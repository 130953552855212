import React from "react";
import {
  Box,
  Flex,
  AnyObject,
  Space,
  SpaceTheme,
  ThemeHeaderTitleOptions,
  SpaceThemeNames,
  Text,
} from "@thenounproject/lingo-core";
import styled from "styled-components";

import ThemeEditorAccordion from "./ThemeEditorAccordion";
import ThemeEditorOption from "./ThemeEditorOption";
import ThemeFontPicker from "./ThemeFontPicker";
import ThemeColorPicker from "./ThemeColorPicker";
import ThemeTextArea from "./ThemeTextArea";
import ThemeSelect from "./ThemeSelect";
import ThemeHeaderBackgroundPicker from "./ThemeHeaderBackgroundPicker";

import {
  Font,
  SpaceThemeColorOptions,
  SpaceThemeImageOptions,
  SpaceThemeTextOptions,
  SpaceThemeSelectOptions,
  themeSelectOptions,
  spaceTitleSelectOptions,
  SpaceThemePreviews,
} from "../types";
import ThemeImagePicker from "./ThemeImagePicker";
import { DEFAULT_THEME } from "@contexts/ThemeDataContext";

export const Wrapper = styled(Box).attrs({
  borderLeft: "default",
  flex: "240px 1 0",
  background: "white",
  overflow: "auto",
  pb: "80px",
})``;

export const Divider = styled(Box).attrs({ width: "100%", borderBottom: "default" })``;

type Props = {
  space: Space;
  fonts: Font[];
  updateTheme: (data: Partial<SpaceTheme>) => void;
  updateFiles: (data: AnyObject) => void;
  newTheme: SpaceTheme;
  existingTheme: SpaceTheme;
  themeEditorView: SpaceThemePreviews;
};

const ThemeEditorControlPanel: React.FC<Props> = ({
  space,
  fonts,
  newTheme,
  existingTheme,
  updateTheme,
  updateFiles,
  themeEditorView,
}) => {
  const { headerTitleStyle } = newTheme;
  const isHomeView = themeEditorView === SpaceThemePreviews.home,
    isGalleryView = themeEditorView === SpaceThemePreviews.gallery;

  const titleLogo = headerTitleStyle === ThemeHeaderTitleOptions.logo;
  const titleSpaceName = headerTitleStyle === ThemeHeaderTitleOptions.name;

  const hasPortalsFeature = space.features.includes("portals");

  /* Rendering functions */
  function renderKitInPortalText() {
    return (
      <Text font="ui.small" color="grayDarkest">
        Change to “Kit in Portal” to preview
      </Text>
    );
  }

  function renderSpaceHeaderSection() {
    if (isGalleryView || hasPortalsFeature) return;
    return (
      <ThemeEditorAccordion title="Header" openByDefault>
        <ThemeEditorOption title="Title">
          <ThemeSelect<ThemeHeaderTitleOptions>
            {...{
              newTheme,
              updateTheme,
              themeSelectOption: SpaceThemeSelectOptions.headerTitleStyle,
              options: spaceTitleSelectOptions,
            }}
          />
          <Box mt="m">
            {titleSpaceName && (
              <ThemeColorPicker
                {...{
                  existingTheme: DEFAULT_THEME,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.headerTitle,
                }}
              />
            )}
            {titleLogo && (
              <ThemeImagePicker
                {...{
                  existingTheme,
                  newTheme,
                  updateTheme,
                  updateFiles,
                  themeImageOption: SpaceThemeImageOptions.headerLogoImage,
                  sizeRecommendation: "400px wide PNG",
                }}
              />
            )}
          </Box>
        </ThemeEditorOption>
        <Divider />
        <ThemeEditorOption title="Description">
          <ThemeTextArea
            {...{
              newTheme,
              updateTheme,
              themeTextOption: SpaceThemeTextOptions.description,
            }}
          />
          <Box mt="m">
            <ThemeColorPicker
              {...{
                existingTheme: DEFAULT_THEME,
                newTheme,
                updateTheme,
                themeColorOption: SpaceThemeColorOptions.headerDescription,
              }}
            />
          </Box>
        </ThemeEditorOption>

        <Divider />
        <ThemeEditorOption title="Background">
          <ThemeHeaderBackgroundPicker
            {...{
              existingTheme,
              newTheme,
              updateTheme,
              updateFiles,
            }}
          />
        </ThemeEditorOption>
      </ThemeEditorAccordion>
    );
  }

  function renderKitSettingsSection() {
    if (!hasPortalsFeature && isHomeView) return;
    return (
      <>
        <ThemeEditorAccordion title="Asset Backgrounds" openByDefault>
          {hasPortalsFeature && isHomeView && renderKitInPortalText()}
          <ThemeEditorOption title="Colors">
            <Flex flexDirection="column" gap="16px">
              <ThemeColorPicker
                {...{
                  existingTheme: DEFAULT_THEME,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.assetLight,
                }}
              />
              <ThemeColorPicker
                {...{
                  existingTheme: DEFAULT_THEME,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.assetDark,
                }}
              />
            </Flex>
          </ThemeEditorOption>
        </ThemeEditorAccordion>
        <ThemeEditorAccordion title="Note Highlighting" openByDefault>
          {hasPortalsFeature && isHomeView && renderKitInPortalText()}
          <ThemeEditorOption title="Colors">
            <Flex flexDirection="column" gap="16px">
              <ThemeColorPicker
                {...{
                  existingTheme: DEFAULT_THEME,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.noteInfo,
                }}
              />
              <ThemeColorPicker
                {...{
                  existingTheme: DEFAULT_THEME,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.noteWarning,
                }}
              />
              <ThemeColorPicker
                {...{
                  existingTheme: DEFAULT_THEME,
                  newTheme,
                  updateTheme,
                  themeColorOption: SpaceThemeColorOptions.noteSuccess,
                }}
              />
            </Flex>
          </ThemeEditorOption>
        </ThemeEditorAccordion>
      </>
    );
  }

  /* Component render */
  return (
    <Wrapper>
      <ThemeEditorAccordion title="Global Styles" openByDefault>
        <ThemeEditorOption
          title="Theme"
          upgradeRequired={!space.features.includes("space_theme_appearance")}
          upgradeMessage="Themes are available for Enterprise customers.">
          <ThemeSelect<SpaceThemeNames>
            {...{
              newTheme,
              updateTheme,
              themeSelectOption: SpaceThemeSelectOptions.themeName,
              options: themeSelectOptions,
            }}
          />
        </ThemeEditorOption>
        <Divider />
        <ThemeEditorOption
          title="Font"
          customizationMessage="Want to use your own font files?"
          upgradeRequired={!space.features.includes("space_theme_fonts")}
          upgradeMessage="Font customization is available for Enterprise customers.">
          <ThemeFontPicker {...{ fonts, newTheme, existingTheme, updateTheme }} />
        </ThemeEditorOption>
        <Divider />
        <ThemeEditorOption title="Colors">
          <ThemeColorPicker
            {...{
              existingTheme: DEFAULT_THEME,
              newTheme,
              updateTheme,
              themeColorOption: SpaceThemeColorOptions.accent,
            }}
          />
        </ThemeEditorOption>
      </ThemeEditorAccordion>
      {renderSpaceHeaderSection()}
      {renderKitSettingsSection()}
    </Wrapper>
  );
};

export default ThemeEditorControlPanel;
