import React, { useState, useCallback, useMemo } from "react";
import {
  Text,
  Box,
  Input,
  PopupMenu,
  useBoolean,
  DropdownButton,
  SpaceTheme,
} from "@thenounproject/lingo-core";

import type { Font } from "@features/theming/types";
import { NavPointTypes } from "@redux/legacy-actions/navPoints";
import useNavPoint from "@hooks/useNavPoint";
import { useSelectSpace } from "@redux/selectors/entities/spaces";

interface Props {
  fonts: Font[];
  newTheme: SpaceTheme;
  updateTheme: (data: Partial<SpaceTheme>) => void;
  error?: string;
}

const ThemeFontPicker: React.FC<Props> = ({ fonts, updateTheme, newTheme, error }) => {
  const navPoint = useNavPoint();
  const space = useSelectSpace();
  const hasPortalsFeature = space?.features?.includes("portals");

  const isEditingPortal = navPoint?.type === NavPointTypes.Portal;
  const isEditingKit = Boolean(navPoint.kit);
  const portalTheme = isEditingPortal ? navPoint?.portal?.theme : null;
  const kitTheme = isEditingKit ? navPoint?.kit?.theme : null;

  const defaultText = isEditingPortal || isEditingKit ? "Default (Inherit)" : "Default (Inter)";

  const spaceTheme = navPoint?.space?.theme;
  const dropdownRef = React.useRef();
  const [isOpen, setOpen, setClosed] = useBoolean(false);
  const [fontSearch, setFontSearch] = useState("");

  const selectedFont = useMemo(() => {
    // return null for inheriting a font from space
    if (
      ((isEditingPortal && !portalTheme?.fonts) || (isEditingKit && !kitTheme?.fonts)) &&
      newTheme.fonts?.system?.family === spaceTheme?.fonts?.system?.family &&
      hasPortalsFeature
    ) {
      return null;
    }
    return newTheme?.fonts?.system?.family;
  }, [
    hasPortalsFeature,
    isEditingKit,
    isEditingPortal,
    kitTheme?.fonts,
    newTheme.fonts?.system?.family,
    portalTheme?.fonts,
    spaceTheme?.fonts?.system?.family,
  ]);

  const onSelectFont = (font: Font) => {
    updateTheme({
      fonts: { css: font.css_url, system: { family: font.family, source: font.source } },
    });
  };

  const restoreDefault = () => {
    updateTheme({ fonts: null });
  };

  const onChange = useCallback(e => {
    setFontSearch(e.target.value);
  }, []);

  const dropDownFonts = fonts.filter(f => f.family !== selectedFont);

  function renderFontFilterMenuItems() {
    if (error) {
      return <Text>{error}</Text>;
    } else if (fontSearch) {
      const searchResults = fonts.filter(f =>
        f.family.toLowerCase().startsWith(fontSearch.toLowerCase())
      );
      return (
        <>
          {searchResults.map(f => {
            return (
              <PopupMenu.Item onClick={() => onSelectFont(f)} key={f.family} title={f.family} />
            );
          })}
        </>
      );
    } else
      return (
        <>
          <PopupMenu.Item
            font="ui.small"
            checked={!selectedFont}
            bordered={!selectedFont}
            onClick={restoreDefault}
            key="Default"
            title={defaultText}
          />
          {selectedFont && (
            <PopupMenu.Item
              font="ui.small"
              checked
              key={selectedFont}
              title={selectedFont}
              bordered
            />
          )}
          {dropDownFonts.map(f => {
            return (
              <PopupMenu.Item
                font="ui.small"
                onClick={() => onSelectFont(f)}
                key={f.family}
                title={f.family}
              />
            );
          })}
        </>
      );
  }

  function renderPopupMenu() {
    return (
      <PopupMenu
        data-testid="font-dropdown"
        hPos="alignLeft"
        vPos="alignTop"
        maxHeight="200"
        width="223"
        source="font-dropdown"
        open={isOpen}
        close={setClosed}
        stayOpenOnClick={false}
        clickAwayClose={true}>
        <>
          <Box
            p="s"
            background="grayLightest"
            borderBottom="default"
            onClick={e => e.stopPropagation()}>
            <Input
              font="ui.small"
              autoFocus
              onChange={onChange}
              value={fontSearch}
              placeholder="Filter..."
              type="input"
              id="font_search"
            />
          </Box>
          {renderFontFilterMenuItems()}
        </>
      </PopupMenu>
    );
  }

  return (
    <Box width="100%" ref={dropdownRef}>
      <DropdownButton
        text={selectedFont ?? defaultText}
        size="small"
        data-popup-source="font-dropdown"
        onClick={setOpen}
        styleOverrides={{ mb: "s", width: "100%" }}
      />
      {renderPopupMenu()}
    </Box>
  );
};

export default ThemeFontPicker;
