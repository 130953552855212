import { API, Portal } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  portalId: number | string;
};

const [usePortal, fetchPortal] = createQueryAction<Args, Portal, string>(
  {
    entity: "portals",
    action: "fetch",
    condition: ({ args: { portalId } }) => Boolean(portalId),
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `portals/${args.portalId}`,
      method: "GET",
      entity: API.Entity.portal,
    });
  }
);

export default usePortal;
export { fetchPortal };
