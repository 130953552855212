import React, { useCallback, useMemo, useState, useRef, Fragment } from "react";
import {
  ActivityIndicator,
  Flex,
  GalleryHeading,
  GalleryNote,
  GallerySupportingAsset,
  Grid,
  KitCard,
  Portal,
  PortalItem,
  PortalItemType,
  Space,
  Box,
  Kit,
  useContextMenu,
  useNavigation,
  buildURL,
} from "@thenounproject/lingo-core";

import ContentContainer from "../ContentContainer";
import EmptyState from "../EmptyState";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";
import InsertPortalKitMenu from "./InsertPortalKitMenu";
import { type InsertPosition } from "@actions/uploads";
import PortalDropZone from "./PortalDropZone";
import useNotifications from "@actions/useNotifications";
import useUpdatePortalItem, { PortalUpdates } from "@redux/actions/portalItems/useUpdatePortalItem";
import {
  portalInsertData as portalInsertDataMap,
  PortalInsertType,
} from "../../constants/portalInsertType";
import useDeletePortalItem from "@redux/actions/portalItems/useDeletePortalItem";
import { DropZoneWrapper } from "../../components/portals/PortalDropZone";
import * as spacingUtils from "@helpers/portalItemSpacing";
import _cloneDeep from "lodash/cloneDeep";
import { KitContextMenuItems } from "@features/context-menus/KitContextMenu";
import PortalItemsContextMenu from "@features/context-menus/PortalItemsContextMenu";

const dragImageStyleOverride = `
  height: 32px;
  width: 32px;
  border-radius: 100px;
  background: #cf4a4a;
  position: absolute;
  color: white;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Inter;
  top: -1000px;
`;

type IndexedItem = PortalItem & { index: number };
type IndexedKitItem = { kits: IndexedItem[] };
type ContentItem = IndexedKitItem | IndexedItem;

type Props = {
  space: Space;
  portalItems: PortalItem[];
  portal: Portal;
  setDraggingEntity?: ReturnType<typeof useSetDraggingEntity>;
  portalDraggingActive?: boolean;
  canEditContent: boolean;
  setInspectorState: (portalItemIds: string[]) => void;
  onSelectItem: (e: React.MouseEvent, portalItemId: string, portalItems: PortalItem[]) => void;
  selectedItems: string[];
  isLoading: boolean;
};

const PortalItems: React.FC<Props> = ({
  portalItems,
  isLoading,
  space,
  portal,
  setDraggingEntity,
  portalDraggingActive,
  canEditContent,
  onSelectItem,
  selectedItems,
  setInspectorState,
}) => {
  const [editingItem, setEditingItem] = useState(null);
  const [kitMenuData, setKitMenuData] = useState<{
    insertPosition: InsertPosition;
    coordinates: { xPos: string; yPos: string };
  }>(null);

  const itemWrapperRef = useRef(null);

  const navigation = useNavigation();

  const { showNotification } = useNotifications();
  const [updatePortalItem] = useUpdatePortalItem();
  const [deletePortalItem] = useDeletePortalItem();

  //Dragging
  const draggingNodes = useRef(new Set());
  const handleDragOver = useCallback(
    (e: React.DragEvent<Element>) => {
      if (e.dataTransfer.types || !e.dataTransfer.types.includes("Files")) return;
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
      if (!portalDraggingActive && setDraggingEntity) {
        setDraggingEntity({ entity: DragEntities.PORTAL_ITEM });
      }
    },
    [portalDraggingActive, setDraggingEntity]
  );

  const handleDragEnter = useCallback(
    (e: React.DragEvent<Element>) => {
      if (e.dataTransfer.types || !e.dataTransfer.types.includes("Files")) return;
      handleDragOver(e);
      draggingNodes.current.add(e.target);
    },
    [handleDragOver]
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent<Element>) => {
      if (e.dataTransfer.types || !e.dataTransfer.types.includes("Files")) return;
      e.preventDefault();
      draggingNodes.current.delete(e.target);
      if (!portalDraggingActive && setDraggingEntity && draggingNodes.current.size === 0) {
        setDraggingEntity({ entity: undefined });
      }
    },
    [portalDraggingActive, setDraggingEntity]
  );

  const handleDrop = useCallback(
    (e: React.DragEvent<Element>) => {
      if (e.dataTransfer.files.length === 0) return;
      e.preventDefault();
      draggingNodes.current.clear();
      if (!portalDraggingActive && setDraggingEntity) {
        setDraggingEntity({ entity: undefined });
      }
    },
    [portalDraggingActive, setDraggingEntity]
  );

  const inspectables: PortalItem[] = useMemo(() => {
    return selectedItems.reduce((acc, item) => {
      const stateItem = portalItems.find(i => i.id === item);
      if (stateItem) {
        acc.push(stateItem);
      }
      return acc;
    }, []);
  }, [portalItems, selectedItems]);

  const _onStartEditing = useCallback(
    portalItemId => {
      setEditingItem(portalItemId);
      onSelectItem(null, portalItemId, []);
    },
    [onSelectItem]
  );

  const openInsertAssetMenu = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, insertPosition: InsertPosition) => {
      setKitMenuData({
        insertPosition,
        coordinates: { xPos: `${e.pageX}px`, yPos: `${e.pageY}px` },
      });
    },
    []
  );

  const closeInsertAssetMenu = useCallback(() => {
    setKitMenuData(null);
  }, []);

  const navigateToKit = useCallback(
    (kit: Kit) => {
      const url = buildURL(`/k/${kit.urlId}`, { space, portal: portal });
      navigation.push(url);
    },
    [navigation, portal, space]
  );
  const renderKitMenuItems = (item: PortalItem) => {
    return (
      <KitContextMenuItems
        kit={item.kit}
        space={space}
        draft={undefined}
        activeVersion={undefined}
        portalId={portal.id}
        portalItemId={item.id}
      />
    );
  };

  const {
    contextMenuXPos,
    contextMenuYPos,
    contextMenuOpen,
    handleContextMenuClose,
    setMenuOpen,
    setMenuClosed,
  } = useContextMenu({
    itemRef: itemWrapperRef,
    onOpenMenu: event => {
      let current = event.target as HTMLElement;
      let itemId: string;
      while (current && !itemId) {
        itemId = current.dataset.portalitemid;
        current = current.parentElement;
      }
      if (!itemId) return setMenuClosed();

      const isSelected = selectedItems.some(i => i === itemId);

      if (!isSelected) {
        onSelectItem(event, itemId, portalItems);
      }
      setMenuOpen();
    },
    autoOpen: false,
  });

  function renderContextMenu() {
    if (!contextMenuOpen || !inspectables.length) {
      return null;
    }

    return (
      <PortalItemsContextMenu
        position={{ x: contextMenuXPos, y: contextMenuYPos }}
        onCloseMenu={handleContextMenuClose}
        inspectables={inspectables}
        canEdit={canEditContent}
        onEditItem={setEditingItem}
        portal={portal}
      />
    );
  }

  function renderInsertKitMenu() {
    if (!kitMenuData) return null;
    return (
      <InsertPortalKitMenu
        portal={portal}
        close={closeInsertAssetMenu}
        coordinates={kitMenuData.coordinates}
        insertPosition={kitMenuData.insertPosition}
      />
    );
  }

  function handleClick(e: React.MouseEvent, item: PortalItem) {
    return onSelectItem(e, item.id, portalItems);
  }

  const handleReorderDragEnd = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      if (setDraggingEntity) setDraggingEntity({ entity: undefined });
    },
    [setDraggingEntity]
  );

  const handleReorderDragStart = useCallback(
    (e: React.DragEvent<HTMLElement>) => {
      // ID of item upon which reorder drag is initiated
      e.dataTransfer.effectAllowed = "all";
      // Proxy the selected item state so we can maniplulate it within the callback context
      const versionedDragItemId = e.currentTarget.dataset.dragid;
      if (!versionedDragItemId) {
        e.preventDefault();
        console.warn("Tried to start a reorder, but the drag event couldn't parse an item ID.");
        return;
      }
      let proxiedSelectedItems = _cloneDeep(selectedItems);
      // If the item is not in the selected items, start new selection
      if (!selectedItems.includes(versionedDragItemId)) {
        proxiedSelectedItems = [versionedDragItemId];
        setInspectorState(proxiedSelectedItems);
      }
      // Manipulate the drag image to indicate the length of the group of items
      if (proxiedSelectedItems.length > 1) {
        const length = selectedItems.length;

        const elem: HTMLElement = document.createElement("div");
        elem.setAttribute("style", dragImageStyleOverride);
        elem.innerHTML = String(length);
        document.body.appendChild(elem);
        e.dataTransfer.setDragImage(elem, 0, 0);
        setTimeout(() => {
          document.body.removeChild(elem);
        }, 0);
      }
      // Add current selection to data transfer
      e.dataTransfer.setData("reorderItemIds", String(proxiedSelectedItems));
      e.dataTransfer.setData("fromPortalId", String(portal?.id));

      // Enable drag listeners
      if (!portalDraggingActive && setDraggingEntity)
        setDraggingEntity({ entity: DragEntities.PORTAL_ITEM });
    },
    [portalDraggingActive, portal?.id, selectedItems, setDraggingEntity, setInspectorState]
  );

  function renderDropZone(
    item: PortalItem,
    index: number,
    nextItemId?: string,
    prevItemId?: string
  ) {
    return canEditContent ? (
      <PortalDropZone
        itemType={item.type}
        itemIndex={index}
        portalItemId={item.id}
        portalId={portal.id}
        nextItemId={nextItemId}
        prevItemId={prevItemId}
        startEditingItem={_onStartEditing}
        openInsertAssetMenu={openInsertAssetMenu}
        itemWrapperRef={itemWrapperRef}
      />
    ) : null;
  }

  function renderEmptySection() {
    if (portalItems?.length) return null;
    return (
      <EmptyState
        title="No kits yet"
        subtitle="A Kit is a home for your visual language (e.g. Style Guide, UI Library)."
        iconProps={{
          iconId: "empty-state.assets",
          size: "90",
          fill: "gray",
        }}
        portalDropZoneProps={{
          portalId: portal.id,
          startEditingItem: _onStartEditing,
          openInsertAssetMenu,
        }}
        onDragEnter={handleDragEnter}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      />
    );
  }

  const _onFinishEditing = useCallback(
    async (portalItem: PortalItem, data: PortalUpdates) => {
      if (!data) {
        return setEditingItem(null);
      }
      const deleteIfEmpty = [PortalItemType.note, PortalItemType.heading];
      if (deleteIfEmpty.includes(portalItem.type) && data.data?.content === "") {
        // If the text content is removed, delete the item
        const { error: responseError } = await deletePortalItem({
          portalItemId: portalItem.id,
          portalId: portal.id,
        });
        if (responseError) {
          showNotification({
            message: responseError.message ?? "Failed to delete items.",
            level: "error",
          });
        } else {
          setInspectorState([]);
        }
      } else {
        const { error: responseError } = await updatePortalItem({
          portalItemId: portalItem.id,
          updates: data,
        });
        if (responseError)
          showNotification({
            message: responseError.message ?? "Failed to save item content.",
            level: "error",
          });
        else {
          setEditingItem(null);
        }
      }
    },
    [deletePortalItem, portal.id, showNotification, setInspectorState, updatePortalItem]
  );

  const content = useMemo(() => {
    const result: ContentItem[] = [];
    let sequentialKits: IndexedItem[] = [];

    if (!portalItems) return null;

    portalItems.forEach((item, index) => {
      if (item.type === PortalItemType.kit) {
        sequentialKits.push({ ...item, index });
      } else {
        if (sequentialKits?.length) {
          const indexedSequentialKits: IndexedKitItem = {
            kits: sequentialKits,
          };
          result.push(indexedSequentialKits);
          sequentialKits = [];
        }
        const indexedItem: IndexedItem = {
          ...item,
          index,
        };
        result.push(indexedItem);
      }
    });

    // After the loop, check if there are any remaining kits
    if (sequentialKits.length) {
      const indexedSequentialKits: IndexedKitItem = {
        kits: sequentialKits,
      };
      result.push(indexedSequentialKits);
    }
    return result;
  }, [portalItems]);

  function renderItem(item: PortalItem, index: number) {
    const prevItem = portalItems[index - 1];
    const nextItem = portalItems[index + 1];
    const isEditing = editingItem === item.id;
    const selected = selectedItems.includes(item.id);
    const selectionProps = {
      "data-portalitemid": item.id,
      selected,
      onClick: e => handleClick(e, item),
    };

    const reorderingProps =
      canEditContent && !isEditing
        ? {
            draggable: true,
            onDragStart: handleReorderDragStart,
            onDragEnd: handleReorderDragEnd,
            "data-dragid": item.id,
          }
        : {};

    switch (item.type) {
      case PortalItemType.kit:
        return (
          <Box key={item.id} {...reorderingProps}>
            <KitCard
              id={item.kit.kitId}
              kit={item.kit}
              space={space}
              menuItems={renderKitMenuItems(item)}
              isSelected={selected}
              onClick={(e: React.MouseEvent) => handleClick(e, item)}
              onDoubleClick={() => navigateToKit(item.kit)}
              selectionProps={{ "data-portalitemid": item.id }}
            />
            {renderDropZone(item, index, nextItem?.id, prevItem?.id)}
          </Box>
        );
      case PortalItemType.heading: {
        const headingSpacing = spacingUtils.getPortalHeadingSpacing(index);
        return (
          <DropZoneWrapper key={item.id} id={`${item.id}`} {...headingSpacing} {...reorderingProps}>
            <GalleryHeading
              {...selectionProps}
              item={item}
              canEdit={canEditContent}
              isEditing={isEditing}
              hasDefaultValue={
                item.data.content === portalInsertDataMap[PortalInsertType.heading].data.content
              }
              onFinishEditing={_onFinishEditing}
              onBeginEditing={setEditingItem}
            />
            {renderDropZone(item, index, nextItem?.id, prevItem?.id)}
          </DropZoneWrapper>
        );
      }
      case PortalItemType.note: {
        const noteSpacing = spacingUtils.getPortalNoteSpacing(prevItem?.type, index);
        return (
          <DropZoneWrapper key={item.id} id={`${item.id}`} {...noteSpacing} {...reorderingProps}>
            <GalleryNote
              {...selectionProps}
              item={item}
              canEdit={canEditContent}
              isEditing={isEditing}
              hasDefaultValue={
                item.data.content === portalInsertDataMap[PortalInsertType.note].data.content
              }
              onFinishEditing={_onFinishEditing}
              onBeginEditing={setEditingItem}
            />
            {renderDropZone(item, index, nextItem?.id, prevItem?.id)}
          </DropZoneWrapper>
        );
      }
      case PortalItemType.supportingImage: {
        const supportSpacing = spacingUtils.getGallerySupportSpacing(prevItem?.type, index);
        return (
          <DropZoneWrapper key={item.id} id={`${item.id}`} {...supportSpacing} {...reorderingProps}>
            <GallerySupportingAsset {...selectionProps} item={item} />
            {renderDropZone(item, index, nextItem?.id, prevItem?.id)}
          </DropZoneWrapper>
        );
      }
      default:
        return null;
    }
  }

  function renderKits(kits: IndexedItem[]) {
    return (
      <Grid templateColumns="repeat(3, minmax(0, 1fr))" width="100%" gap="16px" px="8px">
        {kits.map(kit => renderItem(kit, kit.index))}
      </Grid>
    );
  }

  function renderContent() {
    return content?.map((item, index) => {
      if ("kits" in item) {
        return <Fragment key={index}>{renderKits(item.kits)}</Fragment>;
      } else {
        return renderItem(item, item.index);
      }
    });
  }

  if (isLoading) {
    return <ActivityIndicator center height="50vh" />;
  }

  return (
    <ContentContainer
      poweredBy={portal.access.isPublic}
      styleOverrides={{ px: "m", maxWidth: null }}
      disableTextSelection
      data-inspector-clear="true">
      <Flex mb="xxxl" flexDirection="row" width="100%" flexWrap="wrap">
        {renderContextMenu()}
        {renderEmptySection()}
        {renderInsertKitMenu()}
        <Box width="100%" ref={itemWrapperRef} data-inspector-clear="true">
          {renderContent()}
        </Box>
      </Flex>
    </ContentContainer>
  );
};

export default PortalItems;
