import React from "react";
import { useHistory } from "react-router-dom";
import {
  useBoolean,
  KitCollection,
  SpaceThemeNames,
  KitCollectionItem,
} from "@thenounproject/lingo-core";

import KitCollectionCardClassic from "./KitCollectionCardClassic";
import KitCollectionCardDockweiler from "./KitCollectionCardDockweiler";
import KitCollectionCardPismo from "./KitCollectionCardPismo";
import useNavPoint from "@hooks/useNavPoint";

type Props = {
  collection: KitCollection;
  themeName?: SpaceThemeNames;
};
type DenormalizedCollection = Omit<KitCollection, "items"> & { items: KitCollectionItem[] };

const KitCollectionCard: React.FC<Props> = ({ collection, themeName }) => {
  const [menuOpen, setMenuOpen, setMenuClosed] = useBoolean(false),
    history = useHistory();

  const { space } = useNavPoint();

  const popupProps = {
    source: collection.uuid,
    close: setMenuClosed,
    open: menuOpen,
    vPos: undefined,
    hPos: undefined,
    width: 220,
    maxHeight: undefined,
    stayOpenOnClick: false,
    clickAwayClose: true,
    id: "collection-pop-".concat(collection.uuid),
  };

  const cardProps = {
    collection: collection as DenormalizedCollection,
    space,
    menuOpen,
    popupProps,
    setMenuOpen,
    history,
  };

  switch (themeName) {
    case SpaceThemeNames.classic: {
      return <KitCollectionCardClassic {...cardProps} />;
    }
    case SpaceThemeNames.scher: {
      return <KitCollectionCardDockweiler {...cardProps} />;
    }
    case SpaceThemeNames.wyman: {
      return <KitCollectionCardPismo {...cardProps} />;
    }
  }
};

export default KitCollectionCard;
