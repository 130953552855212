import { AssetType } from "@thenounproject/lingo-core";

// Map asset types to icons
const assetIconMap: Record<string, string> = {};
AssetType.audioTypes.forEach(type => {
  assetIconMap[type] = "content.audio";
});
AssetType.genericTypes.forEach(type => {
  assetIconMap[type] = "content.file-document";
});
AssetType.graphicTypes.forEach(type => {
  assetIconMap[type] = "content.file-graphic";
});
assetIconMap[AssetType.URL] = "action.link";

export default assetIconMap;
