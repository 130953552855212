import React, { useState } from "react";

import ThemeColorPicker from "./ThemeColorPicker";
import ThemeImagePicker from "./ThemeImagePicker";
import {
  SpaceThemeColorOptions,
  SpaceThemeImageOptions,
  SpaceThemeHeaderBackgroundTypes,
} from "@features/theming/types";

import { SpaceTheme, Checkbox, Box, Text, SpaceThemeNames } from "@thenounproject/lingo-core";
import { useThemeDataContext } from "@contexts/ThemeDataProvider";

const headerImageSizeRecommendations = {
  [SpaceThemeNames.classic]: "2100px x 700px",
  [SpaceThemeNames.scher]: "1920px x 1080px",
  [SpaceThemeNames.wyman]: "2100px x 900px",
};

interface Props {
  newTheme: SpaceTheme;
  existingTheme: SpaceTheme;
  updateTheme: ReturnType<typeof useThemeDataContext>["updateEditingTheme"];
  updateFiles: ReturnType<typeof useThemeDataContext>["updateFiles"];
}

const ThemeHeaderBackgroundPicker: React.FC<Props> = ({
  existingTheme,
  newTheme,
  updateTheme,
  updateFiles,
}) => {
  const [headerBackgroundType, setHeaderBackgroundType] = useState<string>(
    existingTheme.headerBackgroundImage
      ? SpaceThemeHeaderBackgroundTypes.headerBackgroundImage
      : SpaceThemeHeaderBackgroundTypes.headerBackgroundColor
  );
  return (
    <Box>
      <Checkbox
        id="header background color option"
        type="radio"
        label={<Text font="ui.small">Solid Color</Text>}
        isSelected={headerBackgroundType === SpaceThemeHeaderBackgroundTypes.headerBackgroundColor}
        onClick={() => {
          setHeaderBackgroundType(SpaceThemeHeaderBackgroundTypes.headerBackgroundColor);
          /**
           * When they choose color option,
           * wipe any image from local state.
           *
           * Last saved color option is always stored.
           */
          updateTheme({ [SpaceThemeHeaderBackgroundTypes.headerBackgroundImage]: null });
          updateFiles({ [SpaceThemeHeaderBackgroundTypes.headerBackgroundImage]: null });
        }}
        styleOverrides={{ mb: "s" }}
      />
      <Checkbox
        id="header background image option"
        type="radio"
        label={<Text font="ui.small">Image</Text>}
        isSelected={headerBackgroundType === SpaceThemeHeaderBackgroundTypes.headerBackgroundImage}
        onClick={() => {
          setHeaderBackgroundType(SpaceThemeHeaderBackgroundTypes.headerBackgroundImage);
        }}
        styleOverrides={{ mb: "l" }}
      />
      {headerBackgroundType === SpaceThemeHeaderBackgroundTypes.headerBackgroundColor && (
        <ThemeColorPicker
          {...{
            existingTheme,
            newTheme,
            updateTheme,
            updateFiles,
            themeColorOption: SpaceThemeColorOptions.header,
          }}
        />
      )}
      {headerBackgroundType === SpaceThemeHeaderBackgroundTypes.headerBackgroundImage && (
        <ThemeImagePicker
          {...{
            newTheme,
            updateTheme,
            updateFiles,
            themeImageOption: SpaceThemeImageOptions.headerBackgroundImage,
            sizeRecommendation: headerImageSizeRecommendations[newTheme.themeName],
          }}
        />
      )}
    </Box>
  );
};

export default ThemeHeaderBackgroundPicker;
