import { useState, useEffect } from "react";

import { Font } from "@features/theming/types";
import { createAsyncAction } from "@redux/actions/actionCreators";
import { API } from "@thenounproject/lingo-core";

interface IReturn {
  fonts: Font[];
  isLoading: boolean;
  error: string;
}

const [useFetchThemeFonts] = createAsyncAction("resources/fetchThemeFonts", async () => {
  const res = await API.call<{ fonts: Font[] }>({
    endpoint: `resources/fonts/google-fonts?theme=true`,
    method: "GET",
  });
  return res;
});

export function useThemeFonts(): IReturn {
  const [fonts, setAllFonts] = useState([]),
    [error, setError] = useState(null),
    [isLoading, setLoading] = useState(false);
  const [fetchThemeFonts] = useFetchThemeFonts();

  useEffect(() => {
    async function fetch() {
      setLoading(true);
      const { response, error: respsonseError } = await fetchThemeFonts();
      if (respsonseError) {
        setError(`Problem fetching fonts: ${respsonseError.message}`);
      } else {
        setAllFonts(response.result.fonts);
      }
      setLoading(false);
    }
    void fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { fonts, isLoading, error };
}
