import { useCallback } from "react";
import { useHistory } from "react-router-dom";

const useConfigureMode = () => {
  const configureMode = location.hash === "#configure";
  const history = useHistory();

  const toggleConfigureMode = useCallback(() => {
    const components = [window.location.pathname, window.location.search];
    if (!configureMode) components.push("#configure");
    history.push(components.join(""));
  }, [configureMode, history]);

  const enterConfigureMode = useCallback(() => {
    if (!configureMode) toggleConfigureMode();
  }, [configureMode, toggleConfigureMode]);

  return { configureMode, toggleConfigureMode, enterConfigureMode };
};

export default useConfigureMode;
