import { useAppDispatchV1 } from "@redux/hooks";
import { createAction as _createReduxAction, PrepareAction } from "@reduxjs/toolkit";
import { IfVoid } from "@reduxjs/toolkit/dist/tsHelpers";
import { useCallback } from "react";

// MARK : Action Hook
// -------------------------------------------------------------------------------
type ActionHook<P> = () => IfVoid<P, () => void, (payload: P) => void>;

export default function createAction<P = void, PA extends PrepareAction<P> = PrepareAction<P>>(
  type: string,
  prepare: PA = null
): [ActionHook<P>, typeof action] {
  const action = _createReduxAction(type, prepare);
  const useAction = () => {
    const dispatch = useAppDispatchV1();
    return useCallback(
      (payload: P = null) => {
        dispatch(action(payload));
      },
      [dispatch]
    );
  };
  return [useAction, action];
}
