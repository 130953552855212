import { API } from "@thenounproject/lingo-core";
import { showNotification } from "@actions/useNotifications";
import { createAsyncAction } from "../actionCreators";

type Args = {
  token: string;
};

const [useDeleteApiToken, deleteApiToken] = createAsyncAction(
  "apiTokens/delete",
  async ({ token }: Args, thunkApi) => {
    const res = await API.call<string>({
      endpoint: `api-tokens/${token}`,
      method: "DELETE",
      entity: API.Entity.apiToken,
    });

    thunkApi.dispatch(showNotification({ message: "API token deleted" }));
    return res;
  }
);
export default useDeleteApiToken;
export { deleteApiToken };
