import React, { useEffect } from "react";
import styled, { css, createGlobalStyle } from "styled-components";
import { useAppSelectorV1 } from "@redux/hooks";
import { Flex, Text, utils, Button, Box } from "@thenounproject/lingo-core";

import { buildItemUrl } from "@redux/legacy-actions/navPoints";

import InspectorDetailed from "./InspectorDetailed";
import InspectorDetailedMultiple from "./InspectorDetailedMultiple";
import InspectorSimple from "./InspectorSimple";
import InspectorSimpleMultiple from "./InspectorSimpleMultiple";
import EaseOutDerender from "../EaseOutDerender";
import useWindowSize from "../hooks/useWindowSize";
import { SUPER_NAV_WIDTH, KIT_NAV_WIDTH } from "../../constants/layouts";
import { Inspectable, InspectorSource } from "@constants/Inspector";
import { getSections } from "@selectors/getters";
import { buildLibraryAssetPath } from "@features/library/utils";
import InspectorSimpleTags from "./InspectorSimpleTags";
import { useViewModeContext } from "@contexts/ViewModeProvider";
import useNavPoint from "@hooks/useNavPoint";

const SIMPLE_INSPECTOR_WIDTH = SUPER_NAV_WIDTH + KIT_NAV_WIDTH;

const DETAILED_INSPECTOR_WIDTH = "260px";

const IntercomStyle = createGlobalStyle`
  .intercom-launcher, .intercom-launcher-frame, .intercom-messenger, .intercom-messenger-frame {
    margin-bottom: 76px !important;
  }
`;

const InspectorViewWrapper = styled(Flex).attrs({
  height: "100%",
  width: "100%",
  flex: "1 1 auto",
  flexDirection: "column",
  overflow: "auto",
})`
  overflow-x: hidden;
  transition: padding 0.4s ease;
`;

const InspectorPanelDetailedWrapper = styled(Flex).attrs({
  height: "100%",
  borderLeft: "1px solid border",
  background: "white",
  flexDirection: "column",
  overflow: "auto",
  flex: "1 0 auto",
})``;

const SimpleShownStyles = css`
  bottom: 0;
`;

type ShownProps = {
  shown: boolean;
};

const InspectorPanelSimpleWrapper = styled(Flex).attrs({
  width: `calc(100% - ${SIMPLE_INSPECTOR_WIDTH || 312}px)`,
  height: "100px",
  alignItems: "center",
  justifyContent: "space-between",
  p: "16px",
  zIndex: "inspectorView",
})<ShownProps>`
  position: fixed;
  bottom: -100px;
  right: 0;
  transition: all 0.4s ease;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
  ${props => props.shown && SimpleShownStyles};
`;

const InspectorSimpleInner = styled(Flex).attrs(props => {
  return {
    width: "100%",
    height: "100%",
    background: "grayLightest",
    borderRadius: utils.getBorderRadius(props?.theme?.themeName),
    border: "default",
    boxShadow: "0px 4px 12px rgba(42, 42, 42, 0.1), 0px 1px 2px rgba(42, 42, 42, 0.14)",
    px: "24px",
  };
})``;

export type Props = {
  inspectables: Inspectable[];
  canEdit: boolean;
  children: React.ReactNode;
  source: InspectorSource;
};

const InspectorView: React.FC<Props> = ({ inspectables, canEdit, source, children }) => {
  const navPoint = useNavPoint();
  const { setInspectorMode, inspectorMode } = useViewModeContext();

  const section = useAppSelectorV1(state => {
    if (!inspectables[0]?.item) return null;
    const sectionId = inspectables[0]?.item?.sectionId;
    const sections = getSections(state);
    return sections[`${sectionId}-${inspectables[0]?.item.version}`];
  });

  const detailedMode = inspectorMode === "detailed";
  const assetOverlayActive = navPoint && navPoint.type === "Item";
  const isKitContext = source === InspectorSource.kit;
  const isTagContext = source === InspectorSource.tags;
  const simpleMode = !detailedMode || isTagContext;

  const emptyInspector = inspectables.length === 0;

  /**
   * Listen for window resize:
   * If the window is below threshold - force the simple view
   */
  const windowSize = useWindowSize();

  useEffect(() => {
    // NOTE What should this value be?  Arbitrarily set to 1200px
    if (windowSize.belowInspectorThreshold && detailedMode) {
      setInspectorMode("simple");
    }
  }, [windowSize, detailedMode, setInspectorMode]);

  /**
   * Render methods
   */
  function renderDetailedComponents() {
    if (isTagContext) return null;

    switch (inspectables.length) {
      case 0:
        return renderEmptyDetailedComponents();
      case 1:
        return renderSingleDetailedComponents();
      default:
        return renderMultipleDetailedComponents();
    }
  }

  function renderEmptyDetailedComponents() {
    return (
      <Flex
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        position="relative">
        <Box position="absolute" top="0" right="0">
          <Button
            icon="navigation.chevron-right"
            buttonStyle="tertiary"
            size="small"
            onClick={() => setInspectorMode("simple")}
            width="28px"
            height="28px"
            p="6"
            position="absolute"
            top={8}
            right={8}
          />
        </Box>
        <Text color="grayDark" px="xs">
          Select an asset to inspect
        </Text>
        <Text mt="xl" color="grayDark" px="xs">
          Shift + click to select multiple assets
        </Text>
      </Flex>
    );
  }
  function renderSingleDetailedComponents() {
    if (!inspectables[0]) return;
    const [inspectable] = inspectables;

    const inspectorDetailedProps = {
      inspectable,
      source,
      canEdit,
    };
    return <InspectorDetailed {...inspectorDetailedProps} />;
  }

  function renderMultipleDetailedComponents() {
    const inspectorDetailedProps = {
      inspectables,
      canEdit,
    };
    return <InspectorDetailedMultiple {...inspectorDetailedProps} />;
  }

  function renderTagComponents() {
    return <InspectorSimpleTags {...{ inspectables }} />;
  }

  function renderSimpleComponents() {
    if (isTagContext) {
      return renderTagComponents();
    }
    switch (inspectables.length) {
      case 0:
        return null;
      case 1:
        return renderSingleSimpleComponents();
      default:
        return renderMultipleSimpleComponents();
    }
  }

  function renderSingleSimpleComponents() {
    const [inspectable] = inspectables;
    let path: string;

    if (isKitContext) {
      path = buildItemUrl(inspectable.item, {
        space: navPoint.space,
        portal: navPoint.portal,
        section,
      });
    } else {
      path = buildLibraryAssetPath(navPoint.space, inspectable.asset);
    }

    const inspectorSimpleProps = {
      inspectable,
      source,
      canEdit,
      sharePath: path,
    };
    return <InspectorSimple {...inspectorSimpleProps} />;
  }

  function renderMultipleSimpleComponents() {
    const inspectorSimpleMultipleProps = {
      inspectables,
      source,
      canEdit,
    };
    return <InspectorSimpleMultiple {...inspectorSimpleMultipleProps} />;
  }

  return (
    <Flex height="100%">
      {simpleMode && !emptyInspector && !assetOverlayActive && <IntercomStyle />}
      <InspectorViewWrapper
        id="inspector-view-wrapper"
        data-inspector-clear="true"
        data-testid="inspector-view-wrapper">
        {children}
      </InspectorViewWrapper>
      {!isTagContext && (
        <InspectorPanelDetailedWrapper width={detailedMode ? DETAILED_INSPECTOR_WIDTH : null}>
          {detailedMode ? (
            renderDetailedComponents()
          ) : (
            <>
              {!windowSize.belowInspectorThreshold && (
                <Button
                  icon="navigation.chevron-left"
                  buttonStyle="tertiary"
                  size="small"
                  onClick={() => setInspectorMode("detailed")}
                  width="28px"
                  height="28px"
                  p="6"
                  mt="s"
                />
              )}
            </>
          )}
        </InspectorPanelDetailedWrapper>
      )}
      <InspectorPanelSimpleWrapper shown={!emptyInspector && simpleMode}>
        <InspectorSimpleInner>
          <EaseOutDerender enabled={simpleMode}>{renderSimpleComponents()}</EaseOutDerender>
        </InspectorSimpleInner>
      </InspectorPanelSimpleWrapper>
    </Flex>
  );
};

export default InspectorView;
