/**
 * Metadata sidebar shown in AssetDetail and InspectorDetailed.
 */

import React, { useState, useEffect, useCallback } from "react";
import { useAppSelectorV1 } from "@redux/hooks";
import queryString from "query-string";
import styled from "styled-components";
import {
  Button,
  Box,
  Flex,
  Text,
  formatDate,
  Tooltip,
  SpacePermission,
  AssetType,
  Item,
} from "@thenounproject/lingo-core";

import AccordionSection from "../AccordionSection";
import SketchStyleAttrs from "../kits/SketchStyleAttrs";

import InspectorItemKit from "../inspector/InspectorItemKit";
import InspectorAssetOverview from "./InspectorAssetOverview";
import InspectorAssetTags from "./InspectorAssetTags";
import InspectorActions from "./InspectorActions";
import InspectorCustomFields from "./InspectorCustomFields";

import { Inspectable, InspectorSource, isAssetInspectable } from "@constants/Inspector";
import { useSelectSpace } from "@selectors/entities/spaces";
import useAsset from "@redux/actions/assets/useAsset";
import { getNavPoint } from "@selectors/getters";
import { NavPointTypes } from "@redux/legacy-actions/navPoints";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useUpsell from "@hooks/useUpsell";
import { AccessStatus } from "@hooks/useKitUpsell";
import { useViewModeContext } from "@contexts/ViewModeProvider";

const Meta = styled(Box).attrs({
  width: "100%",
  height: "100%",
  overflow: "auto",
})``;

const AssetName = styled(Text).attrs({
  font: "ui.regularBold",
  mb: "xxs",
})`
  overflow-wrap: break-word;
`;

const KitsSectionContainer = styled(Box).attrs({
  position: "sticky",
  bottom: "0",
  background: "white",
  zIndex: 5,
})``;

export type Props = {
  canEdit: boolean;
  source: InspectorSource;
  inspectable: Inspectable;
};

function InspectorDetailed({ canEdit, source, inspectable }: Props) {
  const space = useSelectSpace(),
    { showModal } = useShowModal();
  const navPoint = useAppSelectorV1(getNavPoint);
  const { setInspectorMode } = useViewModeContext();

  const { data: fetchedAsset } = useAsset({
    assetId: inspectable?.asset?.id,
    shareToken: inspectable?.asset?.shareToken,
  });

  const { accessStatus: advancedAnalyticsAccessStatus, openUpgradeModal } = useUpsell(
    SpacePermission.viewAdvancedAnalytics
  );
  const mustUpgradeAdvancedAnalytics =
    advancedAnalyticsAccessStatus === AccessStatus.insufficientPlan;

  const shouldShowCollapseButton = [
    NavPointTypes.Kit,
    NavPointTypes.Gallery,
    NavPointTypes.Library,
    NavPointTypes.Section,
  ].includes(navPoint?.type);

  // const assetId = inspectable?.asset?.id;
  // const shareToken = inspectable?.asset?.shareToken;

  const [customTypeSelect, setCustomTypeSelect] = useState(null),
    [kitsSectionExpanded, setExpanded] = useState(false);

  const isAsset = isAssetInspectable(inspectable);

  // Ensure the selected filecut type is avialable
  useEffect(() => {
    if (!inspectable.asset?.meta?.filecuts) return;

    const { availableTypes } = inspectable.asset.meta.filecuts;
    const availableTypeNames = availableTypes
      .filter(({ enabled }) => enabled)
      .map(({ type }) => type);

    if (!availableTypeNames.includes(customTypeSelect)) {
      setCustomTypeSelect(availableTypeNames[0]);
    }
  }, [customTypeSelect, inspectable]);

  // Open the filecuts modal based on url query params
  useEffect(() => {
    const params = queryString.parse(window.location.search);
    const modal = params.modal;
    if (modal) {
      const canManageExports = space.features.includes("export_management");
      if (modal === "exports" && canManageExports) {
        showModal(ModalTypes.MANAGE_FILECUTS, { inspectable });
        window.history.pushState(
          {},
          document.title,
          `${window.location.href.split("&modal=exports")[0]}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // MARK : Rendering
  // -------------------------------------------------------------------------------

  function renderMetaOverview() {
    if (!isAsset)
      return (
        <Box p="m" borderBottom="default">
          <Flex justifyContent="space-between" alignItems="center">
            <AssetName>1 selected</AssetName>
          </Flex>
        </Box>
      );
    return <InspectorAssetOverview {...{ canEdit, inspectable, shouldShowCollapseButton }} />;
  }

  const openInsightsModal = useCallback(() => {
    showModal(ModalTypes.INSIGHTS_ASSET, {
      assetId: inspectable.item.id,
      assetName: inspectable.asset.name || inspectable.asset.colors?.[0]?.name,
      version: inspectable.item.version,
      space,
    });
  }, [inspectable, showModal, space]);

  function renderInsightsButton() {
    if (!isAsset || !["owner", "admin"].includes(space.access.role)) return null;
    if (!inspectable.item) return null;
    return (
      <AccordionSection {...getAccordionProps("Insights")}>
        <Box data-tooltip-source="insights-button">
          <Button
            text="View insights"
            size="small"
            fullWidth
            onClick={openInsightsModal}
            disabled={mustUpgradeAdvancedAnalytics}
          />
          {mustUpgradeAdvancedAnalytics && (
            <Tooltip source="insights-button" direction={Tooltip.Direction.Left}>
              Insights are not available on your current plan.{" "}
              <Button
                buttonStyle="tertiary"
                themeOverrides={{ primaryColor: "white" }}
                fontStyle="ui.smallBold"
                text="View plans."
                onClick={openUpgradeModal}
              />
            </Tooltip>
          )}{" "}
        </Box>
      </AccordionSection>
    );
  }

  function renderMetaTags() {
    if (!isAsset) return null;
    const assetTagsProps = {
      inspectable,
      source,
      canEdit,
    };
    return <InspectorAssetTags {...assetTagsProps} />;
  }

  function renderMetaStyles() {
    const sketchStyles = inspectable.asset?.meta?.sketchStyleAttributes;

    if (!sketchStyles) return null;

    return (
      <AccordionSection {...getAccordionProps("Styles")}>
        <SketchStyleAttrs
          styles={typeof sketchStyles === "string" ? JSON.parse(sketchStyles) : sketchStyles}
        />
      </AccordionSection>
    );
  }

  function renderMetaDetails() {
    function renderSource() {
      if (inspectable.asset?.meta?.figma?.url) {
        return (
          <Box mb="m">
            <Text color="grayDarkest" font="ui.small" mb="2">
              Source
            </Text>
            <Button
              fontStyle="ui.small"
              icon="action.external-link"
              text="Figma"
              size="small"
              buttonStyle="tertiary"
              link={inspectable.asset.meta?.figma?.url}
            />
          </Box>
        );
      }
    }

    function renderDetailField(title: string, value: string) {
      return (
        <Box mb="m">
          <Text color="grayDarkest" font="ui.small" mb="2">
            {title}
          </Text>
          <Text color="black" font="ui.small">
            {value}
          </Text>
        </Box>
      );
    }

    function renderLinkMeta() {
      if (inspectable.asset?.type === AssetType.URL && inspectable.asset?.meta?.content) {
        const { title, description, url } = inspectable.asset.meta.content;
        return (
          <>
            {url && (
              <Box mb="m">
                <Text color="grayDarkest" font="ui.small">
                  URL
                </Text>
                <Button buttonStyle="tertiary" link={url} text={url} size="small" />
              </Box>
            )}
            {title && (
              <Box mb="m">
                <Text color="grayDarkest" font="ui.small" mb="2">
                  Title
                </Text>
                <Text color="black" font="ui.small">
                  {title}
                </Text>
              </Box>
            )}
            {description && (
              <Box mb="m">
                <Text color="grayDarkest" font="ui.small" mb="2">
                  Description
                </Text>
                <Text color="black" font="ui.small">
                  {description}
                </Text>
              </Box>
            )}
          </>
        );
      }
    }

    return (
      <AccordionSection {...getAccordionProps("Details")}>
        {isAsset && <InspectorCustomFields assets={[inspectable.asset]} canEdit={canEdit} />}
        {renderSource()}
        {renderLinkMeta()}
        {renderDetailField(
          "Created",
          formatDate(inspectable.asset?.dateAdded ?? inspectable.item?.dateAdded, "MMMM do, yyyy")
        )}
        {renderDetailField(
          "Modified",
          formatDate(
            inspectable.asset?.dateUpdated ?? inspectable.item?.dateUpdated,
            "MMMM do, yyyy"
          )
        )}
        {fetchedAsset?.uploader?.name && renderDetailField("Creator", fetchedAsset?.uploader.name)}
      </AccordionSection>
    );
  }

  function getAccordionProps(title: string) {
    return {
      contentId: inspectable.asset?.id ?? inspectable.item?.id,
      title,
      styleOverrides: { borderBottom: "none" },
    };
  }

  function renderAssetKits() {
    if (!isAsset) return null;
    if (!inspectable.asset) return null;
    if (inspectable.asset.sourceId) return null;
    if (space.access.isPublic) return null;
    return (
      <AccordionSection
        {...getAccordionProps("Kits")}
        expanded={kitsSectionExpanded}
        setExpanded={() => setExpanded(!kitsSectionExpanded)}
        disableControls={false}
        styleOverrides={{
          height: kitsSectionExpanded ? "260px" : "48px",
          borderTop: "default",
          mb: "s",
        }}
        titleWrapperStyleOverrides={{ mb: "s" }}>
        {fetchedAsset?.items?.length ? (
          <Flex flexDirection="column" width="100%" overflow="auto">
            {(fetchedAsset.items as Item[]).map(item => {
              return <InspectorItemKit key={item.id} item={item} />;
            })}
          </Flex>
        ) : (
          <Text font="ui.small" width="100%" color="grayDarker" textAlign="center" py="m">
            No kits
          </Text>
        )}
      </AccordionSection>
    );
  }

  function renderNonAssetActions() {
    if (isAsset) return null;
    return (
      <AccordionSection>
        <InspectorActions inspectables={[inspectable]} canEdit={canEdit} />
      </AccordionSection>
    );
  }

  return (
    <Meta data-testid="inspector-detailed">
      <Flex flexDirection="column" height="100%">
        <Flex flexDirection="column" flex="1 0 auto">
          {shouldShowCollapseButton && (
            <Button
              icon="navigation.chevron-right"
              buttonStyle="tertiary"
              size="small"
              onClick={() => setInspectorMode("simple")}
              width="28px"
              height="28px"
              p="6"
              position="absolute"
              top={8}
              right={8}
              zIndex={1}
            />
          )}
          {renderMetaOverview()}
          {renderNonAssetActions()}
          {renderInsightsButton()}
          {renderMetaTags()}
          {renderMetaDetails()}
          {renderMetaStyles()}
        </Flex>
        <KitsSectionContainer>{renderAssetKits()}</KitsSectionContainer>
      </Flex>
    </Meta>
  );
}

export default InspectorDetailed;
