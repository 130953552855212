import React from "react";
import { Switch } from "react-router-dom";
import appRoutes from "./app/routes";
import marketingRoutes from "./marketing/Routes";

export default function Routes() {
  return (
    <Switch>
      {appRoutes()}
      {marketingRoutes()}
    </Switch>
  );
}
