import { API, Kit } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  spaceId: number | string;
  kitId: string;
};

const [useKit, fetchKit] = createQueryAction<Args, Kit, string>(
  {
    entity: "kits",
    action: "fetch",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `kits/${args.spaceId}/${args.kitId}`,
      method: "GET",
      entity: API.Entity.kit,
      query: {
        include: "space",
      },
    });
  }
);

export default useKit;
export { fetchKit };
