import { useCallback, useMemo } from "react";

import { PortalItem, PortalItemType } from "@thenounproject/lingo-core";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";

import useDeletePortalItems from "@redux/actions/portalItems/useDeletePortalItems";
import useUpdatePortalItems from "@redux/actions/portalItems/useUpdatePortalItems";
import useUpdatePortalFileAsset from "@redux/actions/portalItems/useUpdatePortalFileAsset";

export default function usePortalInspectableActions(inspectables: PortalItem[], portalId?: string) {
  const [deletePortalItems] = useDeletePortalItems(),
    [updatePortalItems] = useUpdatePortalItems(),
    [updatePortalFileAsset] = useUpdatePortalFileAsset(),
    { showModal } = useShowModal();

  const singleItem = inspectables.length === 1 ? inspectables[0] : null;

  const canReplaceFile = useMemo(() => {
    if (singleItem && singleItem?.status !== "active") return false;
    return singleItem?.type === PortalItemType.supportingImage;
  }, [singleItem]);

  const replaceFile = useCallback(() => {
    if (!canReplaceFile) return;
    const callback = (files: File[]) => {
      return updatePortalFileAsset({ portalItem: singleItem, file: files[0] });
    };
    showModal(ModalTypes.PICK_FILE, {
      onUploadFiles: callback,
      prompt: "Choose image",
      itemType: singleItem?.type,
      assetType: singleItem?.asset?.type,
    });
  }, [canReplaceFile, singleItem, showModal, updatePortalFileAsset]);

  const deleteItems = useCallback(() => {
    const portalItemIds = inspectables.map(i => i?.id).filter(Boolean);
    void deletePortalItems({ portalItems: portalItemIds, portalId });
  }, [deletePortalItems, inspectables, portalId]);

  //Edit Item Background
  const canEditItemBackgroundColor = useMemo(() => {
      const editableBackgroundTypes = [PortalItemType.note];
      return inspectables.every(
        i =>
          i?.status === "active" &&
          editableBackgroundTypes.includes(i?.type) &&
          i?.type === inspectables[0]?.type
      );
    }, [inspectables]),
    editItemBackground = useCallback(
      (background: string) => {
        if (!canEditItemBackgroundColor) return;
        const portalItemIds = inspectables.map(i => i?.id).filter(Boolean);
        void updatePortalItems({
          // portalId,
          portalItems: portalItemIds,
          updates: { data: { background_color: background } },
        });
      },
      [canEditItemBackgroundColor, inspectables, updatePortalItems, portalId]
    );

  return {
    deleteItems,
    replaceFile: canReplaceFile ? replaceFile : null,
    editItemBackground: canEditItemBackgroundColor ? editItemBackground : null,
  };
}
