import { API, Kit } from "@thenounproject/lingo-core";

import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: number | string;
  sectionId: string;
  version?: number;
};

const [useSection, fetchSection] = createQueryAction<Args, Kit[], string>(
  {
    entity: "sections",
    action: "fetch",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `spaces/${args.spaceId}/sections/${args.sectionId}`,
      method: "GET",
      entity: API.Entity.section,
      query: {
        v: args.version,
      },
    });
  }
);

export default useSection;
export { fetchSection };
