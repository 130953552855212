/**
 * Item reducer
 */

import _merge from "lodash/merge";
import { Repo } from "@thenounproject/lingo-core";
import { createReducer } from "@reduxjs/toolkit";
import { fetchShowcaseKits } from "@queries/useShowcaseKits";

const shortIdReducer = createReducer<Record<string, string>>({}, builder => {
  builder
    .addCase(fetchShowcaseKits.fulfilled, () => {
      // Do nothing
    })
    .addDefaultCase((state, action: any) => {
      const entities = action.payload?.entities ?? action.response?.entities;
      if (!entities) return state;
      const updates = Repo.getShortIdsFromEntities(entities);
      return _merge(state, updates);
    });
});

export default shortIdReducer;
