import { API, Portal, SpacePreview } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  identifier: string;
};

type Result = {
  portalId: string;
  spaceId: number;
  portal: Portal;
  space: SpacePreview;
};

const [useIdentifyDomain, identifyDomain] = createQueryAction<Args, Result, Result>(
  {
    entity: "spaces",
    action: "identifyDomain",
    condition: ({ args: { identifier } }) => Boolean(identifier),
    denormalize: false,
  },
  async ({ args }) => {
    return await API.call<Result>({
      endpoint: `identify/${args.identifier}`,
      method: "GET",
      entity: API.Entity.domainIdentification,
    });
  }
);

export default useIdentifyDomain;
export { identifyDomain };
