import React, { Fragment, useCallback } from "react";

import {
  PopupMenu,
  Kit,
  Space,
  Tooltip,
  Button,
  SpacePermission,
  KitVersion,
  KitPermission,
  buildURL,
  copy,
} from "@thenounproject/lingo-core";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import useNotifications from "@actions/useNotifications";
import useDuplicateKit from "@redux/actions/kits/useDuplicateKit";
import useDeletePortalItem from "@redux/actions/portalItems/useDeletePortalItem";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";
import { useLocation } from "react-router";
import useNavPoint from "@hooks/useNavPoint";
import useConfigureMode from "../../components/hooks/useConfigureMode";

type Props = {
  kit: Kit;
  draft: KitVersion;
  space: Space;
  activeVersion?: number;
  portalId?: string;
  portalItemId?: string;
};

export function KitContextMenuItems({
  kit,
  draft,
  space,
  portalId,
  portalItemId,
  // Some options may be be ommitted when viewing a release version
  activeVersion = 0,
}: Props) {
  const [duplicateKit] = useDuplicateKit();
  const location = useLocation();
  const navPoint = useNavPoint();
  const { clearSelection } = useSelectedItemsContext();

  const permissions = kit.access?.permissions || [],
    spacePermissions = space?.access?.permissions || [],
    canEdit = permissions.includes(KitPermission.edit),
    canEditContent = permissions.includes(KitPermission.editContent),
    canDelete = permissions.includes(KitPermission.delete),
    canRemove = permissions.includes(KitPermission.manage) && kit.purchase;

  const { showModal } = useShowModal();
  const { showNotification } = useNotifications();
  const [deletePortalItem] = useDeletePortalItem();

  const { accessStatus: advancedAnalyticsAccessStatus, openUpgradeModal } = useUpsell(
    SpacePermission.viewAdvancedAnalytics
  );
  const mustUpgradeAdvancedAnalytics =
    advancedAnalyticsAccessStatus === AccessStatus.insufficientPlan;

  const duplicateSelected = useCallback(async () => {
    const { error } = await duplicateKit({
      kitId: kit.kitId,
      spaceId: space?.id,
    });

    if (error) {
      showNotification({
        message: `There was a problem duplicating your kit ${error.message}`,
        level: "error",
      });
    } else {
      showNotification({
        message: "Your kit is being duplicated. It may take up to a few minutes.",
      });
    }
  }, [duplicateKit, kit.kitId, showNotification, space?.id]);

  const removeKitFromPortal = useCallback(async () => {
    const { error } = await deletePortalItem({
      portalItemId,
      portalId,
    });

    if (error) {
      showNotification({
        message: `There was a problem removing your kit from the portal: ${error.message}`,
        level: "error",
      });
    } else {
      showNotification({
        message: "Your kit has been removed from the portal.",
      });
    }
  }, [deletePortalItem, portalId, portalItemId, showNotification]);

  const openShareModal = useCallback(() => {
    if (portalId) {
      showModal(ModalTypes.SHARE_PORTAL, { portalId, kitId: kit.kitId });
    } else {
      showModal(ModalTypes.SHARE_KIT, { kitId: kit.kitId });
    }
  }, [kit.kitId, portalId, showModal]);
  function renderShareItem() {
    return permissions.includes(KitPermission.changeOwnStatus) ? (
      <PopupMenu.Item key="kit-menu-share" title="Share kit" onClick={openShareModal} />
    ) : (
      <PopupMenu.Item
        key="kit-menu-copy-share-link"
        title="Copy share link"
        onClick={() =>
          copy(buildURL(`/k/${kit.shortId}`, { space, portal: navPoint.portal, isPublic: true }))
        }
      />
    );
  }

  function renderSharedKitDetailsItem() {
    if (!kit.purchase) return null;
    if (!showModal) return null;
    return (
      <PopupMenu.Item
        key="kit-menu-shared-kit-details"
        title="License & details"
        onClick={() => showModal(ModalTypes.PUBLISHED_KIT_META, { kit })}
      />
    );
  }

  function renderViewInsightsItem() {
    if (!["owner", "admin"].includes(space?.access.role)) return null;

    return (
      <React.Fragment key="kit-menu-view-insights">
        <PopupMenu.Item
          title="View insights"
          disabled={mustUpgradeAdvancedAnalytics}
          onClick={() =>
            showModal(ModalTypes.INSIGHTS_KIT, { kitId: kit.kitId, kitName: kit.name, space })
          }
          data-tooltip-source="insights-kit-menu-item"
        />
        {mustUpgradeAdvancedAnalytics && (
          <Tooltip source="insights-kit-menu-item" direction={Tooltip.Direction.Right}>
            Insights are not available on your current plan.{" "}
            <Button
              buttonStyle="tertiary"
              themeOverrides={{ primaryColor: "white" }}
              fontStyle="ui.smallBold"
              text="View plans."
              onClick={openUpgradeModal}
            />
          </Tooltip>
        )}
      </React.Fragment>
    );
  }

  // Section 2
  function renderEditItem() {
    if (!canEdit || activeVersion > 0) return null;
    if (!showModal) return null;
    return (
      <PopupMenu.Item
        key="kit-menu-edit-kit"
        title="Edit kit"
        onClick={() => showModal(ModalTypes.EDIT_KIT, { kit: kit })}
      />
    );
  }
  const { enterConfigureMode: _enterConfigureMode } = useConfigureMode();
  const enterConfigureMode = useCallback(() => {
    clearSelection({ type: "kitGallery" });
    _enterConfigureMode();
  }, [_enterConfigureMode, clearSelection]);

  function renderKitThemeItem() {
    if (!canEdit || activeVersion > 0 || !space.features.includes("portals") || !navPoint.kit)
      return null;
    return (
      <PopupMenu.Item
        key="kit-menu-configure-kit"
        title="Configure kit styling"
        onClick={enterConfigureMode}
      />
    );
  }
  function renderDuplicateKitItem() {
    if (portalId) return null;
    if (!duplicateKit) return null;
    if (!canEdit || !spacePermissions.includes("create_kit") || activeVersion > 0) {
      return null;
    }
    return (
      <PopupMenu.Item
        key="kit-menu-duplicate-kit"
        title="Duplicate kit"
        onClick={duplicateSelected}
      />
    );
  }

  function renderCollectionsItem() {
    if (space.features.includes("portals")) return null;
    if (!canEdit || !spacePermissions.includes(SpacePermission.editKitCollection)) {
      return null;
    }
    return (
      <PopupMenu.Item
        key="kit-menu-collections"
        title="Add to collections"
        onClick={() => showModal(ModalTypes.MANAGE_KIT_COLLECTIONS, { kit })}
      />
    );
  }

  const previous = location?.search.includes("?previous=")
    ? location.search
    : `?previous=${location?.pathname}`;

  function renderRecentlyDeletedItem() {
    if (!draft) return null;
    if (!canEditContent || activeVersion > 0) return null;
    return (
      <PopupMenu.Item
        key="kit-menu-recently-deleted"
        title="Recently removed"
        disabled={draft.counts.trash === 0}
        link={buildURL(`/k/${kit.urlId}/deleted/${previous}`, { space, portal: navPoint.portal })}
      />
    );
  }

  function renderRecoveredAssetsItem() {
    if (!draft) return null;
    if (!canEditContent || activeVersion > 0 || draft.counts.recovery === 0) return null;
    return (
      <PopupMenu.Item
        key="kit-menu-recovered-assets"
        title="Recovered assets"
        link={buildURL(`/k/${kit.urlId}/recovered/${previous}`, {
          space,
          portal: navPoint.portal,
        })}
      />
    );
  }

  // Section 3
  function renderDeleteItem() {
    if (portalId) return null;
    if (!(canDelete || canRemove) || activeVersion > 0) {
      return null;
    }

    return (
      <PopupMenu.Item
        key="kit-menu-delete-kit"
        title={kit.purchase ? "Remove kit" : "Delete kit"}
        onClick={() => showModal(ModalTypes.DELETE_KIT, { kit: kit })}
      />
    );
  }

  function renderRemoveKitFromPortal() {
    if (!portalId) return null;
    return (
      <PopupMenu.Item
        key="kit-menu-remove-kit-from-portal"
        title="Remove kit from portal"
        onClick={removeKitFromPortal}
      />
    );
  }

  const shareOptions = [
    renderShareItem(),
    renderSharedKitDetailsItem(),
    renderViewInsightsItem(),
  ].filter(Boolean);

  const editOptions = [
    renderEditItem(),
    renderKitThemeItem(),
    renderDuplicateKitItem(),
    renderCollectionsItem(),
    renderRecentlyDeletedItem(),
    renderRecoveredAssetsItem(),
  ].filter(Boolean);
  const deleteOptions = [renderDeleteItem(), renderRemoveKitFromPortal()].filter(Boolean);

  const sections = [shareOptions, editOptions, deleteOptions].filter(s => s.length);

  if (!sections.length) return null;

  return (
    <Fragment>
      {sections.map((s, idx) => (
        <PopupMenu.Section key={`kit-menu-section-${idx}`}>{s}</PopupMenu.Section>
      ))}
    </Fragment>
  );
}
