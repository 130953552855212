import React, { ComponentProps, Fragment, useMemo, useCallback } from "react";
import {
  copy,
  PopupMenu,
  ItemType,
  Tooltip,
  Button,
  SpacePermission,
  styles,
  buildPath,
  Text,
  Box,
  Flex,
  Item,
} from "@thenounproject/lingo-core";
import { ChromePicker, ColorResult } from "react-color";
import styled, { useTheme } from "styled-components";

import useInspectableActions from "@features/context-menus/hooks/useInspectableActions";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";
import { Inspectable } from "@constants/Inspector";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import UpsellTooltip from "../../components/spaces/settings/UpsellTooltip";
import { NoteBackgroundColorOptions } from "./components/NoteBackgroundColorOptions";

type Props = {
  // Menu
  position?: {
    x: string | number;
    y: string | number;
  };
  popupSource?: string;
  onCloseMenu: () => void;
  // Data
  inspectables: Inspectable[];
  canEdit: boolean;
  onEditItem?: (itemId: string) => void;

  vPos?: ComponentProps<typeof PopupMenu>["vPos"];
  hPos?: ComponentProps<typeof PopupMenu>["hPos"];
  backgroundColorhPos?: ComponentProps<typeof PopupMenu.Item>["hPos"];
};

const StyledColorPicker = styled(ChromePicker)`
  width: 100% !important;
`;

export function hasContextMenu(inspectables: Inspectable[], item?: Item): boolean {
  if (inspectables.length > 1 && item?.data?.viewId) return false;
  return true;
}

const GalleryContextMenu: React.FC<Props> = ({
  position,
  popupSource,
  onCloseMenu,
  inspectables,
  canEdit,
  onEditItem,
  vPos,
  hPos,
  backgroundColorhPos,
}) => {
  const contextMenuSource = "gallery-context-menu";

  const inspectableContextMenuItems = InspectableContextMenuItems({
    inspectables: inspectables,
    canEdit: canEdit,
    onEditItem: onEditItem,
    backgroundColorhPos: backgroundColorhPos,
  });

  if (!inspectableContextMenuItems) return null;

  const { x: xPos, y: yPos } = position || { x: "0", y: "0" };
  return (
    <Fragment>
      {position ? (
        <PopupMenu.ContextMenuAnchor
          xPos={xPos}
          yPos={yPos}
          data-popup-source={contextMenuSource}
        />
      ) : null}
      <PopupMenu
        data-testid="context-menu-popup"
        source={popupSource ?? contextMenuSource}
        close={onCloseMenu}
        vPos={vPos || "floatBelow"}
        hPos={hPos || "alignLeft"}>
        <InspectableContextMenuItems
          inspectables={inspectables}
          canEdit={canEdit}
          onEditItem={onEditItem}
          backgroundColorhPos={backgroundColorhPos}
        />
      </PopupMenu>
    </Fragment>
  );
};
export default GalleryContextMenu;

type ItemProps = {
  inspectables: Inspectable[];
  canEdit: boolean;
  onEditItem?: (itemId: string) => void;
  backgroundColorhPos?: ComponentProps<typeof PopupMenu.Item>["hPos"];
};

export const InspectableContextMenuItems: React.FC<ItemProps> = ({
  inspectables,
  canEdit,
  onEditItem,
  backgroundColorhPos,
}) => {
  const { showModal } = useShowModal(),
    space = useSelectSpace(),
    currentTheme = useTheme();

  const {
    copyShareLink,
    replaceFile,
    replaceFont,
    editColor,
    editLink,
    moveItems,
    copyItems,
    deleteItems,
    trashItems,
    removeThumbnail,
    restoreItems,
    renameGalleryItem,
    editAssetBackground,
    editItemBackground,
    editThumbnail,
    setAsKitCover,
    addToKit,
    removeFromKits,
    deleteAssets,
    editDisplayStyle,
  } = useInspectableActions(inspectables);

  const { accessStatus: advancedAnalyticsAccessStatus, openUpgradeModal: openAnalyticsUpsell } =
    useUpsell(SpacePermission.viewAdvancedAnalytics);
  const mustUpgradeAdvancedAnalytics =
    advancedAnalyticsAccessStatus === AccessStatus.insufficientPlan;

  const { openUpgradeModal: openThemeUpsell, accessStatus: customThemeAccessStatus } = useUpsell(
    SpacePermission.manageCustomTheme
  );
  const mustUpgradeCustomTheme = customThemeAccessStatus === AccessStatus.insufficientPlan;

  const hasAssets = useMemo(
    () => inspectables.some(i => !i.item || i.item?.type === ItemType.asset),
    [inspectables]
  );

  function copyShareLinkItem() {
    if (!copyShareLink) return null;
    const title = "Copy share link";
    return <PopupMenu.Item key={title} title={title} onClick={copyShareLink} />;
  }

  function copyTextContentItem() {
    if (inspectables.length !== 1) return null;
    const url = inspectables[0].asset?.meta?.content?.url;
    // Text content can be from text items or link assets
    const textContent = inspectables[0].item?.data.content || url || null;
    if (!textContent) return null;
    const title = url ? "Copy link address" : "Copy";
    return (
      <PopupMenu.Item
        key={title}
        title={title}
        onClick={() => {
          copy(textContent);
        }}
      />
    );
  }

  function editContentItem() {
    if (!canEdit) return null;
    if (!onEditItem) return null;
    if (inspectables.length !== 1) return null;
    const [{ item }] = inspectables;
    if (!item) return null;
    const types = [ItemType.codeSnippet, ItemType.guide, ItemType.note, ItemType.heading];
    if (!types.includes(item.type as ItemType)) return null;
    const title = item.type === ItemType.guide ? "Edit description" : "Edit";
    return (
      <PopupMenu.Item
        key={title}
        title={title}
        onClick={() => {
          onEditItem(item.id);
        }}
      />
    );
  }

  function moveToItem() {
    if (!canEdit) return null;
    if (!moveItems) return null;
    const title = "Move to...";
    return <PopupMenu.Item key={title} title={title} onClick={moveItems} />;
  }

  function copyToItem() {
    if (!canEdit) return null;
    if (!copyItems) return null;
    const title = "Copy to...";
    return <PopupMenu.Item key={title} title={title} onClick={copyItems} />;
  }

  function addToKitItem() {
    if (!canEdit) return null;
    if (!addToKit) return null;
    const title = "Add to...";
    return <PopupMenu.Item key={title} title={title} onClick={addToKit} />;
  }

  function removeFromKitsItem() {
    if (!canEdit) return null;
    if (!removeFromKits) return null;
    const title = "Remove from kits";
    return <PopupMenu.Item key={title} title={title} onClick={removeFromKits} />;
  }

  function editThumbnailItem() {
    if (!canEdit) return null;
    if (!editThumbnail) return null;
    const title = "Change thumbnail";
    return <PopupMenu.Item key={title} title={title} onClick={editThumbnail} />;
  }

  function removeItemThumbnail() {
    if (!canEdit) return null;
    if (!removeThumbnail) return null;
    const title = "Remove thumbnail";
    return <PopupMenu.Item key={title} title={title} onClick={removeThumbnail} />;
  }

  function replaceItem() {
    if (!canEdit) return null;
    if (editColor) {
      const title = "Edit color";
      return <PopupMenu.Item key={title} title={title} onClick={editColor} />;
    } else if (editLink) {
      const title = "Edit link";
      return <PopupMenu.Item key={title} title={title} onClick={editLink} />;
    } else if (replaceFile) {
      let title;
      if (inspectables[0].item?.type === ItemType.gallery) {
        title = inspectables[0].asset ? "Replace cover image" : "Add cover image";
      } else if (inspectables[0].item?.type === ItemType.guide) {
        title = inspectables[0].asset ? "Replace image" : "Add image";
      } else {
        title = inspectables[0].asset ? "Replace file" : "Add file";
      }
      return <PopupMenu.Item key={title} title={title} onClick={replaceFile} />;
    } else if (replaceFont) {
      const title = "Replace font";
      return <PopupMenu.Item key={title} title={title} onClick={replaceFont} />;
    }
  }

  function restoreItem() {
    if (!canEdit) return null;
    if (!restoreItems) return null;
    const title = "Restore";
    return <PopupMenu.Item key={title} title={title} onClick={restoreItems} />;
  }

  function deleteItem() {
    if (!canEdit) return null;
    if (deleteAssets) {
      const title = "Delete";
      return <PopupMenu.Item key={title} title={title} onClick={deleteAssets} />;
    } else if (trashItems) {
      let title;
      if (inspectables[0].item?.itemId) {
        title = "Remove from gallery";
      } else {
        title = "Remove from kit";
      }
      return <PopupMenu.Item key={title} title={title} onClick={trashItems} />;
    } else if (deleteItems) {
      const title = "Delete";
      return <PopupMenu.Item key={title} title={title} onClick={deleteItems} />;
    }
  }

  function updateGalleryName() {
    if (!canEdit) return null;
    if (!renameGalleryItem) return null;
    // See if item has a user-defined name to determine if the gallery is manual or dynamic
    const hasDefinedName = inspectables.every(i => i.item?.data.name);
    return (
      <PopupMenu.Item
        key="configure-gallery"
        title="Configure gallery"
        onClick={() => renameGalleryItem(hasDefinedName ? "manual" : "dynamic")}
      />
    );
  }

  function backgroundColorAsset() {
    if (!canEdit) return null;
    if (!editAssetBackground) return null;
    const titleMap = {
      backgroundColor: "Background color",
      light: "Light (default)",
      dark: "Dark",
      checkered: "Checkered",
      none: "None",
      custom: "Custom",
    };
    const currentBackgroundColor = inspectables[0].asset.meta.backgroundColor || "light";
    const activeCustomTheme = space?.theme?.active;

    return (
      <PopupMenu.Item
        key={titleMap.backgroundColor}
        title={titleMap.backgroundColor}
        hPos={backgroundColorhPos}>
        <PopupMenu.Item
          key={titleMap.none}
          title={titleMap.none}
          onClick={() => editAssetBackground("clear")}
          checked={currentBackgroundColor === "clear"}
          image={{ color: "transparent" }}
        />
        <PopupMenu.Item
          key={titleMap.light}
          title={titleMap.light}
          onClick={() => editAssetBackground("light")}
          checked={currentBackgroundColor === "light"}
          image={{
            color:
              activeCustomTheme && currentTheme.assetLightColor
                ? currentTheme.assetLightColor
                : styles.color.grayLighter,
          }}
        />
        <PopupMenu.Item
          key={titleMap.dark}
          title={titleMap.dark}
          onClick={() => editAssetBackground("dark")}
          checked={currentBackgroundColor === "dark"}
          image={{
            color:
              activeCustomTheme && currentTheme.assetDarkColor
                ? currentTheme.assetDarkColor
                : styles.color.grayDark,
          }}
        />

        <PopupMenu.Item
          key={titleMap.checkered}
          title={titleMap.checkered}
          onClick={() => editAssetBackground("checkered")}
          checked={currentBackgroundColor === "checkered"}
          image={{ iconId: "content.checkered", fill: "gray", size: "16" }}
        />
        <PopupMenu.Item
          key={titleMap.custom}
          title={titleMap.custom}
          checked={currentBackgroundColor.startsWith("#")}
          image={{ iconId: "content.custom-colors", fill: "", size: "16" }}>
          <Box
            onClick={e => {
              e.stopPropagation();
            }}>
            <StyledColorPicker
              color={selectedAssetColor}
              onChangeComplete={(color: ColorResult) => editAssetBackground(color.hex)}
              disableAlpha
            />
          </Box>
        </PopupMenu.Item>
        <PopupMenu.Section data-tooltip-source="theme-editor-button">
          <Flex background="grayLightest" p="8px" alignItems="center" flexDirection="column">
            <Text font="ui.small" textAlign="center">
              Configure preset colors in the
            </Text>
            <Button
              text="theme editor"
              buttonStyle="tertiary"
              size="small"
              disabled={mustUpgradeCustomTheme}
              link={buildPath("/settings/editor?view=gallery", { space })}
            />
          </Flex>
          {mustUpgradeCustomTheme && (
            <UpsellTooltip
              onClick={openThemeUpsell}
              source="theme-editor-button"
              featureName="Custom themes"
              direction={Tooltip.Direction.Top}
            />
          )}
        </PopupMenu.Section>
      </PopupMenu.Item>
    );
  }

  const selectedAssetColor = useMemo(() => {
    if (!inspectables.length) {
      return;
    }
    // Check if all items have the same background color
    const sameBackgroundColor = inspectables.every(
      i => i.asset?.meta?.backgroundColor === inspectables[0]?.asset?.meta?.backgroundColor
    );

    if (sameBackgroundColor) return inspectables[0].asset?.meta?.backgroundColor;

    // Return red so that users can also select using color spectrum slider
    return "#ff0000";
  }, [inspectables]);

  const selectedColor = useMemo(() => {
    if (!inspectables.length) {
      return;
    }
    // Check if all items have the same background color
    const sameBackgroundColor = inspectables.every(
      i => i.item?.data?.backgroundColor === inspectables[0].item?.data?.backgroundColor
    );

    if (sameBackgroundColor && inspectables[0].item?.data?.backgroundColor !== "default")
      return inspectables[0].item?.data?.backgroundColor;

    // Return red so that users can also select using color spectrum slider
    return "#ff0000";
  }, [inspectables]);

  function backgroundColorGallery() {
    if (!canEdit) return null;
    if (!editItemBackground) return null;
    if (!inspectables.every(i => i.item?.type === ItemType.gallery)) return null;
    const hasBackgroundColor = inspectables[0].item.data.backgroundColor !== "default";
    const titleMap = {
      backgroundColor: "Background color",
      default: "Default",
      custom: "Custom",
    };

    return (
      <PopupMenu.Item
        key={titleMap.backgroundColor}
        title={titleMap.backgroundColor}
        hPos={backgroundColorhPos}>
        <PopupMenu.Item
          key={titleMap.default}
          title={titleMap.default}
          onClick={() => editItemBackground(null, ItemType.gallery)}
          image={{ color: styles.color.grayLightest }}
          checked={!hasBackgroundColor}
        />
        <PopupMenu.Item
          key={titleMap.custom}
          title={titleMap.custom}
          image={{ iconId: "content.custom-colors", fill: "", size: "16" }}
          checked={hasBackgroundColor}>
          <Box
            onClick={e => {
              e.stopPropagation();
            }}>
            <StyledColorPicker
              color={selectedColor}
              onChangeComplete={(color: ColorResult) =>
                editItemBackground(color.hex, ItemType.gallery)
              }
              disableAlpha
            />
          </Box>
        </PopupMenu.Item>
      </PopupMenu.Item>
    );
  }

  function backgroundColorNote() {
    if (!canEdit) return null;
    if (!editItemBackground) return null;
    if (!inspectables.every(i => i.item?.type === ItemType.note)) return null;

    return (
      <React.Fragment key="note-bg-options">
        <NoteBackgroundColorOptions
          selectedColor={selectedColor}
          editItemBackground={editItemBackground}
        />
      </React.Fragment>
    );
  }

  function displayAsItem() {
    if (!canEdit || !editDisplayStyle) return null;

    return (
      <PopupMenu.Item key="display-as" title="Display as" hPos={backgroundColorhPos}>
        <PopupMenu.Item
          key="display-as-square"
          title="Square"
          checked={inspectables.every(insp => insp.item?.data.displayStyle === "square")}
          onClick={editDisplayStyle.square}
        />
        <PopupMenu.Item
          key="display-as-row"
          title="Row"
          checked={inspectables.every(insp => insp.item?.data.displayStyle === "full_width")}
          onClick={editDisplayStyle.row}
        />
      </PopupMenu.Item>
    );
  }

  function kitCoverItem() {
    if (!canEdit) return null;
    if (!setAsKitCover) return null;
    const title = "Set as kit cover";
    return <PopupMenu.Item key={title} title={title} onClick={setAsKitCover} />;
  }

  const openInsightsModal = useCallback(() => {
    showModal(ModalTypes.INSIGHTS_ASSET, {
      assetId: inspectables[0].item.id,
      assetName: inspectables[0].asset.name || inspectables[0].asset.colors?.[0]?.name,
      version: inspectables[0].item.version,
      space,
    });
  }, [inspectables, showModal, space]);

  function viewInsights() {
    if (inspectables.length !== 1) return null;
    if (inspectables[0].item?.type !== ItemType.asset) return null;
    if (!["owner", "admin"].includes(space.access.role)) return null;

    const title = "View insights";

    return (
      <Fragment key={title}>
        <PopupMenu.Item
          title={title}
          onClick={openInsightsModal}
          disabled={mustUpgradeAdvancedAnalytics}
          data-tooltip-source="insights-menu-item"
        />
        {mustUpgradeAdvancedAnalytics && (
          <Tooltip source="insights-menu-item" direction={Tooltip.Direction.Right}>
            Insights are not available on your current plan.{" "}
            <Button
              buttonStyle="tertiary"
              themeOverrides={{ primaryColor: "white" }}
              fontStyle="ui.smallBold"
              text="View plans."
              onClick={openAnalyticsUpsell}
            />
          </Tooltip>
        )}
      </Fragment>
    );
  }

  // Rendering multi item menu
  if (inspectables.length === 0) return null;
  let title = null;
  if (inspectables.length > 1) {
    title = hasAssets
      ? `${inspectables.length} assets selected`
      : `${inspectables.length} items selected`;
  }

  const firstLevelMenuItems = [
    copyTextContentItem(),
    copyShareLinkItem(),
    moveToItem(),
    copyToItem(),
    addToKitItem(),
    viewInsights(),
  ].filter(Boolean);

  const secondLevelMenuItems = [
    editContentItem(),
    updateGalleryName(),
    backgroundColorAsset(),
    backgroundColorNote(),
    backgroundColorGallery(),
    displayAsItem(),
    editThumbnailItem(),
    removeItemThumbnail(),
    replaceItem(),
    kitCoverItem(),
  ].filter(Boolean);

  const thirdLevelMenuItems = [removeFromKitsItem(), restoreItem(), deleteItem()].filter(Boolean);

  /**
   * If there are no actions to show, return null
   */
  if ([...firstLevelMenuItems, ...secondLevelMenuItems, ...thirdLevelMenuItems].length === 0) {
    return null;
  }

  return (
    <Fragment>
      {firstLevelMenuItems.length > 0 && (
        <PopupMenu.Section title={title}>{firstLevelMenuItems}</PopupMenu.Section>
      )}
      {secondLevelMenuItems.length > 0 && (
        <PopupMenu.Section>{secondLevelMenuItems}</PopupMenu.Section>
      )}
      {thirdLevelMenuItems.length > 0 && (
        <PopupMenu.Section>{thirdLevelMenuItems}</PopupMenu.Section>
      )}
    </Fragment>
  );
};
