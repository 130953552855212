import { Asset, AssetType } from "@thenounproject/lingo-core";
import assetIconMap from "../components/kits/GalleryAssetContent/assetIconMap";

// Helper attempts to solve UI wonkiness where there is a split second
// after `assetProcessing` returns `completed` but the new thumbnails
// haven't loaded into the UI. Without this helper, we will see
// the old audio or zip icon for a split second before new thumbnail renders.
export const getAssetIcon = (asset: Asset): string => {
  const assetType = asset?.type,
    canEditAssetThumbnail = AssetType.audioTypes.has(assetType) || assetType === AssetType.zip,
    audioOrZipAssetHasCustomThumbnail = !!asset?.meta?.preview?.id;
  if (canEditAssetThumbnail && audioOrZipAssetHasCustomThumbnail) {
    return "";
  }

  return assetIconMap[asset.type] || "content.file";
};
