import React from "react";
import { Box, Flex, Text, Badge, Button, openIntercom } from "@thenounproject/lingo-core";
import styled from "styled-components";

interface Props {
  title: string;
  upgradeRequired?: boolean;
  customizationMessage?: string;
  children: React.ReactNode;
  upgradeMessage?: string;
}

const Wrapper = styled(Flex).attrs({
  flexDirection: "column",
  py: "m",
})``;
const Title = styled(Flex).attrs({ alignItems: "center", mb: "s" })``;

const OptionWrapper = styled(Box).attrs({ width: "100%" })<Pick<Props, "upgradeRequired">>`
  ${props =>
    props.upgradeRequired &&
    `
    opacity: .5;
    pointer-events: none;
    user-select: none;
  `}
`;

const ThemeEditorOption: React.FC<Props> = ({
  children,
  title,
  upgradeRequired,
  customizationMessage,
  upgradeMessage,
}) => {
  return (
    <Wrapper>
      <Title>
        <Text font="ui.smallBold" color="black" mr="s">
          {title}
        </Text>
        {upgradeRequired && <Badge id={title} text="Upgrade" tooltip={upgradeMessage} />}
      </Title>
      <OptionWrapper upgradeRequired={upgradeRequired}>{children}</OptionWrapper>
      {customizationMessage && (
        <Box>
          <Text font="ui.small" color="grayDarkest">
            {customizationMessage}
          </Text>
          <Button
            text="Contact us"
            size="small"
            buttonStyle="tertiary"
            onClick={() => openIntercom()}
          />
        </Box>
      )}
    </Wrapper>
  );
};

export default ThemeEditorOption;
