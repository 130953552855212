import { useCallback, useMemo } from "react";

import {
  copy,
  AssetType,
  Item,
  ItemType,
  pluralize,
  slugifyName,
  buildPath,
  buildURL,
} from "@thenounproject/lingo-core";

import useReplaceAssetFile from "@redux/actions/assets/useReplaceAssetFile";
import useCreateItemAsset from "@redux/actions/items/useCreateItemAsset";
import useSaveAssetMetadata from "@redux/actions/assets/useSaveAssetMetadata";
import useBatchUpdateAssetsMetadata from "@redux/actions/assets/useBatchUpdateAssetsMetadata";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import useSetKitCoverWithAsset from "@redux/actions/kits/useUpdateKitCoverWithAsset";
import useNotifications from "@actions/useNotifications";
import useBatchSaveItems from "@redux/actions/items/useBatchSaveItems";
import { Inspectable } from "@constants/Inspector";

import { useGetNavPoint, useGetSections } from "@selectors/getters";
import { buildLibraryAssetUrl } from "@features/library/utils";
import useDeleteAssets from "@redux/actions/assets/useDeleteAssets";
import useDeleteAssetItems from "@redux/actions/assets/useDeleteAssetItems";
import { useHistory } from "@helpers/routerHooks";
import { NavPointTypes } from "@redux/legacy-actions/navPoints";
import useDeleteTags from "@redux/actions/tags/useDeleteTags";
import useUpdateTags from "@redux/actions/tags/useUpdateTags";
import {
  AssetCopyTypes,
  SectionCallbackArgs,
} from "@features/content-management/modals/MoveCopyToSectionModal";
import useMoveItems from "@redux/actions/items/useMoveItems";
import useDuplicateItems from "@redux/actions/items/useDuplicateItems";
import useRemoveAssetThumbnail from "@redux/actions/assets/useRemoveAssetThumbnail";
import useBatchUpdateItemStatus from "@redux/actions/items/useBatchUpdateItemStatus";
import { useSelectedItemsContext } from "@contexts/SelectedItemsProvider";
import useNavPoint from "@hooks/useNavPoint";

function canChangeBackgroundColor(inspectable: Inspectable) {
  return (
    (!inspectable?.item || inspectable.item.type === ItemType.asset) &&
    ![AssetType.color, AssetType.textStyle].includes(inspectable.asset?.type)
  );
}

function canChangeDisplayStyle(item: Item) {
  return item.type === ItemType.asset;
}

export default function useInspectableActions(inspectables: Inspectable[]) {
  const { space, portal } = useNavPoint(),
    history = useHistory(),
    [replaceAssetFile] = useReplaceAssetFile(),
    [createItemAsset] = useCreateItemAsset(),
    [saveAssetMetadata] = useSaveAssetMetadata(),
    [batchUpdateAssetsMetadata] = useBatchUpdateAssetsMetadata(),
    [setKitCoverWithAsset] = useSetKitCoverWithAsset(),
    { showNotification } = useNotifications(),
    { showModal } = useShowModal(),
    [_deleteTags] = useDeleteTags(),
    [_editTag] = useUpdateTags(),
    [batchSaveItems] = useBatchSaveItems(),
    [moveItems] = useMoveItems(),
    [duplicateItems] = useDuplicateItems(),
    [removeAssetThumbnail] = useRemoveAssetThumbnail(),
    [batchUpdateItemStatus] = useBatchUpdateItemStatus();

  const { deselectItems } = useSelectedItemsContext();
  const navPoint = useGetNavPoint();

  const sections = useGetSections();

  const singleItem = inspectables.length === 1 ? inspectables[0] : null;

  const includesGalleryItems = useMemo(
    () => inspectables.some(i => i.item?.itemId),
    [inspectables]
  );

  // Check the nav point to see if we are inside a manual gallery
  const currentItem = navPoint?.item;
  const inspectablesInsideManualGallery = useMemo(() => {
    return !!(currentItem?.type === ItemType.gallery && !currentItem?.data?.viewId);
  }, [currentItem]);

  // MARK : Item Actions
  // -------------------------------------------------------------------------------

  const canRestoreItems = useMemo(
      () => !includesGalleryItems && inspectables.some(i => i.item?.status === "trashed"),
      [includesGalleryItems, inspectables]
    ),
    restoreItems = useCallback(() => {
      if (!canRestoreItems) return;
      const itemIds = inspectables.filter(i => i.item?.status === "trashed").map(i => i.item?.id);
      void batchUpdateItemStatus({ items: itemIds, status: "active" });
    }, [batchUpdateItemStatus, canRestoreItems, inspectables]);

  // We can trash if all items are active asset type & not a recovered asset
  const canTrashItems = useMemo(
      () =>
        (!includesGalleryItems || inspectablesInsideManualGallery) &&
        inspectables.every(
          i =>
            i.item?.type === ItemType.asset &&
            i.item?.status === "active" &&
            (i.item.sectionId || i.item.itemId)
        ),
      [includesGalleryItems, inspectables, inspectablesInsideManualGallery]
    ),
    trashItems = useCallback(() => {
      if (!canTrashItems) return;
      const itemIds = inspectables
        .filter(
          i =>
            i.item?.type === ItemType.asset && i.item?.status === "active" && !i.item.data?.viewId
        )
        .map(i => i.item?.id);
      void batchUpdateItemStatus({ items: itemIds, status: "trashed" });
    }, [batchUpdateItemStatus, canTrashItems, inspectables]);

  // We can delete if all items are trashed OR type is not asset
  const canDeleteItems = useMemo(
      () =>
        !includesGalleryItems &&
        inspectables.every(
          i => i.item && (i.item?.status === "trashed" || i.item?.type !== ItemType.asset)
        ),
      [includesGalleryItems, inspectables]
    ),
    deleteItems = useCallback(() => {
      if (!canDeleteItems) return;
      const itemIds = inspectables.map(i => i.item?.id).filter(Boolean);
      void batchUpdateItemStatus({ items: itemIds, status: "deleted" });
    }, [batchUpdateItemStatus, canDeleteItems, inspectables]);

  // We can move active items of any type
  const canMoveItems = useMemo(
      () => !includesGalleryItems && inspectables.some(i => i.item?.status === "active"),
      [includesGalleryItems, inspectables]
    ),
    moveItemsCallback = useCallback(
      async ({ fromSectionId, selectedSection, selectedKit }: SectionCallbackArgs) => {
        const itemIds = inspectables.map(i => i.item?.id).filter(Boolean);
        const res = await moveItems({
          itemIds,
          fromSectionId,
          toSectionId: selectedSection.id,
        });
        if (res.isSuccess) {
          const currentKitId = inspectables[0]?.item?.kitId;
          showNotification({
            message: `${itemIds.length} ${pluralize("asset", itemIds.length)} moved to`,
            link: {
              text: selectedSection?.name || "Untitled Section",

              url: buildURL(`/s/${selectedSection?.shortId}`, {
                space,
                // Perhaps there is a better way to do this. The goal was to not bounce out
                // of a portal if you copy or move content within the same kit
                portal: selectedKit.kitId === currentKitId ? portal : null,
              }),
            },
          });
        }
      },
      [inspectables, moveItems, portal, showNotification, space]
    ),
    onMoveItems = useCallback(() => {
      if (!canMoveItems) return;
      const itemIds = inspectables.map(i => i.item?.id).filter(Boolean);
      showModal(ModalTypes.MOVE_COPY_TO_SECTION, {
        type: "move",
        fromSectionId: inspectables[0].item?.sectionId,
        kitId: inspectables[0].item?.kitId,
        callback: moveItemsCallback,
        entityText: `${itemIds.length} ${pluralize("asset", itemIds.length)}`,
      });
    }, [canMoveItems, inspectables, moveItemsCallback, showModal]),
    copyItemsCallback = useCallback(
      ({ selectedSection, copyAs }: SectionCallbackArgs) => {
        const itemIds = inspectables.map(i => i.item?.id).filter(Boolean);
        void duplicateItems({
          itemIds,
          toSectionId: selectedSection.id,
          createReferences: copyAs === AssetCopyTypes.reference,
        }).then(response => {
          if (response.isSuccess) {
            showNotification({
              message: `${itemIds.length} ${pluralize("asset", itemIds.length)} copied to`,
              link: {
                text: selectedSection?.name || "Untitled Section",
                url: buildPath(`/s/${selectedSection?.shortId}`, { space }),
              },
            });
            /**
             * If copying from within the same section,
             * refetch the section to update the items.
             */
            // TODO: Migrate this to query mutations
            // if (selectedSection.id === fromSectionUuid) {
            //   // NOTE - Determine which pages the copied items belong to?
            //   void fetchSection({ spaceId: navPoint.spaceId, uuid: fromSectionUuid, version: 0 });
            // }

            // void fetchSection({ spaceId: navPoint.spaceId, uuid: selectedSection.id, version: 0 });
            // dispatch(actions.fetchKitOutline(navPoint.spaceId, navPoint.kitId, 0));
          }
        });
      },
      [duplicateItems, inspectables, showNotification, space]
    ),
    onCopyItems = useCallback(() => {
      if (!canMoveItems) return;
      const itemIds = inspectables.map(i => i.item?.id).filter(Boolean);
      showModal(ModalTypes.MOVE_COPY_TO_SECTION, {
        type: "copy",
        fromSectionId: inspectables[0].item?.sectionId,
        callback: copyItemsCallback,
        entityText: `${itemIds.length} ${pluralize("asset", itemIds.length)}`,
      });
    }, [canMoveItems, copyItemsCallback, inspectables, showModal]);

  //Rename/configure gallery item
  const canRenameGalleryItem = useMemo(() => {
    return (
      singleItem &&
      inspectables.every(i => i.item?.status === "active" && i.item?.type === ItemType.gallery)
    );
  }, [inspectables, singleItem]);
  const renameGalleryItem = useCallback(
    galleryType => {
      if (!canRenameGalleryItem) return;
      showModal(ModalTypes.CREATE_GALLERY_ITEM, {
        item: inspectables[0].item,
        defaultGalleryType: galleryType,
      });
    },
    [canRenameGalleryItem, inspectables, showModal]
  );

  // Edit Asset Background
  const canEditAssetBackgroundColor = useMemo(() => {
    return inspectables.every(i => canChangeBackgroundColor(i));
  }, [inspectables]);
  const editAssetBackground = useCallback(
    (background: string) => {
      if (!canEditAssetBackgroundColor) return;
      const updates = inspectables
        .filter(inspectable => inspectable.asset)
        .map(inspectable => ({
          uuid: inspectable.asset.id,
          meta: { background_color: background },
        }));
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      void batchUpdateAssetsMetadata(updates);
    },
    [canEditAssetBackgroundColor, inspectables, batchUpdateAssetsMetadata]
  );

  //Edit Item Background
  const canEditItemBackgroundColor = useMemo(() => {
      const editableBackgroundTypes = [ItemType.note, ItemType.gallery];
      return inspectables.every(
        i =>
          i.item?.status === "active" &&
          editableBackgroundTypes.includes(i.item?.type) &&
          i.item?.type === inspectables[0].item?.type
      );
    }, [inspectables]),
    editItemBackground = useCallback(
      (background: string, itemType: ItemType) => {
        if (!canEditItemBackgroundColor) return;

        const updates = inspectables
          .filter(i => i.item && i.item?.type === itemType)
          .map(i => ({
            uuid: i.item.id,
            data: { background_color: background },
          }));
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        batchSaveItems({ updatedItems: updates });
      },
      [batchSaveItems, canEditItemBackgroundColor, inspectables]
    );

  // Edit display style
  const canEditDisplayStyle = useMemo(
      () =>
        !includesGalleryItems &&
        inspectables.some(i => i.item?.status === "active" && i.item?.type === ItemType.asset),
      [includesGalleryItems, inspectables]
    ),
    _editDisplayStyle = useCallback(
      (style: Item["data"]["displayStyle"]) => {
        if (!canEditDisplayStyle) return;
        const updates = inspectables
          .filter(i => i.item && canChangeDisplayStyle(i.item))
          .map(i => ({
            uuid: i.item.id,
            data: {
              display_style: style,
            },
          }));
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        batchSaveItems({ updatedItems: updates });
      },
      [batchSaveItems, canEditDisplayStyle, inspectables]
    ),
    editDisplayStyle = {
      row: useCallback(() => _editDisplayStyle("full_width"), [_editDisplayStyle]),
      square: useCallback(() => _editDisplayStyle("square"), [_editDisplayStyle]),
    };

  // Kit Cover
  const canSetKitCover = useMemo(() => {
      if (singleItem?.item?.status !== "active") return false;
      if (singleItem?.item?.type !== ItemType.asset) return false;
      const allowedTypes = [
        AssetType.png,
        AssetType.jpg,
        AssetType.gif,
        AssetType.svg,
        AssetType.pdf,
        AssetType.eps,
        AssetType.tiff,
        ...AssetType.sketchTypes,
      ];
      return allowedTypes.includes(singleItem?.asset?.type ?? "");
    }, [singleItem]),
    setAsKitCover = useCallback(async () => {
      if (!canSetKitCover) return;
      const { item } = singleItem;
      await setKitCoverWithAsset({
        kitId: item.kitId,
        spaceId: item.spaceId,
        assetId: item.asset.id,
      });
    }, [canSetKitCover, setKitCoverWithAsset, singleItem]);

  // MARK : Library actions
  // -------------------------------------------------------------------------------
  const isLibrarySelection = useMemo(() => !inspectables.some(i => i.item), [inspectables]);
  const canAddToKit = isLibrarySelection;
  const addToKit = useCallback(() => {
    const assetIds = inspectables.map(i => i.asset.id);
    showModal(ModalTypes.ADD_ASSETS_TO_KIT, { assetIds });
  }, [inspectables, showModal]);

  const canDeleteAllAssetItems = isLibrarySelection;
  const [_deleteAssetItems] = useDeleteAssetItems();
  const deleteAllAssetItems = useCallback(() => {
    if (!canDeleteAllAssetItems) return;
    showModal(ModalTypes.CONFIRMATION, {
      title: `Remove asset from all kits?`,
      message: "Asset will remain in your library but removed from all kits.",
      buttonText: "Remove",
      buttonProcessingText: "Removing...",
      onConfirm: () => {
        const assetIds = inspectables.map(i => i.asset.id);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        _deleteAssetItems({ assetIds }).then(action => {
          if (!action.error) {
            const items = Object.values(action.response.entities.items ?? {}) as Item[];
            const numKits = items.reduce((acc, item) => {
              acc.add(item.kitId);
              return acc;
            }, new Set()).size;
            if (!numKits) {
              return showNotification({
                message: `The selected ${pluralize(
                  "asset",
                  assetIds.length
                )} has no instances to remove`,
              });
            }
            showNotification({
              message: `Removed ${pluralize("asset", assetIds.length)} from ${numKits} ${pluralize(
                "kit",
                numKits
              )}`,
            });
          } else showNotification({ message: action.error.message, level: "error" });
        });
      },
    });
  }, [_deleteAssetItems, canDeleteAllAssetItems, inspectables, showModal, showNotification]);

  // Delete Assets
  // Only allowed if there are no items
  const canDeleteAssets = isLibrarySelection;
  const [_deleteAssets] = useDeleteAssets();
  const deleteAssets = useCallback(() => {
    if (!canDeleteAssets) return;
    const numAssets = inspectables.length;
    showModal(ModalTypes.CONFIRMATION, {
      title: `Delete ${numAssets} ${pluralize("asset", numAssets)}?`,
      message: `${pluralize(
        "Asset",
        numAssets
      )} will be removed from all your kits and deleted from your space.`,
      buttonText: "Delete",
      buttonProcessingText: "Deleting...",
      onConfirm: async () => {
        const assetIds = inspectables.map(i => i.asset?.id);

        const { response: { result } = {}, error } = await _deleteAssets({ assetIds });
        if (error) {
          showNotification({ message: error.message, level: "error" });
        } else {
          if (navPoint.type === NavPointTypes.LibraryAsset) {
            history.push(buildPath("/library", { space }));
          }
          const errors = result.assets.filter(f => f.error);
          const succeeded = assetIds.length - errors.length;
          if (succeeded) {
            showNotification({
              message: `${succeeded} ${pluralize("asset", succeeded)} deleted`,
            });
          }

          if (errors.length) {
            errors.forEach(err => {
              showNotification({
                message: err?.error?.message || "Failed to delete asset",
                level: "error",
              });
            });
          }

          deselectItems({ type: "assetLibrary", ids: assetIds });
        }
      },
    });
  }, [
    canDeleteAssets,
    inspectables,
    showModal,
    _deleteAssets,
    showNotification,
    navPoint?.type,
    deselectItems,
    history,
    space,
  ]);

  // MARK : Library OR Kit actions
  // -------------------------------------------------------------------------------

  const canReplaceFile = useMemo(() => {
      const replacableItemTypes = [ItemType.supportingImage, ItemType.guide, ItemType.gallery];
      const nonReplaceableAssetTypes = [AssetType.color, AssetType.textStyle, AssetType.URL];
      if (singleItem?.item && singleItem?.item?.status !== "active") return false;
      return (
        replacableItemTypes.includes(singleItem?.item?.type as ItemType) ||
        (singleItem?.asset && !nonReplaceableAssetTypes.includes(singleItem?.asset?.type))
      );
    }, [singleItem]),
    replaceFile = useCallback(() => {
      if (!canReplaceFile) return;
      const callback = (files: File[]) =>
        singleItem.asset
          ? replaceAssetFile({ inspectable: singleItem, file: files[0] })
          : createItemAsset({ item: singleItem.item, file: files[0] });
      showModal(ModalTypes.PICK_FILE, {
        onUploadFiles: callback,
        prompt: "Choose image",
        itemType: singleItem.item?.type,
        assetType: singleItem.asset?.type,
      });
    }, [canReplaceFile, createItemAsset, replaceAssetFile, showModal, singleItem]);

  // Replace Font
  const canReplaceFont = useMemo(() => {
      if (singleItem?.item && singleItem?.item?.status !== "active") return false;
      return singleItem?.asset?.type === AssetType.textStyle;
    }, [singleItem]),
    replaceFont = useCallback(() => {
      if (!canReplaceFont) return;
      const fileCallback = (files: File[]) =>
        replaceAssetFile({ inspectable: singleItem, file: files[0] });
      const metaCallback = (names: string[]) =>
        saveAssetMetadata({
          assetId: singleItem.asset.id,
          data: {
            meta: {
              font: {
                source: "google-fonts",
                font_name: names[0],
              },
            },
          },
        });
      showModal(ModalTypes.CREATE_FONT, {
        onPickFontFiles: fileCallback,
        onPickGoogleFont: metaCallback,
        replace: true,
      });
    }, [canReplaceFont, replaceAssetFile, saveAssetMetadata, showModal, singleItem]);

  // Edit Color
  const canEditColor = useMemo(() => {
      if (singleItem?.item && singleItem?.item?.status !== "active") return false;
      return singleItem?.asset?.type === AssetType.color;
    }, [singleItem]),
    editColor = useCallback(() => {
      if (!canEditColor) return;
      showModal(ModalTypes.CREATE_EDIT_COLOR, {
        asset: singleItem.asset,
      });
    }, [canEditColor, showModal, singleItem?.asset]);

  // Edit Link
  const canEditLink = useMemo(() => {
      if (singleItem?.item && singleItem?.item?.status !== "active") return false;
      return singleItem?.asset?.type === AssetType.URL;
    }, [singleItem]),
    editLink = useCallback(() => {
      if (!canEditLink) return;
      showModal(ModalTypes.CREATE_EDIT_LINK, {
        asset: singleItem?.asset,
      });
    }, [canEditLink, showModal, singleItem?.asset]);

  // Edit Thumbnails
  const canEditThumbnail = useMemo(() => {
      // if asset already has a preview, we will render 'remove thumbnail' instead
      if (singleItem?.asset?.meta?.preview?.id) return false;
      const assetType = singleItem?.asset?.type;

      const editableAsset =
        AssetType.motionTypes.has(assetType) ||
        AssetType.audioTypes.has(assetType) ||
        assetType === AssetType.zip;

      return singleItem?.item?.type === ItemType.asset && editableAsset;
    }, [singleItem]),
    editThumbnail = useCallback(() => {
      if (!canEditThumbnail) return;
      if (AssetType.motionTypes.has(inspectables[0].asset?.type)) {
        showModal(ModalTypes.EDIT_MOTION_ASSET_THUMBNAIL, {
          asset: inspectables[0].asset,
        });
      } else {
        // must be a zip or audio file
        showModal(ModalTypes.EDIT_ASSET_THUMBNAIL, {
          asset: inspectables[0].asset,
        });
      }
    }, [canEditThumbnail, inspectables, showModal]);

  // Remove Thumbnail
  const canRemoveThumbnail = useMemo(() => {
    const hasThumbnail = singleItem?.asset?.meta?.preview?.id;
    const assetType = singleItem?.asset?.type;
    const editableAsset =
      AssetType.motionTypes.has(assetType) ||
      AssetType.audioTypes.has(assetType) ||
      assetType === AssetType.zip;

    return hasThumbnail && editableAsset && singleItem?.item?.type === ItemType.asset;
  }, [singleItem]);

  const removeThumbnail = useCallback(() => {
    if (!canRemoveThumbnail) return;
    void removeAssetThumbnail({ assetId: singleItem.asset.id });
  }, [canRemoveThumbnail, removeAssetThumbnail, singleItem]);

  const canCopyShareLink = singleItem?.item || singleItem?.asset,
    copyShareLink = useCallback(() => {
      const { item, asset } = singleItem;

      if (item) {
        let path = "",
          hash = "",
          assetToken = undefined;
        switch (item.type) {
          case ItemType.asset:
            // Link to asset detail
            path = ["a", slugifyName(item.asset?.name, item.shortId)].join("/");
            assetToken = item.asset?.shareToken;
            break;
          case ItemType.gallery: {
            //Link to gallery
            path = `a/${item.urlId}`;
            break;
          }
          default: {
            // Link to item in the section
            let sectionId = item.sectionId;
            const section = sections[`${sectionId}-${item.version}`];
            if (section?.shortId) {
              sectionId = slugifyName(section.name, section.shortId);
            }
            path = `/s/${sectionId}`;
            hash = item.shortId;
          }
        }
        const url = buildURL(
          path,
          { space, portal, isPublic: true },
          { v: item.version, asset_token: assetToken },
          hash
        );
        copy(url);
      } else if (asset) {
        // Library link
        copy(buildLibraryAssetUrl(asset, space));
      }
    }, [portal, sections, singleItem, space]);

  const canDeleteTags = useMemo(() => {
    // some inspectables have tags
    return inspectables.some(i => Boolean(i.tag));
  }, [inspectables]);

  const deleteTags = useCallback(() => {
    showModal(ModalTypes.CONFIRMATION, {
      title: `Delete ${pluralize("tag", inspectables.length)}?`,
      message: `The selected ${pluralize(
        "tag",
        inspectables.length
      )} will be removed from all assets and deleted from your space.`,
      buttonText: "Delete",
      buttonProcessingText: "Deleting...",
      successNotification: { message: `${pluralize("Tag", inspectables.length)} deleted` },
      onConfirm: async () => {
        const result = await _deleteTags({
          spaceId: space.id,
          keywords: inspectables.map(i => i.tag.value),
        });
        if (result.error) {
          showNotification({
            message: result?.error?.message,
            level: "error",
          });
        } else {
          deselectItems({
            type: "tagLibrary",
            ids: inspectables.map(i => i.tag.value),
          });
        }
        return result;
      },
    });
  }, [_deleteTags, deselectItems, inspectables, showModal, showNotification, space.id]);

  const canEditTag = useMemo(() => {
    // some inspectables have tags
    return Boolean(singleItem?.tag);
  }, [singleItem]);

  const editTag = useCallback(
    (new_keyword: string) => {
      return _editTag({
        spaceId: space.id,
        keywords: [{ keyword: singleItem.tag.value, new_keyword }],
      });
    },
    [_editTag, singleItem, space.id]
  );

  return {
    restoreItems: canRestoreItems ? restoreItems : null,
    moveItems: canMoveItems ? onMoveItems : null,
    copyItems: canMoveItems ? onCopyItems : null,
    trashItems: canTrashItems ? trashItems : null,
    deleteItems: canDeleteItems ? deleteItems : null,

    replaceFile: canReplaceFile ? replaceFile : null,
    replaceFont: canReplaceFont ? replaceFont : null,
    removeThumbnail: canRemoveThumbnail ? removeThumbnail : null,
    editColor: canEditColor ? editColor : null,
    editLink: canEditLink ? editLink : null,

    copyShareLink: canCopyShareLink ? copyShareLink : null,
    editThumbnail: canEditThumbnail ? editThumbnail : null,
    setAsKitCover: canSetKitCover ? setAsKitCover : null,

    renameGalleryItem: canRenameGalleryItem ? renameGalleryItem : null,
    editAssetBackground: canEditAssetBackgroundColor ? editAssetBackground : null,
    editItemBackground: canEditItemBackgroundColor ? editItemBackground : null,

    editDisplayStyle: canEditDisplayStyle ? editDisplayStyle : null,

    // Library
    removeFromKits: canDeleteAllAssetItems ? deleteAllAssetItems : null,
    deleteAssets: canDeleteAssets ? deleteAssets : null,
    addToKit: canAddToKit ? addToKit : null,
    deleteTags: canDeleteTags ? deleteTags : null,
    editTag: canEditTag ? editTag : null,
  };
}
