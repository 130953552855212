import { API, KitVersion, Section } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: string | number;
  kitId: string;
  version?: number;
};

type KitOutline = Omit<KitVersion, "sections"> & { sections: Section[] };

const [useKitOutline, fetchKitOutline] = createQueryAction<Args, KitOutline, string>(
  {
    entity: "kitVersions",
    action: "fetchOutline",
    condition: ({ args }) => Boolean(args.kitId),
    denormalize: { sections: "sections" },
  },
  async ({ args }) => {
    return await API.call<string>({
      endpoint: `spaces/${args.spaceId}/kits/${args.kitId}/outline`,
      method: "GET",
      entity: API.Entity.kitVersion,
      query: {
        v: args.version,
      },
    });
  }
);

export default useKitOutline;
export { fetchKitOutline };
