import { AssetType, Asset, styles, SpaceTheme } from "@thenounproject/lingo-core";
import transparentBackground from "../images/web-access/pattern@2x.png";

export default function getAssetBackground(asset: Asset, theme: SpaceTheme) {
  if (!asset || [AssetType.color, AssetType.textStyle].includes(asset.type))
    return { background: "grayLighter" };
  const activeCustomTheme = theme?.active;
  const darkColor =
    activeCustomTheme && theme.assetDarkColor ? theme.assetDarkColor : styles.color.grayDark;
  const lightColor =
    activeCustomTheme && theme.assetLightColor ? theme.assetLightColor : styles.color.grayLighter;

  const { backgroundColor } = asset.meta;
  if (backgroundColor && backgroundColor.startsWith("#")) {
    return { background: backgroundColor };
  } else if (backgroundColor === "light") {
    return { background: lightColor };
  } else if (backgroundColor === "dark") {
    return { background: darkColor };
  } else if (backgroundColor === "clear") {
    return { background: "rgba(255, 255, 255, 0)" };
  } else if (backgroundColor === "checkered") {
    return {
      backgroundSize: "32px",
      backgroundImage: `url(${transparentBackground})`,
    };
  }
  return { background: lightColor };
}
