import { useEffect } from "react";
import QueryString from "query-string";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useHistory } from "react-router";

export default function () {
  const history = useHistory();
  const queryParams = QueryString.parse(history.location.search);
  const { showModal } = useShowModal();
  useEffect(() => {
    const plan = queryParams.upgrade as string;
    if (plan) {
      queryParams["upgrade"] = undefined;
      history.location.search = QueryString.stringify(queryParams);
      showModal(ModalTypes.CHANGE_SUBSCRIPTION_FLOW, {
        recommendedPlan: plan,
      });
    }
  }, [history.location, queryParams, showModal]);
}
