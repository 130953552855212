import React, { MutableRefObject, useCallback, useLayoutEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import {
  ActivityIndicator,
  Box,
  Kit,
  KitVersion,
  Section,
  Item,
  Text,
  Button,
  Flex,
  pluralize,
  SpacePermission,
  PagingIndicator,
  buildURL,
} from "@thenounproject/lingo-core";
import Helmet from "react-helmet";

import GalleryItems from "./GalleryItems";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { scrollToItem } from "@helpers/items";
import EmptyState from "../EmptyState";
import useGalleryItems from "@redux/actions/items/useGalleryItems";
import { useViewModeContext } from "@contexts/ViewModeProvider";
import useNavPoint from "@hooks/useNavPoint";

export const GalleryTitle = styled(Text).attrs({
  as: "h1",
  font: "gallery.heading",
})`
  pointer-events: none;
`;

const FetchingIndicatorWrapper = styled(Box).attrs({
  background: "rgba(255, 255, 255, 0.8)",
  position: "fixed",
  top: 61,
  bottom: 0,
  right: 0,
  height: "100%",
  zIndex: 1,
})``;

type Props = {
  item: Item;
  section: Section;
  kit: Kit;
  kitVersion: KitVersion;
  getItemUrl: (item: Item) => string;
  canEditContent: boolean;
  onSelectItem: (event: React.MouseEvent, itemId: string, allItems: Item[]) => void;
  setInspectorState: (itemIds: string[]) => void;
  selectedItems: string[];
  scrollContainerRef: MutableRefObject<HTMLDivElement>;
};

const GalleryComponent: React.FC<Props> = ({
  item,
  section,
  kit,
  kitVersion,
  getItemUrl,
  canEditContent,
  onSelectItem,
  setInspectorState,
  selectedItems,
  scrollContainerRef,
}) => {
  const history = useHistory();
  const location = useLocation();
  const { showModal } = useShowModal();
  const { space, portal } = useNavPoint();
  const { galleryAssetViewMode } = useViewModeContext();

  // MARK : Data
  // -------------------------------------------------------------------------------
  const {
    data: { items, total } = {},
    isLoading,
    error: loadingError,
    fetchNextPage,
  } = useGalleryItems({
    itemId: item.id,
    version: section.version,
  });

  const hasMore = items?.length >= total ? false : true;

  // MARK : Actions
  // -------------------------------------------------------------------------------
  const openConfigureModal = useCallback(() => {
    showModal(ModalTypes.CREATE_GALLERY_ITEM, { item, defaultGalleryType: "dynamic" });
  }, [item, showModal]);

  // MARK : Scrolling to an item if the URL has a hash
  // -------------------------------------------------------------------------------
  const headingHash = useMemo(() => {
    if (!location.hash) return;
    // Ignore hashes if the url is not the current page. This can cause issues if we
    // link to another page with a hash since this page may "consume" the hash while
    // the nav point is loading.
    const onPage = location.pathname.includes(item.shortId) || location.pathname.includes(item.id);
    return onPage ? location.hash?.replace("#", "") : null;
  }, [item.id, item.shortId, location.hash, location.pathname]);

  useLayoutEffect(() => {
    if (!headingHash) return;
    // If we found the hash or there are no more items
    // remove it from the URL so we don't scroll again
    if (scrollToItem(headingHash) || !hasMore) {
      history.replace(`${location.pathname}${location.search}`);
    } else if (!isLoading) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasMore, headingHash, history, isLoading, location.pathname, location.search]);
  function renderFetchingIndicator() {
    if (headingHash) {
      const container = scrollContainerRef.current;
      if (!container) return;

      return (
        <FetchingIndicatorWrapper width={"calc(100% - 320px)"} height="100%">
          <ActivityIndicator size="large" center />
        </FetchingIndicatorWrapper>
      );
    }
  }

  const renderTitle = section && kitVersion,
    themeActive = space?.theme.active,
    docTitle = renderTitle ? `${kit.name}: ${section.name}${themeActive ? "" : " on Lingo"}` : null,
    itemProcessing = item.data.itemProcessing === "pending";

  const sectionUrl = buildURL(
    `/s/${section.urlId}`,
    { space, portal },
    { v: item.version },
    item.shortId
  );

  function renderContent() {
    if (itemProcessing) {
      return (
        <EmptyState
          title="Check back in a minute"
          subtitle="This gallery is being prepared, assets will appear soon."
        />
      );
    } else if (item.data.assets === 0) {
      return (
        <EmptyState
          title="No assets"
          subtitle="There are no assets in the configured library view."
        />
      );
    }
    if (isLoading && !items?.length) {
      return <ActivityIndicator mt="xxl" center />;
    } else if (loadingError && !items?.length) {
      return (
        <EmptyState
          title="Unable to load gallery"
          subtitle={loadingError.message}
          iconProps={{ iconId: "error", fill: "error" }}
          button={{ text: "Retry", onClick: fetchNextPage }}
        />
      );
    } else {
      return (
        <>
          <GalleryItems
            section={null}
            item={item}
            items={items}
            getItemUrl={getItemUrl}
            onSelectItem={onSelectItem}
            setInspectorState={setInspectorState}
            selectedItems={selectedItems}
            scrollContainerRef={scrollContainerRef}
            canEditContent={canEditContent}
            assetViewMode={galleryAssetViewMode}
          />

          <PagingIndicator
            hasMore={!itemProcessing && hasMore}
            data={items}
            isLoading={isLoading}
            error={loadingError}
            fetchNextPage={fetchNextPage}
          />
        </>
      );
    }
  }

  return (
    <>
      {docTitle ? <Helmet title={docTitle} /> : null}
      {renderFetchingIndicator()}

      <div>
        <Button
          buttonStyle="tertiary"
          text="Back to section"
          iconBefore="arrow-left"
          size="small"
          link={sectionUrl}
          mb="m"
        />
        <Flex alignItems="center" mb="xl">
          <GalleryTitle flex="1">{item.data.viewName}</GalleryTitle>
          <Flex gap="16px" alignItems="center">
            <Text font="ui.regularSemiBold">
              {item.data.assets} {pluralize("asset", item.data.assets)}
            </Text>
            {space.access.permissions.includes(SpacePermission.manageViews) && (
              <Button
                size="small"
                buttonStyle="secondary"
                onClick={openConfigureModal}
                text="Configure"
              />
            )}
          </Flex>
        </Flex>
        {renderContent()}
      </div>
    </>
  );
};

export default GalleryComponent;
