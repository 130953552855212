import { MarketingButtons } from "../../components/MarketingButtons";
import { PageContent } from "../../pages/templates/PageLayout";
import { exampleLinks } from "./exampleLinks";
import featureCards from "../featureCards";
import Images from "../images";

const data: PageContent = {
  style: "light",
  sections: [
    {
      type: "HeroSection",
      title: "Digital brand guidelines platform",
      subtitle: `Say goodbye to stale PDF style guides. With Lingo, anyone can easily create, share update and use a modern living digital style guide. [See an example](${exampleLinks.digitalBrandGuidelines}).`,
      primaryButton: MarketingButtons.getStarted,
      secondaryButton: MarketingButtons.bookDemo,
      image: {
        src: Images.Solutions.brandGuidelines_hero,
        alt: "Brand guidelines",
      },
    },
    {
      type: "MediaSection",
      title: "Your brand in one place",
      subtitle:
        "Logos, colors, fonts, photos, videos, illustrations and more in a single location.",
      layoutDirection: "image-left",
      body: {
        style: "tabList",
        items: [
          {
            title: "Content and Context. Together.",
            body: "Still attaching a stale style guide PDF whenever someone needs to use your logo? With Lingo people can grab the content they need and learn how to use it in the same spot.",
            image: {
              src: Images.Solutions.brandGuidelines_contentContext,
              alt: "Content and Context. Together.",
            },
          },
          {
            title: "Advanced custom branding",
            body: "Tell your brand’s unique story by customizing how your assets are categorized and ordered. Better yet, choose a custom font, color and web link to make your library feel official.",
            image: {
              src: Images.Solutions.brandGuidelines_customBranding,
              alt: "Advanced custom branding",
            },
          },
          {
            title: "Private and public sharing",
            body: "Keep your style guide private so only people with permission can have access, or make your library public so anyone with the link can have access - no account required.",
            image: {
              src: Images.Solutions.brandGuidelines_publicSharing,
              alt: "Advanced custom branding",
            },
          },
          {
            title: "Version your style guide",
            body: "Control how changes to your style guide are released to the rest of your team. Roll back to a previous version if needed.",
            image: {
              src: Images.Solutions.brandGuidelines_versionHistory,
              alt: "Version your style guide",
            },
          },
        ],
      },
    },
    {
      type: "CardSection",
      title: "More features to manage your brand guidelines",
      footerButton: {
        link: "/product/features",
        text: "View all features",
        buttonStyle: "outline",
      },
      items: [
        featureCards.customBranding,
        featureCards.customDomains,
        featureCards.usageGuidelines,
        featureCards.fileConversions,
        featureCards.tags,
        featureCards.kitVersioning,
      ],
    },
    {
      type: "FaqSection",
      title: "Frequently asked questions",
      faqs: [
        {
          question: "What is a brand style guide?",
          answer:
            "A brand style guide is a digital rulebook that documents the main components of your brand and provides instructions on how those components should be used.",
        },
        {
          question: "Why are brand style guides important?",
          answer:
            "Having a brand style guide helps people better understand your brand’s values, purpose and design language. Armed with this information, people are empowered to create more consistent experiences across all of your brand touchpoints. These consistent and reliable experiences can lead customers to develop loyalty toward your brand.",
        },
        {
          question: "What content should be documented in a brand style guide?",
          answer:
            "The basic building blocks of a brand style guide are: mission statement or purpose of company, logo guidelines, the brand’s color palette, typography and personality guidelines. Access to downloadable files is just as important as the documentation and you can include it all in one place with Lingo.",
        },
        {
          question: "How do I create a brand style guide?",
          answer: `To create a brand style guide you first need to do an inventory of your brand. Gather all of your brand's digital assets in one place so you can organize them. Then you'll want to categorize the assets. Most style guides document their logos, colors, typography, mission statement and voice and tone. Here's an example of a real life brand style guide built using Lingo. [See an example](${exampleLinks.digitalBrandGuidelines})`,
        },
      ],
    },
    { type: "CtaSection" },
  ],
};

export default data;
