import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import {
  Flex,
  Text,
  Button,
  DropdownButton,
  PopupMenu,
  useBoolean,
  buildPath,
  Space,
} from "@thenounproject/lingo-core";

import { SpaceThemePreviews, unsavedChangesModalTextProps } from "../types";

import useShowModal, { ModalTypes } from "@redux/actions/useModals";

interface Props {
  space: Space;
  themeEditorView: SpaceThemePreviews;
  changed: boolean;
  loading: boolean;
  setThemeEditorView: (type: SpaceThemePreviews) => void;
  saveTheme: () => void;
}

const HeaderContainer = styled(Flex).attrs({
  p: "m",
  justifyContent: "space-between",
  width: "100%",
  borderBottom: "default",
  background: "white",
})``;

const viewTextMap = {
  ["Home"]: "Portal home",
  ["Kit Gallery"]: "Kit in portal",
};

const ThemeEditorHeader: React.FC<Props> = ({
  space,
  themeEditorView,
  setThemeEditorView,
  changed,
  loading,
  saveTheme,
}) => {
  const hasPortalsFeature = space.features.includes("portals");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { showModal } = useShowModal();
  const [previewMenuShown, showPreviewMenu, hidePreviewMenu] = useBoolean(false);
  const history = useHistory();

  function handleExitEditor() {
    if (changed) {
      return showModal(ModalTypes.CONFIRMATION, {
        ...unsavedChangesModalTextProps,
        onConfirm: () => history.push(buildPath("/settings", { space })),
      });
    }
    return history.push(buildPath("/settings", { space }));
  }

  return (
    <HeaderContainer>
      <Flex alignItems="center">
        <Button
          icon="arrow-left"
          size="small"
          mr="s"
          onClick={handleExitEditor}
          themeOverrides={{
            primaryColor: "white",
            primaryColorDark: "grayLight",
            primaryColorTint: "black",
          }}
        />
        <Text font="ui.regularBold">Theme Editor</Text>
      </Flex>
      <DropdownButton
        text={hasPortalsFeature ? viewTextMap[themeEditorView] : themeEditorView}
        data-popup-source="theme-preview-menu"
        onClick={showPreviewMenu}
        styleOverrides={{ width: "120px" }}
      />
      <PopupMenu
        data-testid="theme-preview-menu"
        source="theme-preview-menu"
        width="120"
        vPos="alignTop"
        hPos="alignLeft"
        open={previewMenuShown}
        close={hidePreviewMenu}
        stayOpenOnClick={false}
        clickAwayClose={true}>
        <PopupMenu.Item
          key="home"
          font="ui.small"
          title={hasPortalsFeature ? viewTextMap[SpaceThemePreviews.home] : SpaceThemePreviews.home}
          checked={themeEditorView === SpaceThemePreviews.home}
          onClick={() => {
            setThemeEditorView(SpaceThemePreviews.home);
          }}
        />
        <PopupMenu.Item
          key="kit-gallery"
          font="ui.small"
          title={
            hasPortalsFeature ? viewTextMap[SpaceThemePreviews.gallery] : SpaceThemePreviews.gallery
          }
          checked={themeEditorView === SpaceThemePreviews.gallery}
          onClick={() => {
            setThemeEditorView(SpaceThemePreviews.gallery);
          }}
        />
      </PopupMenu>
      <Button
        text={loading ? "Saving..." : "Save Changes"}
        size="small"
        disabled={loading || !changed}
        onClick={saveTheme}
      />
    </HeaderContainer>
  );
};

export default ThemeEditorHeader;
