import React from "react";
import { Flex, Text, useBoolean, Box, Button, SpaceTheme } from "@thenounproject/lingo-core";
import styled from "styled-components";
import { ColorResult } from "react-color";

import { ColorMap, SpaceThemeColorOptions } from "@features/theming/types";
import { parseThemeColors } from "@features/theming/utils/parseThemeColors";
import { parseDefaultTextColorFromTheme } from "@features/theming/utils/parseDefaultTextColorFromTheme";
import useNotifications from "@actions/useNotifications";
import ColorPickerComponent from "./ColorPickerComponent";

type PickerDirection = "top" | "bottom";

interface Props {
  themeColorOption: SpaceThemeColorOptions;
  newTheme: SpaceTheme;
  existingTheme: SpaceTheme;
  updateTheme: (data: Partial<SpaceTheme>) => void;
}

const Wrapper = styled(Flex).attrs({
  width: "100%",
  flexDirection: "column",
})``;

const SwatchWrapper = styled(Flex).attrs({ as: "button", p: 0, alignItems: "center" })`
  button {
    height: 18px;
    width: 18px;
    border-radius: 2px;
    margin-right: 6px;
  }
`;

const Swatch = styled(Box).attrs(props => {
  return {
    height: "18px",
    width: "18px",
    mr: "6px",
    p: "0",
    borderRadius: "2px",
    background: props.color,
    border: "default",
  };
})``;

const ThemeColorPicker: React.FC<Props> = ({
  existingTheme,
  newTheme,
  updateTheme,
  themeColorOption,
}) => {
  const { showNotification } = useNotifications();

  const [pickerOpen, setPickerOpen, setPickerClosed] = useBoolean(false);
  const [pickerDirection, setPickerDirection] = React.useState<PickerDirection>("bottom");

  const colorMap: ColorMap = {
    [SpaceThemeColorOptions.accent]: {
      new: newTheme.primaryColor,
      existing: existingTheme.primaryColor,
    },
    [SpaceThemeColorOptions.header]: {
      new: newTheme.headerBackgroundColor,
      existing: existingTheme.headerBackgroundColor,
    },
    [SpaceThemeColorOptions.headerDescription]: {
      new: newTheme.headerDescriptionColor,
      existing: existingTheme.headerDescriptionColor,
    },
    [SpaceThemeColorOptions.headerTitle]: {
      new: newTheme.headerTitleColor,
      existing: existingTheme.headerTitleStyle,
    },
    [SpaceThemeColorOptions.noteInfo]: {
      new: newTheme.noteInfoColor,
      existing: existingTheme.noteInfoColor,
    },
    [SpaceThemeColorOptions.noteSuccess]: {
      new: newTheme.noteSuccessColor,
      existing: existingTheme.noteSuccessColor,
    },
    [SpaceThemeColorOptions.noteWarning]: {
      new: newTheme.noteWarningColor,
      existing: existingTheme.noteWarningColor,
    },
    [SpaceThemeColorOptions.assetLight]: {
      new: newTheme.assetLightColor,
      existing: existingTheme.assetLightColor,
    },
    [SpaceThemeColorOptions.assetDark]: {
      new: newTheme.assetDarkColor,
      existing: existingTheme.assetDarkColor,
    },
  };

  const currentColor = colorMap[themeColorOption].new;
  const existingColor = colorMap[themeColorOption].existing;

  const onOpenColorPicker = (e: React.MouseEvent) => {
    setPickerOpen();
    setPickerDirection(e.clientY > window.innerHeight / 2 ? "top" : "bottom");
  };

  const handleChangeComplete = (color: ColorResult) => {
    switch (themeColorOption) {
      case SpaceThemeColorOptions.accent: {
        const colors = parseThemeColors(color.hex);
        if (colors.lightness > 0.85) {
          return showNotification({ message: "Color contrast too low", level: "error" });
        }
        updateTheme({ ...colors });
        break;
      }
      case SpaceThemeColorOptions.header: {
        updateTheme({ headerBackgroundColor: color.hex });
        break;
      }
      case SpaceThemeColorOptions.headerDescription: {
        updateTheme({ headerDescriptionColor: color.hex });
        break;
      }
      case SpaceThemeColorOptions.headerTitle: {
        updateTheme({ headerTitleColor: color.hex });
        break;
      }
      case SpaceThemeColorOptions.noteInfo: {
        updateTheme({ noteInfoColor: color.hex });
        break;
      }
      case SpaceThemeColorOptions.noteSuccess: {
        updateTheme({ noteSuccessColor: color.hex });
        break;
      }
      case SpaceThemeColorOptions.noteWarning: {
        updateTheme({ noteWarningColor: color.hex });
        break;
      }
      case SpaceThemeColorOptions.assetLight: {
        updateTheme({ assetLightColor: color.hex });
        break;
      }
      case SpaceThemeColorOptions.assetDark: {
        updateTheme({ assetDarkColor: color.hex });
        break;
      }
      default:
        console.error(`No change handler case for ${themeColorOption}`);
    }
  };

  const handleReset = () => {
    switch (themeColorOption) {
      case SpaceThemeColorOptions.accent: {
        const { primaryColor, primaryColorDark, primaryColorTint } = existingTheme;
        updateTheme({ primaryColor, primaryColorDark, primaryColorTint });
        break;
      }
      case SpaceThemeColorOptions.header: {
        const { headerBackgroundColor } = existingTheme;
        updateTheme({ headerBackgroundColor });
        break;
      }
      case SpaceThemeColorOptions.headerDescription: {
        updateTheme({ headerDescriptionColor: parseDefaultTextColorFromTheme(existingTheme) });
        break;
      }
      case SpaceThemeColorOptions.headerTitle: {
        updateTheme({ headerTitleColor: parseDefaultTextColorFromTheme(existingTheme) });
        break;
      }
      case SpaceThemeColorOptions.noteInfo: {
        updateTheme({ noteInfoColor: existingTheme.noteInfoColor });
        break;
      }
      case SpaceThemeColorOptions.noteSuccess: {
        updateTheme({ noteSuccessColor: existingTheme.noteSuccessColor });
        break;
      }
      case SpaceThemeColorOptions.noteWarning: {
        updateTheme({ noteWarningColor: existingTheme.noteWarningColor });
        break;
      }
      case SpaceThemeColorOptions.assetLight: {
        updateTheme({ assetLightColor: existingTheme.assetLightColor });
        break;
      }
      case SpaceThemeColorOptions.assetDark: {
        updateTheme({ assetDarkColor: existingTheme.assetDarkColor });
        break;
      }
      default:
        console.error(`No reset handler case for ${themeColorOption}`);
    }
  };

  return (
    <Wrapper>
      <Flex justifyContent="space-between" width="100%" alignItems="center">
        <SwatchWrapper onClick={onOpenColorPicker}>
          <Swatch color={currentColor} aria-label={`${themeColorOption} color swatch`} />
          <Text font={"ui.small"}>{themeColorOption}</Text>
        </SwatchWrapper>
        <Button
          aria-label={`${themeColorOption} color reset button`}
          text="Reset"
          size="small"
          buttonStyle="tertiary"
          onClick={handleReset}
          disabled={currentColor === existingColor}
        />
      </Flex>
      <ColorPickerComponent
        pickerOpen={pickerOpen}
        setPickerClosed={setPickerClosed}
        pickerDirection={pickerDirection}
        currentColor={currentColor}
        handleColorSelect={handleChangeComplete}
      />
    </Wrapper>
  );
};

export default ThemeColorPicker;
