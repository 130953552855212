import _isEqual from "lodash/isEqual";
import QueryString from "query-string";

import {
  Repo,
  LingoError,
  ItemType,
  Space,
  Portal,
  Kit,
  Item,
  Section,
  KitVersion,
  isError,
  ErrorCode,
  Asset,
  KitCollection,
  SpacePermission,
  buildURL,
} from "@thenounproject/lingo-core";

import { identifyDomain as identifyDomainAction } from "@actions/spaces/useIdentifyDomain";
import { fetchItem } from "@actions/items/useItem";
import { fetchKitOutline } from "@actions/kitVersions/useKitOutline";
import { fetchSection } from "@redux/actions/sections/useSection";
import { fetchCurrentUser } from "@queries/useCurrentUser";
import { fetchSpace } from "@redux/actions/spaces/useSpace";
import { fetchAsset } from "@redux/actions/assets/useAsset";
import { fetchKit } from "@redux/actions/kits/useKit";
import { fetchKitCollection } from "@redux/actions/kitCollections/useKitCollection";
import { fetchCustomFields } from "@redux/actions/fields/useCustomFields";

import { AppDispatch, GetState } from "@redux/store/reduxStore";
import { History } from "history";
import { createAction } from "@redux/actions/actionCreators";
import { useAppDispatchV1 } from "@redux/hooks";
import { fetchPortal } from "@redux/actions/portals/usePortal";
import { useHistory } from "react-router";
import { GalleryContext } from "../../components/kits/Kit";

export type NavPoint = {
  type: NavPointTypes;
  loaded?: boolean;
  error?: LingoError;
  spaceId?: number;
  space?: Space;
  version?: number;
  role?: string;
  name?: string;
  portalId?: string;
  portal?: Portal;
  kitId?: string;
  kit?: Kit;
  kitVersion?: KitVersion;
  kitCollection?: KitCollection;
  kitCollectionId?: string;
  sectionId?: string;
  section?: Section;
  gallery?: Item;
  itemId?: string;
  item?: Item;
  asset?: Asset;
  assetId?: string;
  assetToken?: string;
  insightsPage?: string;
  galleryContext?: string;
  settingsPage?: string;
  public?: boolean;
  search?: string;

  // Internal
  redirect?: string;
  userFetched?: boolean;
  spaceFetched?: boolean;
  itemFetched?: boolean;
  spaceOptional?: boolean;
  kitCollectionFetched?: boolean;
  subdomain?: string;
  // viewsFetched?: boolean;
  fieldsFetched?: boolean;
  galleryFetched?: boolean;
  galleryId?: string;
  sectionOptional?: boolean;
  assetFetched?: boolean;
  sectionFetched?: boolean;
  portalFetched?: boolean;
  portalOptional?: boolean;
  kitFetched?: boolean;
  kitOptional?: boolean;
  kitVersionFetched?: boolean;
  kitVersionOptional?: boolean;
};

export enum NavPointTypes {
  "LibraryAsset" = "LibraryAsset",
  "Item" = "Item",
  "Gallery" = "Gallery",
  "Section" = "Section",
  "Kit" = "Kit",
  "KitVersions" = "KitVersions",
  "KitTrashedItems" = "KitTrashedItems",
  "UserSettings" = "UserSettings",
  "SpaceBilling" = "SpaceBilling",
  "SpaceSearch" = "SpaceSearch",
  "SpaceSettings" = "SpaceSettings",
  "SpaceUsers" = "SpaceUsers",
  "Insights" = "Insights",
  "NewSpace" = "NewSpace",
  "SpaceKits" = "SpaceKits",
  "KitCollection" = "KitCollection",
  "DefaultSpace" = "DefaultSpace",
  "Library" = "Library",
  "TagManagement" = "TagManagement",
  "CustomFields" = "CustomFields",
  "KitRecoveredAssets" = "KitRecoveredAssets",
  "Dashboard" = "Dashboard",
  "Portal" = "Portal",
}

export const [, setNavPoint] = createAction<NavPoint>("navPoint/set");

// MARK : Fetchers
// -------------------------------------------------------------------------------

type NavPointLoader = (
  navPoint: NavPoint
) => (dispatch: AppDispatch, getState: GetState) => Promise<Partial<NavPoint>>;

const fetchUserIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.userFetched) return {};

  const {
    user: { isFetched },
  } = getState();
  if (isFetched) return { userFetched: true };
  const res = await dispatch(fetchCurrentUser());

  if (fetchCurrentUser.fulfilled.match(res)) {
    return { userFetched: true };
  } else if (fetchCurrentUser.rejected.match(res)) {
    // We don't need the user for public access
    return { userFetched: true };
  }
};

const identifyDomain: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.spaceId || !navPoint.subdomain) return {};
  const { subdomain } = navPoint;

  try {
    const res = await identifyDomainAction.lazy({ dispatch, getState }, { identifier: subdomain });
    if (!res.portalId && !res.spaceId) {
      return {
        error: { code: ErrorCode.objectNotFound, message: "We couldn't find anything here" },
      };
    }
    // If the domain is for a portal, don't set the space id
    // this will force the portal to fetch first, which should include the space
    // otherwise the space fetch will possibly fail
    return { spaceId: res.portalId ? undefined : res.spaceId, portalId: res.portalId };
  } catch (error) {
    if (isError(error)) {
      if (navPoint.spaceOptional && error.details?.space_id) {
        return { spaceId: error.details.space_id };
      }
      return { error };
    } else {
      throw error;
    }
  }
};

export const fetchSpaceIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.spaceFetched || !navPoint.spaceId) {
    return {};
  }

  const { spaceId } = navPoint,
    repo = new Repo(getState()),
    cached = repo.getSpace(spaceId);

  if (cached) return { spaceId: cached.id, spaceFetched: true };

  const res = await dispatch(fetchSpace({ args: { spaceId: spaceId } }));
  if (fetchSpace.fulfilled.match(res)) {
    const space = res.payload.entities.spaces[res.payload.result];
    return { spaceId: space.id, spaceFetched: true };
  } else if (fetchSpace.rejected.match(res)) {
    const error = res.payload;
    if (navPoint.spaceOptional) {
      return error.details?.space_id ? { spaceId: error.details.space_id } : {};
    }
    return { error };
  }
};

export const fetchKitCollectionIfNeeded: NavPointLoader =
  navPoint => async (dispatch, getState) => {
    if (!navPoint.kitCollectionId) return {};
    if (navPoint.kitCollectionFetched || !navPoint.spaceId) {
      return {};
    }

    const { kitCollectionId } = navPoint,
      repo = new Repo(getState()),
      cached = repo.getKitCollection(kitCollectionId);

    if (cached) return { kitCollectionId: cached.uuid, kitCollectionFetched: true };

    const res = await dispatch(fetchKitCollection({ args: { collectionId: kitCollectionId } }));
    if (fetchKitCollection.fulfilled.match(res)) {
      return { kitCollectionId: res.payload.result, kitCollectionFetched: true };
    } else if (fetchKitCollection.rejected.match(res)) {
      if (navPoint.portalId) {
        return {
          redirect: navPoint.subdomain
            ? `/p/${navPoint.portalId}`
            : `/${navPoint.spaceId}/p/${navPoint.portalId}`,
        };
      }
      return { error: res.payload };
    }
  };

export const fetchCustomFieldsIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.fieldsFetched || !navPoint.spaceId) {
    return {};
  }

  const state = getState();
  const repo = new Repo(state),
    space = repo.getSpace(navPoint.spaceId);

  if (!space) return {};
  if (!space.features?.includes("custom_fields")) return { fieldsFetched: true };
  const cached = Object.values(state.entities.fields.objects).find(f => f.spaceId === space.id);
  if (cached) return { fieldsFetched: true };

  const res = await dispatch(
    fetchCustomFields({
      args: {
        spaceId: space.id,
        kitUuid: navPoint.kitId,
        assetId: navPoint.assetId,
        assetToken: navPoint.assetToken,
      },
    })
  );

  if (fetchCustomFields.fulfilled.match(res)) {
    return { fieldsFetched: true };
  } else if (fetchCustomFields.rejected.match(res)) {
    return { fieldsFetched: true };
    // return { error: res.payload };
  }
};

export const fetchItemIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.itemFetched || !navPoint.itemId) return {};
  if (!navPoint.spaceId) return {};

  const { spaceId, itemId, version, assetToken } = navPoint,
    repo = new Repo(getState()),
    cached = repo.getItem(itemId, version);

  function makeResult(item: Item) {
    const shared = {
      itemId: item.id,
      galleryId: item.itemId,
      sectionId: item.sectionId,
      kitId: item.kitId,
      version: item.version,
      assetId: item.assetId,
      itemFetched: true,
    };
    if (item.type === ItemType.gallery) {
      return {
        type: NavPointTypes.Gallery,
        galleryFetched: true,
        name: item?.data?.name,
        ...shared,
      };
    }
    return {
      ...shared,
      assetToken: item.asset?.shareToken,
      itemFetched: true,
      assetFetched: Boolean(item.asset),
    };
  }

  if (cached) return makeResult(cached);

  const res = await dispatch(
    fetchItem({
      args: { spaceId: spaceId, itemId, version, assetToken },
    })
  );
  if (fetchItem.fulfilled.match(res)) {
    const item = res.payload.entities.items[res.payload.result];
    return makeResult(item);
  } else if (fetchItem.rejected.match(res)) {
    const error = res.payload;
    if (error.details?.access === "password" && !navPoint.kitId) {
      return { kitId: error.details.kit_uuid };
    }
    return { error: res.payload };
  }
  return { itemFetched: true };
};

export const fetchGalleryIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.galleryFetched || !navPoint.galleryId) return {};
  if (!navPoint.spaceId) return {};

  const { spaceId, galleryId, version } = navPoint,
    makeResult = (gallery: Item) => ({
      galleryId: gallery.id,
      gallery: gallery,
      galleryFetched: true,
    }),
    repo = new Repo(getState()),
    cached = repo.getItem(galleryId, version);

  if (cached) return makeResult(cached);

  const res = await dispatch(
    fetchItem({
      args: { spaceId: spaceId, itemId: galleryId, version },
    })
  );
  if (fetchItem.fulfilled.match(res)) {
    const item = res.payload.entities?.items[res.payload.result];
    return makeResult(item);
  } else if (fetchItem.rejected.match(res)) {
    const error = res.payload;
    if (error.details && error.details.access === "password" && !navPoint.kitId) {
      return { kitId: error.details.kit_uuid };
    }
    if (navPoint.sectionOptional) return { galleryFetched: true };
    return { error };
  }
};

export const fetchAssetIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.assetFetched || !navPoint.assetId) return {};
  if (!navPoint.spaceId) return {};

  const { assetId, assetToken } = navPoint,
    makeResult = (asset: Asset) => ({
      assetToken: asset.shareToken,
      assetFetched: true,
    }),
    repo = new Repo(getState()),
    cached = repo.getAsset(assetId);

  if (cached) return makeResult(cached);

  const res = await dispatch(fetchAsset({ args: { assetId, shareToken: assetToken } }));
  if (fetchAsset.fulfilled.match(res)) {
    const asset = res.payload.entities.assets[res.payload.result];
    return makeResult(asset);
  } else if (fetchAsset.rejected.match(res)) {
    return { error: res.payload };
  }
};

export const fetchSectionIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.sectionFetched || !navPoint.sectionId) return {};
  if (!navPoint.spaceId) return {};

  const { spaceId, sectionId, version } = navPoint,
    makeResult = (section: Section) => ({
      sectionId: section.id,
      kitId: section.kitId,
      version: section.version,
      sectionFetched: true,
    }),
    repo = new Repo(getState()),
    cached = repo.getSection(sectionId, version);

  // We need counts to display a section
  if (cached && cached.counts) return makeResult(cached);

  const res = await dispatch(
    fetchSection({
      args: {
        spaceId: spaceId,
        sectionId: sectionId,
        version,
      },
    })
  );
  if (fetchSection.fulfilled.match(res)) {
    const section = res.payload.entities.sections[res.payload.result];
    return makeResult(section);
  } else if (fetchSection.rejected.match(res)) {
    const error = res.payload;
    if (error.details && error.details.access === "password" && !navPoint.kitId) {
      return { kitId: error.details.kit_uuid };
    }
    if (navPoint.sectionOptional) return { sectionFetched: true };
    return { error };
  }
};

export const fetchPortalIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.portalFetched || !navPoint.portalId) return {};

  const { portalId } = navPoint,
    cached = getState().entities.portals.objects[portalId];

  if (cached) {
    return {
      spaceId: cached.spaceId,
      portalId: cached.id,
      portalFetched: true,
    };
  }

  const res = await dispatch(fetchPortal({ args: { portalId } }));
  const result: Partial<NavPoint> = { portalFetched: true };
  if (fetchPortal.fulfilled.match(res)) {
    result.portalId = res.payload.result;
    if (!navPoint.spaceId) {
      const portal = res.payload.entities.portals[res.payload.result];
      result.spaceId = portal.spaceId;
    }
  } else if (fetchPortal.rejected.match(res)) {
    if (!navPoint.portalOptional) return { error: res.payload };
    if (navPoint.type === "Item") {
      result.public = true;
    }
  }
  return result;
};

export const fetchKitIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.kitFetched || !navPoint.kitId) return {};
  if (!navPoint.spaceId) return {};

  const { spaceId, kitId } = navPoint,
    repo = new Repo(getState()),
    cached = repo.getKit(kitId);

  if (cached && cached.versions && cached.versions.length) {
    return {
      kitId: cached.kitId,
      kitFetched: true,
    };
  }

  const res = await dispatch(fetchKit({ args: { kitId, spaceId } }));
  const result: Partial<NavPoint> = { kitFetched: true };
  if (fetchKit.fulfilled.match(res)) {
    result.kitId = res.payload.result;
  } else if (fetchKit.rejected.match(res)) {
    if (!navPoint.kitOptional) return { error: res.payload };
    if (navPoint.type === "Item") {
      result.public = true;
    }
  }
  return result;
};

export const fetchKitOutlineIfNeeded: NavPointLoader = navPoint => async (dispatch, getState) => {
  if (navPoint.kitVersionFetched || !navPoint.kitId) return {};
  if (!navPoint.spaceId) return {};
  if (navPoint.type === NavPointTypes.KitVersions) return { kitVersionFetched: true };

  const { spaceId, kitId, version } = navPoint,
    makeResult = (version: KitVersion) => ({
      version: version.version,
      kitVersionFetched: true,
    }),
    repo = new Repo(getState()),
    cached = repo.getVersion(kitId, version);

  if (cached && cached.sections) return makeResult(cached);

  const res = await dispatch(fetchKitOutline({ args: { spaceId, kitId, version } }));

  if (fetchKitOutline.fulfilled.match(res)) {
    const version = res.payload.entities.kitVersions[res.payload.result];
    return makeResult(version);
  } else if (fetchKitOutline.rejected.match(res)) {
    if (navPoint.kitVersionOptional)
      return { kitVersionFetched: true, public: navPoint.kitVersionOptional };
    return { error: res.payload };
  }
};

// MARK : Population
/* ------------------------------------------------------------------------------- */

function isNavPointLoaded(navPoint: NavPoint) {
  let needsLoad: string;
  if ((!navPoint.kitFetched || !navPoint.kitVersionFetched) && navPoint.kitId) needsLoad = "kit";
  else if (!navPoint.itemFetched && navPoint.itemId) needsLoad = "item";
  else if (!navPoint.sectionFetched && navPoint.sectionId) needsLoad = "section";
  else if (!navPoint.spaceFetched && navPoint.spaceId) needsLoad = "space";
  else if (!navPoint.portalFetched && navPoint.portalId) needsLoad = "portal";
  else if (!navPoint.fieldsFetched && navPoint.spaceFetched) needsLoad = "customFields";
  else if (!navPoint.userFetched) needsLoad = "user";
  // For debugging
  // if (needsLoad) {
  //   console.debug(`Needs to load ${needsLoad}`);
  // }
  return !needsLoad;
}

const fetchNavPoint = async (
  navPoint: NavPoint,
  i = 0,
  dispatch: AppDispatch
): Promise<NavPoint> => {
  if (i > 5) return Promise.resolve(navPoint);
  if (navPoint.redirect) return Promise.resolve(navPoint);
  if (navPoint.error) return Promise.resolve(navPoint);
  else if (!isNavPointLoaded(navPoint)) {
    return Promise.all([
      dispatch(fetchUserIfNeeded(navPoint)),
      dispatch(fetchSpaceIfNeeded(navPoint)),
      dispatch(identifyDomain(navPoint)),
      dispatch(fetchItemIfNeeded(navPoint)),
      dispatch(fetchGalleryIfNeeded(navPoint)),
      dispatch(fetchAssetIfNeeded(navPoint)),
      dispatch(fetchSectionIfNeeded(navPoint)),
      dispatch(fetchKitIfNeeded(navPoint)),
      dispatch(fetchPortalIfNeeded(navPoint)),
      dispatch(fetchKitOutlineIfNeeded(navPoint)),
      dispatch(fetchKitCollectionIfNeeded(navPoint)),
      dispatch(fetchCustomFieldsIfNeeded(navPoint)),
    ]).then(entities => {
      const improvedNavPoint = entities.reduce((consolidated, entityNavPoint) => {
        Object.keys(consolidated).forEach(key => {
          if (
            (entityNavPoint[key] === undefined || entityNavPoint[key] === null) &&
            consolidated[key] !== undefined
          ) {
            delete entityNavPoint[key];
          }
        });
        return { ...consolidated, ...entityNavPoint, loaded: false };
      }, navPoint);

      return fetchNavPoint(improvedNavPoint as NavPoint, i + 1, dispatch);
    });
  } else {
    return Promise.resolve({ ...navPoint, loaded: true });
  }
};

// A list of error codes that should be gracefully handled even for public users
const publicErrors: number[] = [ErrorCode.unknown, ErrorCode.serviceUnavailable];
const loadNavPoint =
  (navPoint: NavPoint, history: History) => async (dispatch: AppDispatch, getState: GetState) => {
    function handleError(err) {
      if (!isError(err)) {
        throw err;
      }

      if (err.details && err.details.access === "password") {
        const target = window.location.toString();
        const queryString = QueryString.stringify({
          next: target,
          kitId: err.details.kit_uuid || undefined,
          portalId: err.details.portal_uuid || undefined,
        });
        history.replace(`/enter-password/?${queryString}`);
      } else if (getState().user.id || publicErrors.includes(err.code)) {
        dispatch(setNavPoint({ ...navPoint, loaded: true, failed: true, error: err }));
      } else {
        const target = window.location.toString();
        history.replace(`/login/?next=${target}`);
      }
    }
    try {
      const completeNavPoint = await fetchNavPoint(navPoint, 0, dispatch);
      if (completeNavPoint.redirect) {
        history.replace(completeNavPoint.redirect);
      } else if (completeNavPoint.error) {
        handleError(completeNavPoint.error);
      } else {
        if (!navPoint.portalId && completeNavPoint.portalId) {
          // This is all so that if the portal domain matches the space,
          // allow members to access the direct kit link
          const state = getState();
          const space = state.entities.spaces.objects[completeNavPoint.spaceId];
          const portal = state.entities.portals.objects[completeNavPoint.portalId];

          if (
            navPoint.subdomain &&
            portal &&
            space?.domain === portal?.domain &&
            space?.subdomain === portal?.subdomain &&
            space?.access?.permissions?.includes(SpacePermission.viewDashboard)
          ) {
            completeNavPoint.portalId = null;
            if (navPoint.type === NavPointTypes.Portal) {
              completeNavPoint.type = NavPointTypes.SpaceKits;
            }
          }
          if (completeNavPoint.portalId && navPoint.type === NavPointTypes.SpaceKits) {
            completeNavPoint.type = NavPointTypes.Portal;
          }
        }
        if (completeNavPoint.type === NavPointTypes.SpaceKits) {
          // If the space has access to portals, don't show the old space kits page
          // if no portal was returned
          const state = getState();
          const space = state.entities.spaces.objects[completeNavPoint.spaceId];
          if (space?.access?.isPublic !== false && space?.features?.includes("portals")) {
            handleError({
              code: ErrorCode.objectNotFound,
              message: "We couldn't find anything here",
            });
            return;
          }
        }
        dispatch(setNavPoint(completeNavPoint));
      }
      return completeNavPoint;
    } catch (err) {
      handleError(err);
    }
  };

export function useLoadNavPoint() {
  const dispatch = useAppDispatchV1();
  const history = useHistory();
  return async (navPoint: NavPoint) => await dispatch(loadNavPoint(navPoint, history));
}

// MARK : Getters
/* ------------------------------------------------------------------------------- */

export const buildItemUrl = (
  item: Item,
  context: Pick<NavPoint, "section" | "space" | "portal">
) => {
  if (!item) return null;
  const { space, portal, section } = context,
    currentParams = QueryString.parse(window.location.search),
    { type: itemType, version, asset } = item;

  const { shareToken } = asset || {};

  const params = {
    v: version,
    asset_token: shareToken || undefined,
    kit_token: currentParams.tkn || currentParams.kit_token || undefined,
    previous: currentParams.previous || undefined,
    context: undefined,
  };

  const directLinkTypes = [ItemType.asset, ItemType.gallery];
  if (item.status === "trashed") {
    params.context = GalleryContext.deleted;
  } else if (item.sectionId === null) {
    params.context = GalleryContext.recovered;
  } else if (!directLinkTypes.includes(itemType)) {
    return buildURL(`/s/${section.urlId}`, { space, portal }, params, item.shortId);
  }

  return buildURL(`/a/${item.urlId}`, { space, portal }, params);
};

// MARK : Creators
/* ------------------------------------------------------------------------------- */

type NavPointCreator = (args: {
  match: { params: Record<string, string> };
  query: QueryString.ParsedQuery<string>;
  subdomain?: string;
}) => NavPoint;

function isUUID(id: string) {
  const uuidPattern = "^(X{8}-X{4}-4X{3}-[89abAB]X{3}-X{12})".replace(/X/g, "[0-9A-F]");
  const match = id.match(uuidPattern) || [];
  return match && match[1];
}

function parseIdentifier(id: string) {
  if (!id) return id;
  if (isUUID(id)) return id;
  return id.split("-").pop();
}

function getQueryParams(query: QueryString.ParsedQuery<string>) {
  let version: number;
  try {
    version = parseInt(query.v as string);
    if (Number.isNaN(version)) version = undefined;
  } catch {
    version = undefined;
  }

  return {
    version,
    search: query.q as string,
    context: query.context as string,
    assetToken: query.asset_token as string,
  };
}

export const createItemNavPoint: NavPointCreator = ({ match, query, subdomain }) => {
  const { spaceId, itemUuid, portalId } = match.params;
  const { version, search, context: galleryContext, assetToken } = getQueryParams(query);

  return {
    type: NavPointTypes.Item,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    portalId: parseIdentifier(portalId) || undefined,
    subdomain,
    itemId: parseIdentifier(itemUuid),

    assetToken,
    version,
    search,
    galleryContext,
    item: null,
    gallery: null,
    galleryOptional: true,
    kit: null,
    kitOptional: true,
    kitVersion: null,
    kitVersionOptional: true,
    section: null,
    sectionOptional: true,
    portal: null,
    portalOptional: true,
    space: null,
    spaceOptional: true,
  };
};

export const createSectionNavPoint: NavPointCreator = ({ match, query, subdomain }) => {
  const { spaceId, sectionUuid, portalId } = match.params,
    { version } = getQueryParams(query);
  return {
    type: NavPointTypes.Section,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    portalId: parseIdentifier(portalId) || undefined,
    subdomain,
    sectionId: parseIdentifier(sectionUuid),
    version,
    kit: null,
    kitVersion: null,
    section: null,
    space: null,
    spaceOptional: true,
  };
};

export const createKitNavPoint: NavPointCreator = ({ match, query, subdomain }) => {
  const { spaceId, kitUuid, portalId, kitPage } = match.params,
    { version, search } = getQueryParams(query);

  const type =
    {
      versions: NavPointTypes.KitVersions,
      deleted: NavPointTypes.KitTrashedItems,
      recovered: NavPointTypes.KitRecoveredAssets,
    }[kitPage] ?? NavPointTypes.Kit;
  return {
    type,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    portalId: parseIdentifier(portalId) || undefined,
    subdomain,
    kitId: parseIdentifier(kitUuid),
    version,
    search,
    kit: null,
    kitVersion: null,
    space: null,
    spaceOptional: true,
  };
};

export const createDashboardNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId } = match.params;
  return {
    type: NavPointTypes.Dashboard,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createPortalNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId, portalId } = match.params;
  return {
    type: NavPointTypes.Portal,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    portalId: parseIdentifier(portalId),
    kit: null,
    kitVersion: null,
    space: null,
    spaceOptional: true,
  };
};

export const createUserSettingsNavPoint: NavPointCreator = ({ match }) => {
  const { settingsPage } = match.params;
  return {
    type: NavPointTypes.UserSettings,
    settingsPage,
    loaded: false,
  };
};

export const createSpaceBillingNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId } = match.params;
  return {
    type: NavPointTypes.SpaceBilling,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

// Space search is only for legacy links
export const createSpaceSearchNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId } = match.params;
  return {
    type: NavPointTypes.SpaceSearch,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createSpaceSettingsNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId, settingsPage } = match.params;
  return {
    type: NavPointTypes.SpaceSettings,
    settingsPage,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createSpaceUsersNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId, role } = match.params;
  return {
    type: NavPointTypes.SpaceUsers,
    role,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createInsightsNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId, insightsPage } = match.params;
  return {
    type: NavPointTypes.Insights,
    insightsPage,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createNewSpaceNavPoint = (): NavPoint => {
  return {
    type: NavPointTypes.NewSpace,
    loaded: false,
  };
};

export const createSpaceKitsNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId } = match.params;
  return {
    type: NavPointTypes.SpaceKits,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createKitCollectionNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId, kitCollectionId } = match.params;
  return {
    type: NavPointTypes.KitCollection,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    kitCollectionId: parseIdentifier(kitCollectionId) || undefined,
    subdomain,
    space: null,
  };
};

export function createDefaultSpaceNavPoint(subdomain = null) {
  return {
    type: NavPointTypes.DefaultSpace,
    loaded: false,
    subdomain,
  };
}

export const createAssetLibraryNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId } = match.params;
  return {
    type: NavPointTypes.Library,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createAssetTagManagementNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId } = match.params;
  return {
    type: NavPointTypes.TagManagement,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};

export const createLibraryAssetNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId, assetUuid } = match.params;
  return {
    type: NavPointTypes.LibraryAsset,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    assetId: parseIdentifier(assetUuid) || undefined,
    subdomain,
    space: null,
  };
};

export const createCustomFieldsNavPoint: NavPointCreator = ({ match, subdomain }) => {
  const { spaceId } = match.params;
  return {
    type: NavPointTypes.CustomFields,
    loaded: false,
    spaceId: parseInt(spaceId) || undefined,
    subdomain,
    space: null,
  };
};
