/**
 * Custom fields reducer
 */

import _mergeWith from "lodash/mergeWith";

import { Portal, PortalItemType } from "@thenounproject/lingo-core";

import createEntityReducer from "../helpers/createEntityReducer";
import { getEntities } from "../helpers/getEntities";
import { createPortal } from "@redux/actions/kits/useCreatePortal";
import { fetchPortals } from "@redux/actions/portals/usePortals";
import { deletePortal } from "@redux/actions/portals/useDeletePortal";
import { createPortalItems } from "@redux/actions/portalItems/useCreatePortalItems";
import { deletePortalItems } from "@redux/actions/portalItems/useDeletePortalItems";

export default createEntityReducer<Portal>(
  "portals",
  queryBuilder => {
    queryBuilder
      .addCase(createPortal.fulfilled, (state, action) => {
        const newPortal = action.payload.entities.portals[action.payload.result];
        const { spaceId } = newPortal;

        fetchPortals.getQueryData(state, { spaceId }).forEach(query => {
          query.data.push(action.payload.result);
        });
      })
      .addCase(deletePortal.fulfilled, (state, action) => {
        const deletedPortal = action.payload.entities.portals[action.payload.result];
        const { spaceId } = deletedPortal;

        fetchPortals.getQueryData(state, { spaceId }).forEach(query => {
          query.data = query.data.filter(id => id !== deletedPortal.id);
        });
      });
  },
  objectBuilder => {
    objectBuilder
      // update kit count when a new kit is created or deleted for dashboard count
      .addCase(createPortalItems.fulfilled, (state, action) => {
        const portalKitItems = Object.values(action.payload.entities.portalItems).filter(
          item => item.type === PortalItemType.kit
        );
        if (!portalKitItems.length) return;
        portalKitItems.forEach(item => {
          if (state[item.portalId]) {
            state[item.portalId].counts.kits += 1;
          }
        });
      })
      .addCase(deletePortalItems.fulfilled, (state, action) => {
        const portalKitItems = Object.values(action.payload.entities.portalItems).filter(
          item => item.type === PortalItemType.kit
        );
        if (!portalKitItems.length) return;
        portalKitItems.forEach(item => {
          if (state[item.portalId]) {
            state[item.portalId].counts.kits -= 1;
          }
        });
      })
      .addDefaultCase((state, action) => {
        const portals = getEntities(action)?.portals;
        if (portals) {
          _mergeWith(state, portals, (objValue, srcValue, key) => {
            if (key === "theme") {
              return srcValue ?? objValue;
            }
            if (key === "images") {
              return srcValue ?? objValue;
            }
          });
        }
      });
  }
);
