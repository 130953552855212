import { API, Portal } from "@thenounproject/lingo-core";
import _sortBy from "lodash/sortBy";

import { createQueryAction } from "../actionCreators";

type Args = {
  spaceId: number | string;
};

const [usePortals, fetchPortals] = createQueryAction<Args, Portal[], string[]>(
  {
    entity: "portals",
    action: "query",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
    prepareData: portals => _sortBy(portals, p => p.name.toLowerCase()),
  },
  async ({ args }) => {
    const res = await API.call<{ portals: string[] }>({
      endpoint: `spaces/${args.spaceId}/portals`,
      method: "GET",
      entity: API.Entity.portal,
    });
    return { result: res.result.portals, entities: res.entities };
  }
);

export default usePortals;
export { fetchPortals };
