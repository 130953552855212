import { Button, Flex } from "@thenounproject/lingo-core";
import React from "react";

type Props = {
  onSave: () => void;
  onCancel: () => void;
  disabled: boolean;
  isProcessing?: boolean;
};
const ThemeEditorPanelFooter: React.FC<Props> = ({ onSave, onCancel, disabled, isProcessing }) => {
  return (
    <Flex
      flexDirection="column"
      px="s"
      py="m"
      background="white"
      borderTop="default"
      position="sticky"
      bottom={0}>
      <Button
        text={isProcessing ? "Saving" : "Save"}
        size="small"
        onClick={onSave}
        mb="s"
        disabled={disabled}
      />
      <Button text="Cancel" size="small" buttonStyle="secondary" onClick={onCancel} />
    </Flex>
  );
};

export default ThemeEditorPanelFooter;
