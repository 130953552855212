/* eslint-disable react/jsx-key */
import React from "react";
import { Route } from "react-router-dom";
import { lazyWithReload } from "@helpers/lazyWithReload";

import homepageContent from "./data/homepage";
import enterpriseContent from "./data/enterprise";
import partnersContent from "./data/partners";
import product_kitsContent from "./data/product/kits";
import product_libraryContent from "./data/product/library";
import product_insightsContent from "./data/product/insights";
import product_customBrandingContent from "./data/product/customBranding";
import product_integrationsContent from "./data/product/integrations";

import solutions_brandGuidelinesContent from "./data/solutions/brandGuidelines";
import solutions_digitalAssetManagementContent from "./data/solutions/digitalAssetManagement";
import solutions_digitalBrandHubContent from "./data/solutions/digitalBrandHub";
import solutions_brandTeamsContent from "./data/solutions/brandTeams";
import solutions_agenciesContent from "./data/solutions/agencies";

import aboutContent from "./data/about";
import pricingContent from "./data/pricing";
import requestDemoContent from "./data/contact/bookDemo";
import requestQuoteContent from "./data/contact/requestQuote";
import requestTrialContent from "./data/contact/requestTrial";
import contactSalesContent from "./data/contact/contactSales";
import contactContent from "./data/contact/contact";

const PageLayout = lazyWithReload(() => import("./pages/templates/PageLayout"));
// const Pricing = lazyWithReload(() => import("./pages/Pricing"));

const AllFeatures = lazyWithReload(() => import("./pages/AllFeatures"));
const IntegrationDetail = lazyWithReload(() => import("./pages/templates/IntegrationDetail"));
const NotFound = lazyWithReload(() => import("./pages/NotFound"));
const StoreFront = lazyWithReload(() => import("./pages/StoreFront"));
const Legal = lazyWithReload(() => import("./pages/Legal"));
const Accessibility = lazyWithReload(() => import("./pages/Accessibility"));
const OpenMacApp = lazyWithReload(() => import("./pages/OpenMacApp"));
const BlogRoot = lazyWithReload(() => import("./pages/blog/BlogRoot"));
const Showcase = lazyWithReload(() => import("./pages/Showcase"));

export default function MarketingRoutes() {
  return [
    <Route exact key="101" path="/" render={() => <PageLayout {...homepageContent} />} />,
    // <Route key="102" path="/pricing" component={Pricing} />,
    <Route key="102" path="/pricing" render={() => <PageLayout {...pricingContent} />} />,
    <Route key="104" path="/about" render={() => <PageLayout {...aboutContent} />} />,

    <Route key="108" path="/enterprise" render={() => <PageLayout {...enterpriseContent} />} />,
    <Route key="109" path="/partners" render={() => <PageLayout {...partnersContent} />} />,
    <Route key="110" path="/legal" component={Legal} />,
    <Route key="111" path="/accessibility" component={Accessibility} />,

    <Route key="115" path="/showcase/:page" component={Showcase} />,

    <Route
      key="117"
      path="/security"
      render={() => window.location.replace("https://trust-report.lingoapp.com")}
    />,
    <Route key="120" path="/store" component={StoreFront} />,
    <Route key="116" path="/open" component={OpenMacApp} />,
    <Route
      key="114"
      path="/api/"
      render={() => window.location.replace("https://developer.lingoapp.com")}
    />,
    <Route
      key="115"
      path="/help/"
      render={() => window.location.replace("https://help.lingoapp.com")}
    />,

    // Contact pages
    <Route key="130" path="/contact" render={() => <PageLayout {...contactContent} />} />,
    <Route
      key="131"
      path="/contact-sales"
      render={() => <PageLayout {...contactSalesContent} />}
    />,
    <Route
      key="132"
      path="/request-quote"
      render={() => <PageLayout {...requestQuoteContent} />}
    />,
    <Route
      key="133"
      path="/request-trial"
      render={() => <PageLayout {...requestTrialContent} />}
    />,
    <Route key="134" path="/book-demo" render={() => <PageLayout {...requestDemoContent} />} />,

    // Product (200)
    <Route key="200" path="/product/kits" render={() => <PageLayout {...product_kitsContent} />} />,
    <Route
      key="201"
      path="/product/library"
      render={() => <PageLayout {...product_libraryContent} />}
    />,
    <Route
      key="202"
      path="/product/insights"
      render={() => <PageLayout {...product_insightsContent} />}
    />,
    <Route
      key="203"
      path="/product/custom-branding"
      render={() => <PageLayout {...product_customBrandingContent} />}
    />,
    <Route
      key="204"
      path="/product/integrations"
      render={() => <PageLayout {...product_integrationsContent} />}
    />,
    <Route key="205" path="/integrations/:integrationId" component={IntegrationDetail} />,
    <Route key="206" path="/product/features" component={AllFeatures} />,

    // Solutions (300)
    <Route
      key="301"
      path="/solutions/brand-guidelines"
      render={() => <PageLayout {...solutions_brandGuidelinesContent} />}
    />,
    <Route
      key="302"
      path="/solutions/digital-asset-management"
      render={() => <PageLayout {...solutions_digitalAssetManagementContent} />}
    />,
    <Route
      key="303"
      path="/solutions/digital-brand-hub"
      render={() => <PageLayout {...solutions_digitalBrandHubContent} />}
    />,
    <Route
      key="304"
      path="/solutions/brand-teams"
      render={() => <PageLayout {...solutions_brandTeamsContent} />}
    />,
    <Route
      key="305"
      path="/solutions/agencies"
      render={() => <PageLayout {...solutions_agenciesContent} />}
    />,

    // Blog (500)
    <Route key="500" exact path={"/blog*"} component={BlogRoot} />,
    <Route key="404" component={NotFound} />,
  ];
}
