/* eslint-disable react/jsx-key */
import React from "react";
import { getCustomDomainComponents } from "@thenounproject/lingo-core";
import { Route, useLocation } from "react-router-dom";
import QueryString from "query-string";

import { lazyWithReload } from "@helpers/lazyWithReload";

const AuthLayout = lazyWithReload(() => import("../components/layouts/AuthLayout"));
const AppLayout = lazyWithReload(() => import("../components/layouts/AppLayout"));

import {
  createItemNavPoint,
  createLibraryAssetNavPoint,
  createSectionNavPoint,
  createKitNavPoint,
  createUserSettingsNavPoint,
  createSpaceSettingsNavPoint,
  createNewSpaceNavPoint,
  createSpaceKitsNavPoint,
  createDefaultSpaceNavPoint,
  createInsightsNavPoint,
  createSpaceUsersNavPoint,
  createSpaceBillingNavPoint,
  createSpaceSearchNavPoint,
  createKitCollectionNavPoint,
  createAssetLibraryNavPoint,
  createAssetTagManagementNavPoint,
  createCustomFieldsNavPoint,
  createDashboardNavPoint,
  createPortalNavPoint,
} from "@redux/legacy-actions/navPoints";

export default function AppRoutes() {
  const location = useLocation(),
    query = QueryString.parse(location.search),
    { identifier: subdomain } = getCustomDomainComponents();

  function getPath(path: string): string {
    return subdomain ? path : "/:spaceId(\\d+)" + path;
  }

  return [
    <Route
      exact
      key="1"
      path="/(login|guest-login|signup|enter-password|enter-email|extend-trial|go-premium|join-space|login/sso|signup/verification-sent)/"
      component={AuthLayout}
    />,
    <Route key="1" path="/login/:token" component={AuthLayout} />,
    <Route key="1" path="/guest-login/:token" component={AuthLayout} />,
    <Route key="1" path="/password-reset/" component={AuthLayout} />,
    <Route key="1" path="/join/:space/:token/" component={AuthLayout} />,
    <Route key="1" path="/join-spaces" component={AuthLayout} />,
    <Route key="1" path="/link-account/:token/" component={AuthLayout} />,
    <Route key="1" path="/link-account/google/:token/" component={AuthLayout} />,
    <Route key="1" path="/invitation/:token/" component={AuthLayout} />,
    <Route key="1" path="/logout/" component={AuthLayout} />,
    <Route key="1" path="/error/" component={AuthLayout} />,
    <Route key="1" path="/verify/:verifyType(domain|email)/:token" component={AuthLayout} />,
    <Route key="1" path="/login/mac" component={AuthLayout} />,

    <Route
      key="2"
      path="/space/new"
      render={props => <AppLayout {...props} navPoint={createNewSpaceNavPoint()} />}
    />,
    <Route
      key="2"
      path="/space/"
      render={props => <AppLayout {...props} navPoint={createDefaultSpaceNavPoint(subdomain)} />}
    />,

    // User settings
    <Route
      key="2"
      path="/settings/:settingsPage(profile|email|password|privacy)/"
      render={props => (
        <AppLayout
          {...props}
          navPoint={createUserSettingsNavPoint({ match: props.match, query })}
        />
      )}
    />,
    // Space settings
    <Route
      key="2"
      path={getPath(
        "/settings/:settingsPage(basic-info|notifications|integrations|access-controls|advanced|editor)?/"
      )}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createSpaceSettingsNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/search/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createSpaceSearchNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/billing/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createSpaceBillingNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/members/:role(admin|members|limited-members|invitations|requests)?/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createSpaceUsersNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    // Insights
    <Route
      key="2"
      path={getPath("/insights/:insightsPage(assets|kits|users|storage)?/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createInsightsNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/k/:kitUuid/:kitPage?")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createKitNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/s/:sectionUuid")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createSectionNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/a/:itemUuid")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createItemNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/p/:portalId/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createPortalNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/k/:kitUuid/:kitPage?")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createKitNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/a/:itemUuid/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createItemNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      key="2"
      path={getPath("/s/:sectionUuid/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createSectionNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      exact
      key="2"
      path={getPath("/c/:kitCollectionId/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createKitCollectionNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      exact
      key="2"
      path={getPath("/dashboard/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createDashboardNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      exact
      key="2"
      path={getPath("/library/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createAssetLibraryNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      exact
      key="2"
      path={getPath("/library/tags/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createAssetTagManagementNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      exact
      key="2"
      path={getPath("/library/fields/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createCustomFieldsNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      exact
      key="2"
      path={getPath("/library/a/:assetUuid")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createLibraryAssetNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
    <Route
      exact
      key="2"
      path={getPath("/")}
      render={props => (
        <AppLayout
          {...props}
          navPoint={createSpaceKitsNavPoint({ match: props.match, query, subdomain })}
        />
      )}
    />,
  ];
}
