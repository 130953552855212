"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultSubdomains = exports.defaultDomains = void 0;
exports.defaultDomains = ["lingoapp.com"];
exports.defaultSubdomains = [
    "www",
    "www-test",
    "www.test",
    "www-staging",
    "www.staging",
    "local",
    "",
];
function parseHostname(hostname) {
    var components = hostname
        .replace(/https?:\/\//, "")
        .split("/")[0]
        .split(":")[0]
        .split("."), _subdomain = components.slice(0, -2).join("."), _host = components.slice(-2).join(".");
    var defaultDomains = ["lingoapp.com"], defaultSubdomains = ["www", "www-test", "www.test", "www-staging", "www.staging", "local", ""];
    var subdomain = defaultSubdomains.includes(_subdomain) ? null : _subdomain, host = defaultDomains.includes(_host) ? null : _host, identifier = [subdomain, host].filter(Boolean).join(".");
    return {
        subdomain: subdomain,
        host: host,
        identifier: identifier.length ? identifier : null,
    };
}
exports.default = parseHostname;
