import home_hero_3_2 from "./Home/hero_3_2.png";
import home_defineBrand_3_2 from "./Home/defineBrand_3_2.png";
import home_empowerTeams_3_2 from "./Home/empowerTeams_3_2.png";
import home_upToDate_3_2 from "./Home/upToDate_3_2.png";
import showcase_3_2 from "./Home/showcase_3_2.png";
import product_kits_hero_21_9 from "./Product/kits_hero_21_9.png";
import product_kits_contentContext_3_2 from "./Product/kits_contentContext_3_2.png";
import product_kits_customKits_3_2 from "./Product/kits_customKits_3_2.png";
import product_library_hero_21_9 from "./Product/library_hero_21_9.png";
import product_library_assetManagement_3_2 from "./Product/library_assetManagement_3_2.png";
import product_library_dam_3_2 from "./Product/library_dam_3_2.png";
import product_insights_hero_21_9 from "./Product/insights_hero_21_9.png";
import product_insights_optimize_3_2 from "./Product/insights_optimize_3_2.png";
import product_insights_content_3_2 from "./Product/insights_content_3_2.png";
import product_customBranding_hero_21_9 from "./Product/customBranding_hero_21_9.png";
import product_customBranding_customize_3_2 from "./Product/customBranding_customize_3_2.png";
import product_customBranding_showcase_3_2 from "./Product/customBranding_showcase_3_2.png";
import integrations_hero_21_9 from "./Integrations/hero_21_9.png";
import integrations_mac_3_2 from "./Integrations/mac_3_2.png";
import integrations_web_3_2 from "./Integrations/web_3_2.png";
import integrations_figma_3_2 from "./Integrations/figma_3_2.png";
import integrations_figma_content_3_2 from "./Integrations/figma_content_3_2.png";
import integrations_sketch_3_2 from "./Integrations/sketch_3_2.png";
import integrations_sketch_content_3_2 from "./Integrations/sketch_content_3_2.png";
import integrations_dropbox_3_2 from "./Integrations/dropbox_3_2.png";
import integrations_dropbox_content_3_2 from "./Integrations/dropbox_content_3_2.png";
import integrations_google_3_2 from "./Integrations/google_3_2.png";
import integrations_google_content_3_2 from "./Integrations/google_content_3_2.png";
import integrations_api_3_2 from "./Integrations/api_3_2.png";
import integrations_API_content_3_2 from "./Integrations/API_content_3_2.png";
import solutions_brandGuidelines_hero_3_2 from "./Solutions/brandGuidelines_hero_3_2.png";
import solutions_brandGuidelines_contentContext_3_2 from "./Solutions/brandGuidelines_contentContext_3_2.png";
import solutions_brandGuidelines_customBranding_3_2 from "./Solutions/brandGuidelines_customBranding_3_2.png";
import solutions_brandGuidelines_publicSharing_3_2 from "./Solutions/brandGuidelines_publicSharing_3_2.png";
import solutions_brandGuidelines_versionHistory_3_2 from "./Solutions/brandGuidelines_versionHistory_3_2.png";
import solutions_DAM_hero_3_2 from "./Solutions/DAM_hero_3_2.png";
import solutions_DAM_affordable_3_2 from "./Solutions/DAM_affordable_3_2.png";
import solutions_DAM_customMetadata_3_2 from "./Solutions/DAM_customMetadata_3_2.png";
import solutions_DAM_selfServe_3_2 from "./Solutions/DAM_selfServe_3_2.png";
import solutions_DAM_visuallyBrowse_3_2 from "./Solutions/DAM_visuallyBrowse_3_2.png";
import solutions_brandHub_hero_3_2 from "./Solutions/brandHub_hero_3_2.png";
import solutions_brandHub_allAssets_3_2 from "./Solutions/brandHub_allAssets_3_2.png";
import solutions_brandHub_contentContext_3_2 from "./Solutions/brandHub_contentContext_3_2.png";
import solutions_brandHub_customBranding_3_2 from "./Solutions/brandHub_customBranding_3_2.png";
import solutions_brandHub_selfServe_3_2 from "./Solutions/brandHub_selfServe_3_2.png";
import solutions_brandTeam_hero_3_2 from "./Solutions/brandTeam_hero_3_2.png";
import solutions_brandTeam_figmaIntegration_3_2 from "./Solutions/brandTeam_figmaIntegration_3_2.png";
import solutions_brandTeam_easyToBuild_3_2 from "./Solutions/brandTeam_easyToBuild_3_2.png";
import solutions_brandTeam_fileConversion_3_2 from "./Solutions/brandTeam_fileConversion_3_2.png";
import solutions_brandTeam_publicSharing_3_2 from "./Solutions/brandTeam_publicSharing_3_2.png";
import solutions_agencies_hero_3_2 from "./Solutions/agencies_hero_3_2.png";
import solutions_agencies_affiliateProgram_3_2 from "./Solutions/agencies_affiliateProgram_3_2.png";
import solutions_agencies_allClients_3_2 from "./Solutions/agencies_allClients_3_2.png";
import solutions_agencies_fileConversion_3_2 from "./Solutions/agencies_fileConversion_3_2.png";
import solutions_agencies_upToDate_3_2 from "./Solutions/agencies_upToDate_3_2.png";
import enterprise_hero_3_2 from "./Enterprise/hero_3_2.png";
import enterprise_consistency_3_2 from "./Enterprise/consistency_3_2.png";
import enterprise_security_3_2 from "./Enterprise/security_3_2.png";
import enterprise_support_3_2 from "./Enterprise/support_3_2.png";
import features_brandPortal_16_9 from "./Features/brandPortal_16_9.png";
import features_customBranding_16_9 from "./Features/customBranding_16_9.png";
import features_customDomains_16_9 from "./Features/customDomains_16_9.png";
import features_customFields_16_9 from "./Features/customFields_16_9.png";
import features_enhancedSearch_16_9 from "./Features/enhancedSearch_16_9.png";
import features_fileConversions_16_9 from "./Features/fileConversions_16_9.png";
import features_guidelines_16_9 from "./Features/guidelines_16_9.png";
import features_insights_16_9 from "./Features/insights_16_9.png";
import features_integrations_16_9 from "./Features/integrations_16_9.png";
import features_tags_16_9 from "./Features/tags_16_9.png";
import features_unlimitedKits_16_9 from "./Features/unlimitedKits_16_9.png";
import features_versionHistory_16_9 from "./Features/versionHistory_16_9.png";
import features_publicSharing_16_9 from "./Features/publicSharing_16_9.png";
import features_downloadRequests_16_9 from "./Features/downloadRequests_16_9.png";
import bumble from "./CustomerLogos/bumble.svg";
import denison from "./CustomerLogos/denison.svg";
import duolingo from "./CustomerLogos/duolingo.svg";
import intuit from "./CustomerLogos/intuit.svg";
import lime from "./CustomerLogos/lime.svg";
import matterport from "./CustomerLogos/matterport.svg";
import reddit from "./CustomerLogos/reddit.svg";
import snap from "./CustomerLogos/snap.svg";
import texasam from "./CustomerLogos/texasam.svg";
import tntsports from "./CustomerLogos/tntsports.svg";
import ctaBackground from "./Misc/ctaBackground.png";
import affiliate_hero_3_2 from "./Affiliate/hero_3_2.png";
import affiliate_content_3_2 from "./Affiliate/content_3_2.png";
import pricing_hero_3_2 from "./Pricing/hero_3_2.png";
import pricing_roi_3_2 from "./Pricing/roi_3_2.png";

const Home = {
  hero: home_hero_3_2,
  defineBrand: home_defineBrand_3_2,
  empowerTeams: home_empowerTeams_3_2,
  upToDate: home_upToDate_3_2,
  showcase: showcase_3_2,
};

const Product = {
  kits_hero: product_kits_hero_21_9,
  kits_contentContext: product_kits_contentContext_3_2,
  kits_customKits: product_kits_customKits_3_2,
  library_hero: product_library_hero_21_9,
  library_assetManagement: product_library_assetManagement_3_2,
  library_dam: product_library_dam_3_2,
  insights_hero: product_insights_hero_21_9,
  insights_optimize: product_insights_optimize_3_2,
  insights_content: product_insights_content_3_2,
  customBranding_hero: product_customBranding_hero_21_9,
  customBranding_customize: product_customBranding_customize_3_2,
  customBranding_showcase: product_customBranding_showcase_3_2,
};

const Integrations = {
  hero: integrations_hero_21_9,
  mac: integrations_mac_3_2,
  web: integrations_web_3_2,
  figma: integrations_figma_3_2,
  figma_content: integrations_figma_content_3_2,
  sketch: integrations_sketch_3_2,
  sketch_content: integrations_sketch_content_3_2,
  dropbox: integrations_dropbox_3_2,
  dropbox_content: integrations_dropbox_content_3_2,
  google: integrations_google_3_2,
  google_content: integrations_google_content_3_2,
  api: integrations_api_3_2,
  API_content: integrations_API_content_3_2,
};

const Solutions = {
  brandGuidelines_hero: solutions_brandGuidelines_hero_3_2,
  brandGuidelines_contentContext: solutions_brandGuidelines_contentContext_3_2,
  brandGuidelines_customBranding: solutions_brandGuidelines_customBranding_3_2,
  brandGuidelines_publicSharing: solutions_brandGuidelines_publicSharing_3_2,
  brandGuidelines_versionHistory: solutions_brandGuidelines_versionHistory_3_2,
  DAM_hero: solutions_DAM_hero_3_2,
  DAM_affordable: solutions_DAM_affordable_3_2,
  DAM_customMetadata: solutions_DAM_customMetadata_3_2,
  DAM_selfServe: solutions_DAM_selfServe_3_2,
  DAM_visuallyBrowse: solutions_DAM_visuallyBrowse_3_2,
  brandHub_hero: solutions_brandHub_hero_3_2,
  brandHub_allAssets: solutions_brandHub_allAssets_3_2,
  brandHub_contentContext: solutions_brandHub_contentContext_3_2,
  brandHub_customBranding: solutions_brandHub_customBranding_3_2,
  brandHub_selfServe: solutions_brandHub_selfServe_3_2,
  brandTeam_hero: solutions_brandTeam_hero_3_2,
  brandTeam_figmaIntegration: solutions_brandTeam_figmaIntegration_3_2,
  brandTeam_easyToBuild: solutions_brandTeam_easyToBuild_3_2,
  brandTeam_fileConversion: solutions_brandTeam_fileConversion_3_2,
  brandTeam_publicSharing: solutions_brandTeam_publicSharing_3_2,
  agencies_hero: solutions_agencies_hero_3_2,
  agencies_affiliateProgram: solutions_agencies_affiliateProgram_3_2,
  agencies_allClients: solutions_agencies_allClients_3_2,
  agencies_fileConversion: solutions_agencies_fileConversion_3_2,
  agencies_upToDate: solutions_agencies_upToDate_3_2,
};

const Enterprise = {
  hero: enterprise_hero_3_2,
  consistency: enterprise_consistency_3_2,
  security: enterprise_security_3_2,
  support: enterprise_support_3_2,
};

const Features = {
  brandPortal: features_brandPortal_16_9,
  customBranding: features_customBranding_16_9,
  customDomains: features_customDomains_16_9,
  customFields: features_customFields_16_9,
  enhancedSearch: features_enhancedSearch_16_9,
  fileConversions: features_fileConversions_16_9,
  guidelines: features_guidelines_16_9,
  insights: features_insights_16_9,
  integrations: features_integrations_16_9,
  tags: features_tags_16_9,
  unlimitedKits: features_unlimitedKits_16_9,
  versionHistory: features_versionHistory_16_9,
  publicSharing: features_publicSharing_16_9,
  downloadRequests: features_downloadRequests_16_9,
};

const CustomerLogos = {
  bumble,
  denison,
  duolingo,
  intuit,
  lime,
  matterport,
  reddit,
  snap,
  texasam,
  tntsports,
};

const Misc = {
  ctaBackground,
};

const Affiliate = {
  hero: affiliate_hero_3_2,
  content: affiliate_content_3_2,
};

const Pricing = {
  hero: pricing_hero_3_2,
  roi: pricing_roi_3_2,
};

const Images = {
  Home,
  Product,
  Integrations,
  Solutions,
  Enterprise,
  Features,
  CustomerLogos,
  Misc,
  Affiliate,
  Pricing,
};

export default Images;
