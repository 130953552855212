/**
 * Starts a download of multiple assets in a zip file by clicking an element (typically a button).
 *
 * This component uses the "render prop" approach. The button used to open the popup should be
 * rendered within a function passed in as props.children (aka render prop). The actual element that
 * the user clicks needs to use buttonProps.
 *
 * <MultiAssetDownload items={...}>
 *   {({ buttonProps }) => {
 *     return <Button {...buttonProps} text="Download" />
 *   }}
 * </MultiAssetDownload>;
 *
 * Also provided to the render function is numItems, which gives how many items are set to be
 * downloaded (and excludes non-downloadable items, such as colors)
 *
 * <MultiAssetDownload items={...}>
 *   {({ buttonProps, numItems }) => {
 *     return (
 *        <Button
 *          {...buttonProps}
 *          text={`Download ${numItems} Files`}
 *        />
 *      );
 *   }}
 * </MultiAssetDownload>;
 */

import React, { useCallback, useMemo } from "react";
import { AssetType, ErrorCode } from "@thenounproject/lingo-core";

import { Inspectable } from "@constants/Inspector";
import useDownloadMultipleAssets from "@actions/useDownloadMultipleAssets";
import useNotifications from "@actions/useNotifications";
import useShowModal, { ModalTypes } from "@redux/actions/useModals";
import { useSelectSpace } from "@selectors/entities/spaces";

type RenderProps = {
  buttonProps: {
    onClick: (e: any) => void;
  };
  numItems: number;
};

type Props = {
  inspectables: Inspectable[];
  children: (renderProps: RenderProps) => React.ReactNode;
};

export default function MultiAssetDownload({ inspectables, children }: Props) {
  const [downloadMultipleAssets] = useDownloadMultipleAssets();
  const { showNotification } = useNotifications();
  const { showModal, dismissModal } = useShowModal();
  const space = useSelectSpace();

  const filteredAssetInspectables = useMemo(
    () =>
      inspectables.filter(i => Boolean(i?.asset?.permalink && i?.asset?.type !== AssetType.URL)),
    [inspectables]
  );
  const downloadAssets = useCallback(
    async (e?: any) => {
      if (e) {
        const event = e as Event;
        event.preventDefault();
        event.stopPropagation();
      }
      showNotification({
        message: `Preparing to download ${filteredAssetInspectables.length} assets.`,
      });

      const action = await downloadMultipleAssets({
        inspectables: filteredAssetInspectables,
        isAdmin: ["owner", "admin"].includes(space?.access?.role),
      });

      if (action.error) {
        const { message } = action.error || {};
        if (action.error.code === ErrorCode.downloadRequiresIdentity) {
          return showModal(ModalTypes.INFO_PROMPT, {
            callback: () => downloadAssets(),
          });
        }
        if (action.error.code === ErrorCode.downloadRequestPending) {
          return showModal(ModalTypes.CONFIRMATION, {
            title: "Request Pending",
            message,
            buttonText: "Continue",
            buttonProcessingText: "Continue",
            onConfirm: dismissModal,
          });
        }
        if (action.error.code === ErrorCode.downloadRequiresVerifiedIdentity) {
          return showModal(ModalTypes.CONFIRMATION, {
            title: "Request Approved",
            message,
            buttonText: "Continue",
            buttonProcessingText: "Continue",
            onConfirm: dismissModal,
          });
        }
        if (action.error.code === ErrorCode.downloadRequestDenied) {
          return showModal(ModalTypes.CONFIRMATION, {
            title: "Request Denied",
            message,
            buttonText: "Continue",
            buttonProcessingText: "Continue",
            onConfirm: dismissModal,
          });
        }
        showNotification({
          level: "error",
          message:
            message ||
            "Unfortunately, there was a problem starting your download.  Please try again later.",
        });
      }
    },
    [
      downloadMultipleAssets,
      filteredAssetInspectables,
      showModal,
      dismissModal,
      showNotification,
      space?.access?.role,
    ]
  );

  return (
    <>
      {children({
        buttonProps: { onClick: downloadAssets },
        numItems: filteredAssetInspectables.length,
      })}
    </>
  );
}
