import React, { useMemo } from "react";
import styled from "styled-components";
import QueryString from "query-string";
import {
  Flex,
  Text,
  Box,
  SpaceTheme,
  SpaceThemeNames,
  useSpaceThemeName,
  Button,
} from "@thenounproject/lingo-core";

import SearchBarButton from "../../../components/search/SearchBarButton";
import CreateButton from "./CreateButton";

import { SUPER_NAV_WIDTH } from "../../../constants/layouts";
import { useSelectSpace } from "@selectors/entities/spaces";
import { useBannerContext } from "@contexts/BannerProvider";
import { sharedThemeVals } from "../pages/SpaceKits";
import useCurrentUser from "@queries/useCurrentUser";

const MOBILE_HEADER_HEIGHT = 70;

type TitleInView = {
  spaceTitleInView: boolean;
};

const fixedWrapperThemeVals = {
  [SpaceThemeNames.classic]: {
    px: "m",
  },
  [SpaceThemeNames.scher]: { px: "m" },
  [SpaceThemeNames.wyman]: { px: "xxl" },
};
const FixedWrapper = styled(Flex).attrs(
  (props: { top: number; theme?: SpaceTheme; fullWidth: boolean } & TitleInView) => {
    return {
      width: props.fullWidth ? "100%" : `calc(100% - ${SUPER_NAV_WIDTH}px)`,
      borderRadius: "4px 0 0 0",
      justifyContent: "center",
      position: "fixed",
      top: `${props.top}px`,
      right: 0,
      background: props.spaceTitleInView ? "transparent" : "toolbarBackground",
      px: fixedWrapperThemeVals[props?.theme?.themeName].px,
      py: "m",
      style: {
        zIndex: 10,
        transition: "0.3s ease all",
      },
      variations: {
        "mq.s": {
          width: "100%",
          top: `${MOBILE_HEADER_HEIGHT + props.top}px`,
        },
      },
    };
  }
)``;

const Inner = styled(Flex).attrs<TitleInView>(props => {
  const sharedVals = sharedThemeVals[props.theme.themeName || "classic"];

  let maxWidth = sharedVals.contentContainer.maxWidth;
  if (props.theme.themeName !== SpaceThemeNames.wyman) {
    maxWidth = props.spaceTitleInView ? "100%" : sharedVals.contentContainer.maxWidth;
  }
  return {
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    maxWidth,
    style: {
      transition: "0.3s ease all",
    },
  };
})<TitleInView>``;

const TitleBox = styled(Box).attrs<TitleInView>(props => {
  return { style: { opacity: props.spaceTitleInView ? 0 : 1, transition: "0.3s ease all" } };
})<TitleInView>``;

type Props = {
  spaceTitleInView: boolean;
};

const SpaceHeaderFixedNav = ({ spaceTitleInView = true }: Props) => {
  const themeName = useSpaceThemeName();
  const space = useSelectSpace();
  const currentUser = useCurrentUser();
  const { activeSpaceAlert } = useBannerContext();
  const perms = space?.access?.permissions || [],
    canCreateKit = perms.includes("create_kit"),
    canCreateCollection = perms.includes("kit_collections"),
    canSearch = perms.includes("search_assets");

  const currentUrl = window.location.toString();
  const loginUrl = useMemo(() => {
    const qs = QueryString.stringify({ next: currentUrl });
    return `/login?${qs}`;
  }, [currentUrl]);

  const isLoggedIn = Boolean(currentUser?.user?.id);
  if (isLoggedIn && !(canSearch || canCreateKit || canCreateCollection)) return null;

  const top = activeSpaceAlert ? 60 : 0;
  const fullWidth = !isLoggedIn;
  return (
    <FixedWrapper top={top} spaceTitleInView={spaceTitleInView} fullWidth={fullWidth}>
      <Inner spaceTitleInView={spaceTitleInView}>
        <TitleBox spaceTitleInView={spaceTitleInView}>
          <Text font="ui.title">{space.name}</Text>
        </TitleBox>
        <Flex alignItems="center">
          {(canSearch && !spaceTitleInView) || themeName === SpaceThemeNames.wyman ? (
            <SearchBarButton
              mr="s"
              size={themeName === SpaceThemeNames.wyman ? "small" : "regular"}
            />
          ) : null}
          {!isLoggedIn && <Button buttonStyle="primary" text="Login" link={loginUrl} />}
          {(canCreateKit || canCreateCollection) && <CreateButton />}
        </Flex>
      </Inner>
    </FixedWrapper>
  );
};

export default SpaceHeaderFixedNav;
