import { API, Space } from "@thenounproject/lingo-core";

import { createQueryAction } from "@actions/actionCreators";

type Args = {
  spaceId: number | string;
};

const [useSpace, fetchSpace] = createQueryAction<Args, Space, number>(
  {
    entity: "spaces",
    action: "fetch",
    condition: ({ args: { spaceId } }) => Boolean(spaceId),
  },
  async ({ args }) => {
    return await API.call<number>({
      endpoint: `spaces/${args.spaceId}`,
      method: "GET",
      entity: API.Entity.space,
    });
  }
);

export default useSpace;
export { fetchSpace };
