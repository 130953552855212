import { API, base64, SpaceMember } from "@thenounproject/lingo-core";
import { createQueryAction } from "../actionCreators";
import { DEFAULT_PAGE_SIZE } from "../actionCreators/createQueryAction";

type Args = {
  spaceId: number;
  role: string;
  sort: string;
  // Fetching members that do NOT have access to a portal
  portalId?: string;
  name?: string;
};

type NormalizedResult = {
  total: number;
  members: string[];
};

const [useSpaceMembers, fetchSpaceMembers] = createQueryAction<
  Args,
  { total: number; members: SpaceMember[] },
  NormalizedResult
>(
  {
    entity: "spaceMembers",
    action: "query",
    pagingKey: "members",
    cacheKey: args => {
      return { ...args, name: undefined, filtered: Boolean(args.name) };
    },
    denormalize: {
      entity: null,
      members: {
        entity: "spaceMembers",
        user: "users",
        kitAccess: "kitMembers",
      },
    },
  },
  async ({ args, paging }) => {
    const query = base64.encode({
      role: args.role,
      sort: args.sort,
      name: args.name,
      portal_uuid: args.portalId,
      limit: DEFAULT_PAGE_SIZE,
      offset: DEFAULT_PAGE_SIZE * (paging.page - 1),
      include: ["kits"],
    });
    const res = await API.call<NormalizedResult>({
      endpoint: `spaces/${args.spaceId}/members`,
      method: "GET",
      entity: API.Entity.spaceMember,
      query: { query },
    });
    const { members, total } = res.result;
    return { result: { members, total }, entities: res.entities };
  }
);

export default useSpaceMembers;
export { fetchSpaceMembers };
