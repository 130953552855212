import React from "react";
import { ChromePicker, ColorResult } from "react-color";
import {
  PopupMenu,
  Flex,
  Text,
  Button,
  Tooltip,
  Box,
  buildPath,
  styles,
  SpacePermission,
  ItemType,
} from "@thenounproject/lingo-core";
import styled, { useTheme } from "styled-components";

import { useSelectSpace } from "@selectors/entities/spaces";
import useUpsell, { AccessStatus } from "@hooks/useUpsell";
import UpsellTooltip from "../../../components/spaces/settings/UpsellTooltip";

const StyledColorPicker = styled(ChromePicker)`
  width: 100% !important;
`;

type Props = {
  editItemBackground: (background: string | null, itemType?: ItemType) => void;
  selectedColor: string;
};

export const NoteBackgroundColorOptions: React.FC<Props> = ({
  editItemBackground,
  selectedColor,
}) => {
  const space = useSelectSpace();
  const theme = useTheme();
  const titleMap = {
    backgroundColor: "Background color",
    none: "None",
    info: "Info",
    warning: "Warning",
    success: "Success",
    custom: "Custom",
  };
  const { openUpgradeModal: openThemeUpsell, accessStatus: customThemeAccessStatus } = useUpsell(
    SpacePermission.manageCustomTheme
  );
  const mustUpgradeCustomTheme = customThemeAccessStatus === AccessStatus.insufficientPlan;

  return (
    <PopupMenu.Item key={titleMap.backgroundColor} title={titleMap.backgroundColor}>
      <PopupMenu.Item
        key={titleMap.none}
        title={titleMap.none}
        onClick={() => editItemBackground(null, ItemType.note)}
        image={{ color: "transparent" }}
      />
      <PopupMenu.Item
        key={titleMap.info}
        title={titleMap.info}
        onClick={() => editItemBackground("noteInfoColor", ItemType.note)}
        image={{
          color: theme?.active && theme.noteInfoColor ? theme.noteInfoColor : styles.color.noteInfo,
        }}
      />
      <PopupMenu.Item
        key={titleMap.warning}
        title={titleMap.warning}
        onClick={() => editItemBackground("noteWarningColor", ItemType.note)}
        image={{
          color:
            theme?.active && theme.noteWarningColor
              ? theme.noteWarningColor
              : styles.color.noteWarning,
        }}
      />
      <PopupMenu.Item
        key={titleMap.success}
        title={titleMap.success}
        onClick={() => editItemBackground("noteSuccessColor", ItemType.note)}
        image={{
          color:
            theme?.active && theme.noteSuccessColor
              ? theme.noteSuccessColor
              : styles.color.noteSuccess,
        }}
      />
      <PopupMenu.Item
        key={titleMap.custom}
        title={titleMap.custom}
        image={{ iconId: "content.custom-colors", fill: "", size: "16" }}>
        <Box
          onClick={e => {
            e.stopPropagation();
          }}>
          <StyledColorPicker
            color={selectedColor}
            onChangeComplete={(color: ColorResult) => editItemBackground(color.hex, ItemType.note)}
            disableAlpha
          />
        </Box>
      </PopupMenu.Item>
      <PopupMenu.Section data-tooltip-source="theme-editor-button">
        <Flex background="grayLightest" p="8px" alignItems="center" flexDirection="column">
          <Text font="ui.small" textAlign="center">
            Configure preset colors in the
          </Text>
          <Button
            text="theme editor"
            buttonStyle="tertiary"
            size="small"
            disabled={mustUpgradeCustomTheme}
            link={buildPath("/settings/editor?view=gallery", { space })}
          />
        </Flex>
        {mustUpgradeCustomTheme && (
          <UpsellTooltip
            onClick={openThemeUpsell}
            source="theme-editor-button"
            featureName="Custom themes"
            direction={Tooltip.Direction.Top}
          />
        )}
      </PopupMenu.Section>
    </PopupMenu.Item>
  );
};
