import React, { MutableRefObject, useLayoutEffect, useMemo } from "react";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import {
  Repo,
  buildPath,
  ActivityIndicator,
  Box,
  Kit,
  KitVersion,
  Section,
  Item,
  Text,
  PagingIndicator,
} from "@thenounproject/lingo-core";
import Helmet from "react-helmet";

import GalleryItems from "./GalleryItems";
import { scrollToItem } from "@helpers/items";

import type { RootState } from "@redux/store";
import useSetDraggingEntity, { DragEntities } from "@actions/useSetDraggingEntity";
import { useGetDraggingState } from "@selectors/getters";
import SectionFooterNav from "@features/navigation/SectionFooterNav";
import { useAppSelectorV1 } from "@redux/hooks";
import { useViewModeContext } from "@contexts/ViewModeProvider";
import useBatchUpdateItemStatus from "@actions/items/useBatchUpdateItemStatus";
import useSectionItems from "@redux/actions/items/useSectionItems";
import EmptyState from "../EmptyState";
import useUpdateItem from "@redux/actions/items/useUpdateItem";

const FetchingIndicatorWrapper = styled(Box).attrs({
  background: "rgba(255, 255, 255, 0.8)",
  position: "fixed",
  top: 61,
  bottom: 0,
  right: 0,
  height: "100%",
  zIndex: 1,
})``;

//
export const SectionTitle = styled(Text).attrs({
  as: "h1",
  font: "gallery.title",
  mb: "xl",
})`
  pointer-events: none;
`;

type Props = {
  section: Section;
  kit: Kit;
  kitVersion: KitVersion;
  getItemUrl: (item: Item) => string;
  canEditContent: boolean;
  onSelectItem: (event: React.MouseEvent, itemId: string, allItems: Item[]) => void;
  setInspectorState: (itemIds: string[]) => void;
  selectedItems: string[];
  scrollContainerRef: MutableRefObject<HTMLDivElement>;
};

const SectionComponent: React.FC<Props> = ({
  section,
  kit,
  kitVersion,
  getItemUrl,
  canEditContent,
  onSelectItem,
  setInspectorState,
  selectedItems,
  scrollContainerRef,
}) => {
  const history = useHistory();
  const location = useLocation();

  const setDraggingEntity = useSetDraggingEntity();
  const galleryDraggingActive = useGetDraggingState().entity === DragEntities.GALLERY_ITEM;

  const { galleryAssetViewMode } = useViewModeContext();
  const [batchUpdateItemStatus] = useBatchUpdateItemStatus();

  const [updateItem] = useUpdateItem();

  const {
    data: { items, total } = {},
    isLoading,
    error: loadingError,
    fetchNextPage,
  } = useSectionItems({
    sectionId: section.id,
    version: section.version,
  });

  const hasMore = items?.length >= total ? false : true;

  // TODO: Possibly refactor this
  // This was a quick fix to replace mapStateToProps with useSelector
  const { space, nextSection, prevSection, totalSections, currentSectionIndex } = useAppSelectorV1(
    (state: RootState) => {
      const repo = new Repo(state);

      const versionId = `${section.id}-${section.version}`,
        space = repo.getSpace(kit.spaceId);

      let nextSection = null;
      let prevSection = null;
      let currentSectionIndex = 0;
      const totalSections = kitVersion?.sections?.length || 0;

      if (kitVersion && kitVersion.sections && kitVersion.sections.length > 1) {
        currentSectionIndex = kitVersion.sections.indexOf(versionId);
        const nextUuid = kitVersion.sections[currentSectionIndex + 1];
        const prevUuid = kitVersion.sections[currentSectionIndex - 1];

        if (nextUuid) {
          const { shortId: nextShortId, name: nextName } = repo.getSection(
            nextUuid,
            kitVersion.version
          );
          const queryParams = {
            kit_token: kit.shareToken || undefined,
            v: kitVersion.version,
          };
          nextSection = {
            path: buildPath(`/s/${nextShortId}/`, { space }, queryParams),
            name: nextName,
          };
        }
        if (prevUuid) {
          const { shortId: prevShortId, name: prevName } = repo.getSection(
            prevUuid,
            kitVersion.version
          );
          const queryParams = {
            kit_token: kit.shareToken || undefined,
            v: kitVersion.version,
          };
          prevSection = {
            path: buildPath(`/s/${prevShortId}/`, { space }, queryParams),
            name: prevName,
          };
        }
      }

      return {
        space: space,
        nextSection,
        prevSection,
        totalSections,
        currentSectionIndex,
      };
    }
  );

  // Scrolling to an item if the URL has a hash
  const headingHash = useMemo(() => {
    // Don't scroll if configuring theme
    if (!location.hash || location.hash === "#configure") return;
    // Ignore hashes if the url is not the current page. This can cause issues if we
    // link to another page with a hash since this page may "consume" the hash while
    // the nav point is loading.
    const onPage =
      location.pathname.includes(section.shortId) || location.pathname.includes(section.id);
    return onPage ? location.hash?.replace("#", "") : null;
  }, [location.hash, location.pathname, section.id, section.shortId]);

  useLayoutEffect(() => {
    if (!headingHash) return;
    // If we found the hash or there are no more items
    // remove it from the URL so we don't scroll again
    if (scrollToItem(headingHash) || !hasMore) {
      history.replace(`${location.pathname}${location.search}`);
    } else if (!isLoading) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasMore, headingHash, history, isLoading, location.pathname, location.search]);

  function renderFetchingIndicator() {
    if (headingHash) {
      const container = scrollContainerRef.current;
      if (!container) return;

      return (
        <FetchingIndicatorWrapper width={"calc(100% - 320px)"} height="100%">
          <ActivityIndicator size="large" center />
        </FetchingIndicatorWrapper>
      );
    }
  }

  const renderTitle = section && kitVersion,
    themeActive = space && space.theme.active,
    docTitle = renderTitle ? `${kit.name}: ${section.name}${themeActive ? "" : " on Lingo"}` : null;

  function renderSectionTitle() {
    if (!section) return null;
    return <SectionTitle>{section.name || "Untitled section"}</SectionTitle>;
  }

  function renderContent() {
    if (isLoading && !items?.length) {
      return <ActivityIndicator mt="xxl" center />;
    }
    if (loadingError && !items?.length) {
      return (
        <EmptyState
          title="Unable to load section"
          subtitle={loadingError.message}
          iconProps={{ iconId: "error", fill: "error" }}
          button={{ text: "Retry", onClick: fetchNextPage }}
        />
      );
    } else if (section.status === "pending") {
      return (
        <EmptyState
          title="This section is being duplicated"
          subtitle="The assets in this section are still processing, check back in a few minutes."
          iconProps={{ iconId: "content.section", size: "90", fill: "gray" }}
        />
      );
    }
    return (
      <>
        <GalleryItems
          section={section}
          items={items}
          getItemUrl={getItemUrl}
          onSelectItem={onSelectItem}
          setInspectorState={setInspectorState}
          selectedItems={selectedItems}
          scrollContainerRef={scrollContainerRef}
          updateItem={updateItem}
          batchUpdateItemStatus={batchUpdateItemStatus}
          canEditContent={section.status === "active" && canEditContent}
          assetViewMode={galleryAssetViewMode}
          setDraggingEntity={setDraggingEntity}
          galleryDraggingActive={galleryDraggingActive}
        />

        <PagingIndicator
          hasMore={hasMore}
          data={items}
          isLoading={isLoading}
          error={loadingError}
          fetchNextPage={fetchNextPage}
        />
      </>
    );
  }

  return (
    <>
      {docTitle ? <Helmet title={docTitle} /> : null}
      {renderSectionTitle()}
      {renderFetchingIndicator()}
      {renderContent()}
      <SectionFooterNav {...{ nextSection, prevSection, totalSections, currentSectionIndex }} />
    </>
  );
};

export default SectionComponent;
