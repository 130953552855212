import React from "react";
import { TinyColor } from "@ctrl/tinycolor";
import styled, { useTheme } from "styled-components";
import _merge from "lodash/merge";
import {
  Flex,
  Text,
  ImageView,
  utils,
  SpaceTheme,
  SpaceThemeNames,
  AnyObject,
  Box,
  MarkdownText,
  Portal,
  ThemeHeaderTitleOptions,
} from "@thenounproject/lingo-core";

import SearchBarButton from "../search/SearchBarButton";

import MobileSubNav from "../navigation/MobileSubNav";
import { TabletDown, TabletUp } from "@features/media-queries";
import SpaceHeaderLogo from "@features/space-kits/components/SpaceHeaderLogo";
import { useInView } from "react-intersection-observer";

// MARK : Wrapper
// -------------------------------------------------------------------------------
const Wrapper = styled(Box).attrs<AnyObject>(props => {
  const overrides = {
    [SpaceThemeNames.classic]: {},
    [SpaceThemeNames.scher]: {},
    [SpaceThemeNames.wyman]: {
      px: "xl",
    },
  };
  return overrides[props.theme.themeName] ?? {};
})``;

// MARK : Container
// -------------------------------------------------------------------------------
const containerOverrideAttrs = {
  [SpaceThemeNames.classic]: {
    alignItems: "center",
    aspectRatio: "3/1",
    justifyContent: "center",
    maxHeight: "728px",
  },
  [SpaceThemeNames.scher]: {
    alignItems: "flex-end",
    justifyContent: "flex-start",
    aspectRatio: "16/9",
    maxHeight: "728px",
    width: "100%",
  },
  [SpaceThemeNames.wyman]: {
    alignItems: "center",
    aspectRatio: "21/9",
    mx: "auto",
    mt: "60px",
    mb: "m",
    borderRadius: "12px",
    maxWidth: "1140px",
  },
};

const containerOverrides = {
  [SpaceThemeNames.classic]: ``,
  [SpaceThemeNames.scher]: `
      @media screen and (min-width: 1364px) {
        aspect-ratio: unset;
        height: 728px
      }
    `,
  [SpaceThemeNames.wyman]: `
      @media screen and (max-width: 1020px) {
        margin-top: 52px;
      }
    `,
};

const Container = styled(Flex).attrs<AnyObject>(props => {
  const overrides = containerOverrideAttrs[props.theme.themeName];
  return { position: "relative", maxWidth: "100%", ...overrides };
})`
  ${props =>
    containerOverrideAttrs[props?.theme?.themeName].aspectRatio &&
    `
      aspect-ratio: ${containerOverrideAttrs[props?.theme?.themeName].aspectRatio};
    `};

  ${props => containerOverrides[props.theme.themeName]}
`;

// MARK : Background Image
// -------------------------------------------------------------------------------
const backgroundImageOverrides = {
  [SpaceThemeNames.wyman]: {
    borderRadius: "12px",
  },
};
const BackgroundImage = styled(ImageView).attrs(props => {
  const overrides = backgroundImageOverrides[props.theme.themeName];
  return {
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0",
    cover: true,
    ...overrides,
  };
})`
  object-fit: cover;
`;

// MARK : Content Container
// -------------------------------------------------------------------------------
const contentContainerOverrides = {
  [SpaceThemeNames.classic]: {
    alignItems: "center",
    py: "xxxl",
    variations: {
      "mq.s": {
        px: "l",
      },
    },
  },
  [SpaceThemeNames.scher]: {
    alignItems: "flex-start",
    px: "xxxl",
    py: "xxxl",
    variations: {
      "mq.s": {
        p: "xxl",
      },
    },
  },
  [SpaceThemeNames.wyman]: {
    px: "xl",
    py: "xxl",
    variations: {
      "mq.s": {
        p: "xl",
      },
    },
  },
};

const ContentContainer = styled(Flex).attrs<AnyObject>(props => {
  const overrides = contentContainerOverrides[props.theme.themeName];
  return { flexDirection: "column", width: "100%", maxWidth: "650px", ...overrides };
})``;

// MARK : Title
// -------------------------------------------------------------------------------
const titleOverrides = {
  [SpaceThemeNames.classic]: {
    textAlign: "center",
  },
};
const Title = styled(Text).attrs(props => {
  const overrides = titleOverrides[props.theme.themeName];
  return { as: "h1", font: "ui.mediumTitle", p: 0, ...overrides };
})``;

// MARK : Subtitle
// -------------------------------------------------------------------------------
const subtitleOverrides = {
  [SpaceThemeNames.classic]: {
    textAlign: "center",
    mt: "s",
  },
  [SpaceThemeNames.scher]: { textAlign: "left", mt: "m" },
  [SpaceThemeNames.wyman]: { textAlign: "left", mt: "m" },
};
const Subtitle = styled(MarkdownText).attrs(props => {
  const overrides = subtitleOverrides[props.theme.themeName];
  return {
    font: "marketing.bodyLarge",
    elements: "basic",
    ...overrides,
  };
})`
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;

  @media (max-width: 1024px) {
    -webkit-line-clamp: 3;
  }

  a,
  button {
    text-decoration: underline;
    color: ${props => props.color};
    &:hover {
      color: ${props => props.color};
    }
  }
`;

function getDescriptionColor(theme: SpaceTheme): string {
  if (theme.headerDescriptionColor) return theme.headerDescriptionColor;
  if (theme.headerBackgroundImage) return "rgba(255, 255, 255, 1)";
  const c = new TinyColor(theme.headerBackgroundColor),
    { a: alpha } = c.toHsl();
  return c.isLight() || alpha <= 0.5 ? "rgba(42, 42, 42, 1)" : "rgba(255, 255, 255, 1)";
}

function getTitleColor(theme: SpaceTheme): string {
  if (theme.headerTitleColor) return theme.headerTitleColor;
  if (theme.headerBackgroundImage) return "rgba(255, 255, 255, 1)";
  const c = new TinyColor(theme.headerBackgroundColor),
    { a: alpha } = c.toHsl();
  return c.isLight() || alpha <= 0.5 ? "rgba(42, 42, 42, 1)" : "rgba(255, 255, 255, 1)";
}

const searchBarOverrides = {
  [SpaceThemeNames.classic]: {
    mt: "l",
  },
  [SpaceThemeNames.scher]: { mt: "xl" },
  [SpaceThemeNames.wyman]: {},
};

type Props = {
  portal: Portal;
  titleRef?: ReturnType<typeof useInView>["ref"];
};

const PortalHeader: React.FC<Props> = ({ portal, titleRef }) => {
  const perms = portal?.access?.permissions || [],
    canSearch = perms.includes("search_assets");

  const theme = useTheme(),
    themeName = theme.themeName,
    { headerBackgroundColor, headerBackgroundImage, headerLogoImage, headerTitleStyle } = theme,
    titleColor = getTitleColor(theme),
    descriptionColor = getDescriptionColor(theme);

  const renderTitle = () => {
    /**
     * In the client app, the image is type:
     * { width: number, height: number, url: string }
     */
    if (headerTitleStyle === ThemeHeaderTitleOptions.logo && headerLogoImage?.url) {
      return <SpaceHeaderLogo {...(headerLogoImage as { url: string })} />;
    }
    // In the theme editor, the image preview is a data string;
    else if (
      headerTitleStyle === ThemeHeaderTitleOptions.logo &&
      typeof headerLogoImage === "string"
    ) {
      return <SpaceHeaderLogo url={String(headerLogoImage)} />;
    } else if (headerTitleStyle === "name" || !headerTitleStyle) {
      return <Title color={titleColor}>{portal.name}</Title>;
    }
    return null;
  };

  return (
    <>
      <TabletUp>
        <Wrapper>
          <Container
            data-inspector-clear="true"
            background={headerBackgroundColor || utils.getColor("grayLightest")}>
            {headerBackgroundImage && <BackgroundImage src={headerBackgroundImage} />}
            <ContentContainer ref={titleRef} data-inspector-clear="true">
              {renderTitle()}
              {theme.description && (
                <Subtitle color={descriptionColor}>{theme.description}</Subtitle>
              )}
              {canSearch && themeName !== SpaceThemeNames.wyman && (
                <SearchBarButton width="400px" {...searchBarOverrides[themeName]} />
              )}
            </ContentContainer>
          </Container>
        </Wrapper>
      </TabletUp>
      <TabletDown>
        <MobileSubNav />
      </TabletDown>
    </>
  );
};

export default PortalHeader;
