import React from "react";
import styled from "styled-components";
import {
  Flex,
  Text,
  Box,
  Button,
  Tooltip,
  AssetType,
  ItemType,
  useClipboard,
  useNavigation,
} from "@thenounproject/lingo-core";

import useWindowSize from "../hooks/useWindowSize";
import InspectorAssetDownload from "./use/InspectorAssetDownload";
import ColorAssetCopyPopup from "./use/ColorAssetCopyPopup";

import { Inspectable, InspectorSource } from "@constants/Inspector";

import useInspectableActions from "@features/context-menus/hooks/useInspectableActions";
import { useViewModeContext } from "@contexts/ViewModeProvider";

const DownloadAssetWrapper = styled(Box).attrs({ ml: "l" })``;
const TitleContainer = styled(Box).attrs({
  /* NOTE Might need to pass vars on this max-width
   *  in the future to solve for all edge cases
   */
  maxWidth: "calc(100% - 300px)",
  flexShrink: 1,
})``;
const ControlsContainer = styled(Flex).attrs({ alignItems: "center" })`
  flex-shrink: 0;
`;

const ActionButton = styled(Button).attrs({
  size: "small",
  mr: "s",
  themeOverrides: {
    primaryColor: "grayLightest",
    primaryColorTint: "black",
    primaryColorDark: "grayLight",
  },
})``;

export type Props = {
  inspectable: Inspectable;
  source: InspectorSource;
  sharePath: string;
  canEdit: boolean;
};
const InspectorSimple: React.FC<Props> = ({ inspectable, sharePath, canEdit }) => {
  const navigation = useNavigation();
  const { belowInspectorThreshold } = useWindowSize();
  const { setInspectorMode } = useViewModeContext();
  const { onCopy, hasCopied } = useClipboard(inspectable.asset?.meta?.content?.url || null);

  const { moveItems, copyItems, deleteAssets, deleteItems, addToKit, copyShareLink } =
    useInspectableActions([inspectable]);

  function renderTitleSection() {
    let title = "1 item selected";
    if (inspectable.asset) {
      title =
        inspectable.asset.name ||
        inspectable.item?.data?.viewName ||
        inspectable.item?.data?.name ||
        "Unnamed asset";
    }
    return (
      <TitleContainer>
        <Text font="ui.regularBold" truncate>
          {title}
        </Text>
      </TitleContainer>
    );
  }

  function viewDetailsButton() {
    return (
      <>
        <Tooltip source="view-details-button" direction={Tooltip.Direction.Top}>
          View details
        </Tooltip>
        <ActionButton
          data-tooltip-source="view-details-button"
          aria-label="View details"
          icon="info.info"
          onClick={
            belowInspectorThreshold
              ? () => navigation.push(sharePath)
              : () => setInspectorMode("detailed")
          }
        />
      </>
    );
  }
  function viewButton() {
    if (belowInspectorThreshold) return null;
    if (inspectable?.item?.type !== ItemType.asset || !inspectable.asset) return null;
    return (
      <>
        <Tooltip source="open-preview" direction={Tooltip.Direction.Top}>
          Open preview
        </Tooltip>
        <ActionButton
          data-tooltip-source="open-preview"
          aria-label="Open preview"
          icon="maximize"
          onClick={() => navigation.push(sharePath)}
        />
      </>
    );
  }
  function copyShareLinkButton() {
    return (
      <>
        <Tooltip source="copy-share-link-button" direction={Tooltip.Direction.Top}>
          Copy link
        </Tooltip>
        <ActionButton
          data-tooltip-source="copy-share-link-button"
          icon="link"
          aria-label="Copy share link"
          onClick={copyShareLink}
        />
      </>
    );
  }

  function moveToButton() {
    if (!canEdit) return null;
    if (!moveItems) return null;

    return (
      <>
        <Tooltip source="move-to-button" direction={Tooltip.Direction.Top}>
          Move to
        </Tooltip>
        <ActionButton
          data-tooltip-source="move-to-button"
          aria-label="Move to"
          onClick={moveItems}
          icon="action.move_to"
        />
      </>
    );
  }

  function copyToButton() {
    if (!canEdit) return null;
    if (!copyItems) return null;

    return (
      <>
        <Tooltip source="copy-to-button" direction={Tooltip.Direction.Top}>
          Copy to
        </Tooltip>
        <ActionButton
          data-tooltip-source="copy-to-button"
          aria-label="Copy to"
          onClick={copyItems}
          icon="action.copy_to"
        />
      </>
    );
  }

  function deleteButton() {
    if (!canEdit) return null;
    if (!deleteAssets && !deleteItems) return null;

    return (
      <>
        <Tooltip source="delete-button" direction={Tooltip.Direction.Top}>
          Delete
        </Tooltip>
        <ActionButton
          data-tooltip-source="delete-button"
          icon="action.trash"
          onClick={deleteItems ?? deleteAssets}
          aria-label="Delete asset"
        />
      </>
    );
  }

  function addToKitButton() {
    if (!canEdit) return null;
    if (!addToKit) return null;
    return (
      <>
        <Tooltip source="add-to-button" direction={Tooltip.Direction.Top}>
          Add to
        </Tooltip>
        <ActionButton
          data-tooltip-source="add-to-button"
          icon="action.add-to"
          aria-label="Add to kit"
          onClick={addToKit}
        />
      </>
    );
  }

  function downloadButton() {
    if (inspectable.item && inspectable.item.type !== ItemType.asset) return null;

    switch (inspectable.asset.type) {
      case AssetType.color:
        return (
          <DownloadAssetWrapper>
            <ColorAssetCopyPopup
              inspectable={inspectable}
              popupVPos={"alignTop" as "alignTop" | "alignBottom"}
              popupHPos="floatRight">
              {buttonProps => (
                <Button
                  size="small"
                  id="color-copy-button"
                  text="Copy as"
                  icon="triangle-double"
                  width="100%"
                  {...buttonProps}
                />
              )}
            </ColorAssetCopyPopup>
          </DownloadAssetWrapper>
        );
      case AssetType.URL:
        return (
          <DownloadAssetWrapper>
            <Button size="small" text={hasCopied ? "Copied" : "Copy link"} onClick={onCopy} />
          </DownloadAssetWrapper>
        );
      default:
        return (
          <DownloadAssetWrapper>
            <InspectorAssetDownload inspectable={inspectable} mode={"simple"} canEdit={canEdit} />
          </DownloadAssetWrapper>
        );
    }
  }

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      width="100%"
      data-testid="inspector-simple">
      {renderTitleSection()}
      <ControlsContainer>
        {viewButton()}
        {copyShareLinkButton()}
        {moveToButton()}
        {copyToButton()}
        {addToKitButton()}
        {deleteButton()}
        {viewDetailsButton()}
        {downloadButton()}
      </ControlsContainer>
    </Flex>
  );
};

export default InspectorSimple;
