import { fetchAsset } from "@redux/actions/assets/useAsset";
import { duplicateItems } from "@redux/actions/items/useDuplicateItems";
import { fetchGalleryItems } from "@redux/actions/items/useGalleryItems";
import { getEntities } from "@redux/reducers/helpers/getEntities";
import { AppStore } from "@redux/store/reduxStore";
import { Middleware, UnknownAction } from "@reduxjs/toolkit";
import { createKit } from "@redux/actions/kits/useCreateKit";
import { fetchKits } from "@redux/actions/kits/useKits";
import { pollForDuplicateKit } from "@redux/actions/kits/usePollForDuplicateKit";
import { deleteKit } from "@redux/actions/kits/useDeleteKit";
import { deletePortalItem } from "@redux/actions/portalItems/useDeletePortalItem";
import { removeSharedKit } from "@redux/actions/kits/useRemoveSharedKit";

const mutations: Middleware = (store: AppStore) => next => (action: UnknownAction) => {
  processMutations(store, action);
  return next(action);
};

export default mutations;

function processMutations(store: AppStore, action: UnknownAction) {
  checkForUploadedGalleries(store, action);
  refetchAsset(store, action);
  refetchKit(store, action);
  onKitDeleted(store, action);
}

function checkForUploadedGalleries(store: AppStore, action: UnknownAction) {
  const entities = getEntities(action);
  if (!entities?.items) return;
  const galleries = Object.entries(entities.items).filter(
    item => item[1].version === 0 && item[1].type === "gallery"
  );
  const state = store.getState();
  galleries.forEach(gallery => {
    const galleryItem = gallery[1];
    const existingGallery = state.entities.items.objects[gallery[0]];

    if (!existingGallery) return;
    if (
      existingGallery.data.dateRefreshed !== galleryItem.data.dateRefreshed ||
      existingGallery.data.viewId !== galleryItem.data.viewId
    ) {
      // Clear the query for the gallery
      const invalidateAction = fetchGalleryItems.invalidateAction({
        itemId: galleryItem.id,
        version: galleryItem.version,
      });
      store.dispatch(invalidateAction);
    }
  });
}

function refetchAsset(store: AppStore, action: UnknownAction) {
  if (duplicateItems.fulfilled.match(action) && action.meta.arg.createReferences) {
    const {
      result: { items: newItems },
      entities,
    } = action.payload;
    const assetIds = newItems.map(i => entities.items[i].assetId);
    assetIds.forEach(assetId => store.dispatch(fetchAsset.invalidateAction({ assetId })));
  }
}

function refetchKit(store: AppStore, action: UnknownAction) {
  if (pollForDuplicateKit.fulfilled.match(action) || createKit.fulfilled.match(action)) {
    const { spaceId } = action.meta.arg;
    store.dispatch(fetchKits.invalidateAction({ spaceId }));
  }
}

function onKitDeleted(store: AppStore, action: UnknownAction) {
  if (deleteKit.fulfilled.match(action) || removeSharedKit.fulfilled.match(action)) {
    // a deleted kit will return the deleted version
    // a removed shared kit will return the kit
    const removedKit =
      action.payload.entities.kitVersions?.[action.payload.result] ??
      action.payload.entities.kits?.[action.payload.result];
    const portalItems = Object.values(store.getState().entities.portalItems.objects).filter(
      pi => pi.kitId === removedKit.kitId && pi.spaceId === removedKit.spaceId
    );
    portalItems.forEach(portalItem =>
      store.dispatch({
        type: deletePortalItem.fulfilled.type,
        meta: { arg: { portalItemId: portalItem.id, portalId: portalItem.portalId } },
        payload: {
          result: portalItem.id,
          entities: {
            portalItems: { [portalItem.id]: { status: "deleted" } },
          },
        },
      })
    );
  }
}
