import React from "react";
import { Box, Flex, Text, useBoolean, Icon } from "@thenounproject/lingo-core";
import styled from "styled-components";

interface Props {
  title: string;
  openByDefault?: boolean;
  children: React.ReactNode;
}

const Wrapper = styled(Flex).attrs({
  p: "s",
  flexDirection: "column",
  borderBottom: "default",
  variations: {
    ":last-child": { borderBottom: "none" },
  },
})``;
const ChildWrapper = styled(Box).attrs({ pt: "s" })``;
const TitleButton = styled.button`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
`;

const ThemeEditorAccordion: React.FC<Props> = ({ children, title, openByDefault }) => {
  const [open, setOpen, setClosed] = useBoolean(openByDefault || false);
  return (
    <Wrapper>
      <TitleButton onClick={open ? setClosed : setOpen}>
        <Text font="ui.regularBold" color="grayDarker">
          {title}
        </Text>
        <Box height="16" width="16">
          <Icon
            size="16"
            iconId={open ? "navigation.chevron-down" : "navigation.chevron-right"}
            fill="grayDarker"
          />
        </Box>
      </TitleButton>
      {open && <ChildWrapper>{children}</ChildWrapper>}
    </Wrapper>
  );
};

export default ThemeEditorAccordion;
